import React from 'react'
import {
  Link,
  useNavigate
} from 'react-router-dom'
import { Typography } from '@mui/material'
// import { Typography } from '@mui/material'
// import PDFViewer from 'pdf-viewer-reactjs'
// import pdfFile from 'pdf/Get_Started_With_Smallpdf-output-12.pdf'

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const AgreementViewScreen = (props) => {
  const navigate = useNavigate()
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const accountType = localStorage.getItem('accountType')

  return (
    <>
      <main className="main users chart-page h-100 ">
        <div className="text-start cursorPointer">
          <a onClick={() => navigate(-1)} className="f-w-700 color-400 ">
            <img src="../images/back.svg" alt="" className="me-3" /> Go back
          </a>
        </div>
        <div className="container-fluid p-lg-4">
          <div className="title">
            <h3 className="sign-contract mt-2 my-4">Sign contract</h3>
          </div>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
            <div>
              <Viewer
                fileUrl="/pdf-open-parameters.pdf"
                plugins={[defaultLayoutPluginInstance]}
              />
            </div>
          </Worker>
          <div className="form-check my-4">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="Actions"
            />
            <label className="form-check-label " htmlFor="Actions">
              I agree with you on the contract
            </label>
          </div>
          <div className="border-top mb-3 pt-3 d-flex align-item-center justify-content-center gap-3">
            <button
              type="button"
              className="btn cancelBtn py-2 px-5"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <Typography
              component={Link}
              className={'btn ms-2 submitBtn py-2 px-5'}
              to={accountType === 'driver' ? '/alliance/membershipDetails' : '/alliance/upgradeMembershipPlan'}
              color="textSecondary"
              variant="h6"
              type='submit'
              sx={{ textDecoration: 'none' }}
            >
              Next
            </Typography>
            {/* <button
                                      type="submit"
                                      className="btn custom-button py-2 px-5"
                                    >
                                      Next
                                    </button> */}
          </div>
          {/* <div className="form-check">
            <Field
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              name="termsCheck"
            // onChange={(e) => {
            //   setFieldValue("termsCheck", e?.target?.checked);
            // }}
            />
            <label
              className="form-check-label"
              htmlFor="exampleCheck1"
            >
              I accept FreightPro8{' '}
              <span style={{ color: 'red' }}>*</span>{' '}
            </label>
            <a
              // onClick={() => {
              //   setTermsAndConditionShow(!termsAndConditionShow)
              // }}
              href='/TermsAndConditions'
              className="f-w-700 color-700 text-uppercase"
            >
              terms & conditions
            </a>
          </div> */}
        </div>
      </main>
    </>
  )
}

export default AgreementViewScreen
