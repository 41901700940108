import React, { lazy } from 'react'

// project import
import Checking from '../Components/Custom Checking/Checking'
import Loadable from '../Components/Loadable'
import Dashboard from '../Pages/Dashboard/Dashboard'
import Messages from '../Pages/Dashboard/Messages'
import Notifications from '../Pages/Dashboard/Notifications'
// import SearchResults from "../Pages/Dashboard/SearchResults";
import SearchResults from '../Pages/Dashboard/SearchResults'
import Error from '../Pages/Error/Error'
import CustomMessagesWrapper from '../Pages/Messages/CustomMessagesWrapper'
import CustomNotificationsWrapper from '../Pages/Notifications/CustomNotificationsWrapper'
import BusinessProfilePage from '../Pages/ProfilePage_new/BusinessProfile'
import BusinessUpdateProfile from '../Pages/ProfilePage_new/BusinessUpdateProfile'
// import Driverbuddyprofile from "../Pages/ProfilePage_new/Driverbuddyprofile";
// import FleetOwnerBuddyProfile from "../Pages/ProfilePage_new/FleetOwnerProfile/FleetOwnerBuddyProfile";
// import OwnerOperatorBuddiedProfile from "../Pages/ProfilePage_new/OwnerOperatorBuddiesProfile";
import SetAccountTypeScreen from '../Pages/Alliance/AccountType.js'
import PersonalAccountTypeScreen from '../Pages/Alliance/PersonalAccountType.js'
import BusinessAccountTypeScreen from '../Pages/Alliance/BusinessAccountType.js'
import AccountTypeVerifyYourselfScreen from '../Pages/Alliance/AccountTypeVerifyYourself.js'
import PersonalInfoUpdateScreen from '../Pages/Alliance/PersonalInfoUpdate.js'
import UploadDocumentsScreen from '../Pages/Alliance/UploadDocuments.js'
import SelfDocumentUploadScreen from '../Pages/Alliance/SelfDocumentUpload.js'
import UploadDocumentListScreen from '../Pages/Alliance/UploadDocumentList.js'
import AgreementViewScreen from '../Pages/Alliance/AgreementView.js'
import AllianceDashboard from '../Pages/Alliance/AllianceDashboard.js'
import CustomSearchFriendWrapper from '../Pages/SearchFriend/CustomSearchFriendWrapper'
import CSRVerification from '../Pages/CSRVerification/index.js'
import MembershipDetailsScreen from '../Pages/Alliance/MembershipDetails.js'
import RewardsScreen from '../Pages/Alliance/Rewards.js'
import CheckoutPlanScreen from '../Pages/Alliance/CheckoutPlan.js'
import ViewInvoicesPdfScreen from '../Pages/Alliance/ViewInvoicesPdf.js'
import DispatchDashboard from '../Pages/Dispatch/DispatchDashboard.js'
import ConnectDashboard from '../Pages/Connect/ConnectDashboard.js'
import UpgradeMemberShipPlanScreen from '../Pages/Alliance/UpgradeMemberShipPlanScreen.js'
import BillingPageScreen from '../Pages/Alliance/BillingPageScreen.js'
import CsrDashboardScreen from '../Pages/CSR/index.js'
// import CsrProfilePage from '../Pages/CSR/CsrProfile.js'

// import Checking from "../Components/Custom Checking/Checking";

const MainLayout = Loadable(lazy(() => import('../layout/MainLayout')))

const Feed = Loadable(lazy(() => import('../Pages/Feed')))
// const CsrDashboardScreen = Loadable(lazy(() => import('../Pages/CSR/CsrDashboardScreen.js')))

const FleetOwnerBuddyProfile = Loadable(
  lazy(() => import('../Pages/ProfilePage_new/FleetOwnerProfile/FleetOwnerBuddyProfile'))
)
const Driverbuddyprofile = Loadable(
  lazy(() => import('../Pages/ProfilePage_new/Driverbuddyprofile'))
)
const OwnerOperatorBuddiedProfile = Loadable(
  lazy(() => import('../Pages/ProfilePage_new/OwnerOperatorBuddiesProfile'))
)
const UpdateProfile = Loadable(
  lazy(() => import('../Pages/ProfilePage_new/updateProfile'))
)
const OnwerUpdateProfile = Loadable(
  lazy(() => import('../Pages/ProfilePage_new/OoupdateProfile.jsx'))
)
const Profilepage = Loadable(lazy(() => import('../Pages/ProfilePage_new/Profile')))
const CsrProfilePage = Loadable(lazy(() => import('../Pages/CSR/CsrProfile.js')))
const OwnerProfilepage = Loadable(
  lazy(() => import('../Pages/ProfilePage_new/OoProfile.jsx'))
)
const CustomGalleryWrapper = Loadable(
  lazy(() => import('../Components/Custom Image Gallery/CustomGalleryWrapper'))
)
const BuddiesWrapper = Loadable(lazy(() => import('../Pages/Buddies/BuddiesWrapper')))
const FollowersWrapper = Loadable(
  lazy(() => import('../Pages/Followers/FollowersWrapper'))
)
const FollowingWrapper = Loadable(
  lazy(() => import('../Pages/Following/FollowingWrapper'))
)
const MeetupWrapper = Loadable(lazy(() => import('../Pages/Meetup')))
const NewBuddiesPage = Loadable(lazy(() => import('../Pages/Dashboard/NewBuddiesPage')))

const AccountSettingsPage = Loadable(
  lazy(() => import('../Pages/ProfilePage_new/AccountSettings.jsx'))
)

const MembershipPage = Loadable(lazy(() => import('../Pages/frightUI/Membership.js')))
const FreightJobsPage = Loadable(lazy(() => import('../Pages/jobs/index.jsx')))
const FreightEventsPage = Loadable(
  lazy(() => import('../Pages/frightUI/FreightEvents.js'))
)

const FreightEventsDetailPage = Loadable(
  lazy(() => import('../Pages/frightUI/FreightEventsDetail.js'))
)

const CreateFreightEventPage = Loadable(
  lazy(() => import('../Pages/frightUI/createFreightEvent.js'))
)

const EditFreightEventPage = Loadable(
  lazy(() => import('../Pages/frightUI/createFreightEvent.js'))
)

const CouchServicesHostPage = Loadable(
  lazy(() => import('../Pages/couchServicesHost/index.jsx'))
)
const DocumentsVerification = Loadable(
  lazy(() => import('../Components/CSR/CSRVerificationTab/DocumentsVerification.jsx'))
)
const ViewDocumentsVerification = Loadable(
  lazy(() => import('../Components/CSR/CSRVerificationTab/ViewDocumentsVerification.jsx'))
)
const CsrIdVerificationProcess = Loadable(
  lazy(() => import('../Components/CSR/CSRVerificationTab/CsrIdVerificationProcess.jsx'))
)

const VerificationEmail = Loadable(
  lazy(() => import('../Pages/authentication/VerificationEmail'))
)
const VerificationMobile = Loadable(
  lazy(() => import('../Pages/authentication/VerificationMobile'))
)

const create = 'create'
const edit = 'edit'
const ProfileRoutes = {
  // path: "/",
  element: <MainLayout />,
  children: [
    {
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/pro/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/pro/feed',
      element: <Feed />,
    },
    {
      path: '/verificationmobile',
      element: <VerificationMobile />,
    },
    {
      path: '/verificationemail',
      element: <VerificationEmail />,
    },
    {
      path: '/pro/feed/view/:encryptedlink',
      element: <Feed />,
    },
    // {
    //   path: '/csr',
    //   element: <CsrDashboard />,
    // },
    {
      path: '/searchresultspage',
      element: <SearchResults />,
    },
    {
      path: '/notificationspage',
      element: <Notifications />,
    },
    {
      path: '/messagespage',
      element: <Messages />,
    },
    {
      path: '/buddiespage',
      element: <NewBuddiesPage />,
    },
    {
      path: '/pro/profile',
      element: <Profilepage />,
    },
    {
      path: '/ownerprofile',
      element: <OwnerProfilepage />,
    },
    {
      path: '/viewprofile',
      element: <Profilepage />,
    },
    {
      path: '/error',
      element: <Error />,
    },
    {
      path: '/businessprofile',
      element: <BusinessProfilePage />,
    },
    {
      path: '/updateprofile',
      element: <UpdateProfile />,
    },
    {
      path: '/ownerupdateprofile',
      element: <OnwerUpdateProfile />,
    },
    {
      path: '/businessupdateprofile',
      element: <BusinessUpdateProfile />,
    },
    {
      path: '/fleetownerupdateprofile',
      element: <fleetownerupdateprofile />,
    },
    {
      path: '/profile/buddies',
      element: <BuddiesWrapper />,
    },
    {
      path: '/profile/gallery',
      element: <CustomGalleryWrapper showProfileHeader={true} />,
    },
    {
      path: '/profile/followers',
      element: <FollowersWrapper />,
    },
    {
      path: '/profile/following',
      element: <FollowingWrapper />,
    },
    {
      path: '/profile/meetup',
      element: <MeetupWrapper />,
    },
    {
      path: '/profile/messages',
      element: <CustomMessagesWrapper />,
    },
    {
      path: '/profile/Notifications',
      element: <CustomNotificationsWrapper />,
    },
    {
      path: '/profile/Notifications',
      element: <CustomNotificationsWrapper />,
    },
    {
      path: '/profile/SearchFriend',
      element: <CustomSearchFriendWrapper />,
    },
    // {
    //   path: "/fleetownerprofile",
    //   element: <FleetOwnerProfile />,
    // },
    {
      path: `/owneroperatorbuddiesprofile/:id`,
      element: <OwnerOperatorBuddiedProfile />,
    },
    {
      path: `/driverbuddyprofile/:id`,
      element: <Driverbuddyprofile />,
    },
    {
      path: `/fleetownerbuddyprofile/:id`,
      element: <FleetOwnerBuddyProfile />,
    },
    {
      path: '/checking',
      element: <Checking />,
    },
    {
      path: '/accountsettings',
      element: <AccountSettingsPage />,
    },
    {
      path: '/membership',
      element: <MembershipPage />,
    },

    {
      path: '/pro/jobs',
      element: <FreightJobsPage />,
    },
    {
      path: '/pro/jobs/:currentTab',
      element: <FreightJobsPage />,
    },
    {
      path: '/pro/FreightEvents',
      element: <FreightEventsPage />,
    },
    {
      path: '/pro/FreightEventsDetail',
      element: <FreightEventsDetailPage />,
    },

    {
      path: '/pro/createFreightEvent',
      element: <CreateFreightEventPage mode={create} />,
    },

    {
      path: '/pro/editFreightEvent',
      element: <EditFreightEventPage mode={edit} />,
    },
    {
      path: '/pro/couchServicesHost/:currentTab',
      element: <CouchServicesHostPage />,
    },
    {
      path: '/pro/couchServicesHost',
      element: <CouchServicesHostPage />,
    },
    {
      path: '/alliance/dashboard',
      element: <AllianceDashboard />,
    },
    {
      path: '/alliance/setAccountType',
      element: <SetAccountTypeScreen />,
    },
    {
      path: '/alliance/personalaccounttype',
      element: <PersonalAccountTypeScreen />,
    },
    {
      path: '/alliance/businessacoounttype',
      element: <BusinessAccountTypeScreen />,
    },
    {
      path: '/alliance/accountTypeVerifyYourself',
      element: <AccountTypeVerifyYourselfScreen />,
    },
    {
      path: '/alliance/personalInfoUpdate',
      element: <PersonalInfoUpdateScreen />,
    },
    {
      path: '/alliance/UploadDocuments',
      element: <UploadDocumentsScreen />,
    },
    {
      path: '/alliance/selfUploadDocument',
      element: <SelfDocumentUploadScreen />,
    },
    {
      path: '/alliance/uploadDocumentList',
      element: <UploadDocumentListScreen />,
    },
    {
      path: '/alliance/agreementView',
      element: <AgreementViewScreen />,
    },
    {
      path: '/alliance/membershipDetails',
      element: <MembershipDetailsScreen />,
    },
    {
      path: '/alliance/rewards',
      element: <RewardsScreen />,
    },
    {
      path: '/alliance/billing',
      element: <BillingPageScreen />,
    },
    {
      path: '/alliance/checkout',
      element: <CheckoutPlanScreen />,
    },
    {
      path: '/alliance/upgradeMembershipPlan',
      element: <UpgradeMemberShipPlanScreen />,
    },
    {
      path: '/alliance/viewInvoice',
      element: <ViewInvoicesPdfScreen />,
    },
    {
      path: '/dispatch/dashboard',
      element: <DispatchDashboard />,
    },
    {
      path: '/connect/dashboard',
      element: <ConnectDashboard />,
    },
    {
      path: '/csr/dashboard',
      element: <CsrDashboardScreen />,
    },
    {
      path: '/csr/profile',
      element: <CsrProfilePage />,
    },
    {
      path: '/csr/verification',
      element: <CSRVerification />,
    },
    {
      path: '/csr/documents-verification-list',
      element: <DocumentsVerification />,
    },
    {
      path: '/csr/view-documents',
      element: <ViewDocumentsVerification />,
    },
    {
      path: '/csr/id-verification-process',
      element: <CsrIdVerificationProcess />,
    },
  ],
}

export default ProfileRoutes
