import { Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import { Typography } from '@mui/material'
// import activePlanSvg from '../../css/images/actionPlan.svg'

const UpgradeMemberShipPlanScreen = (props) => {
  const navigate = useNavigate()

  return (
    <>
      {/* <GetUserProfile /> */}
      <main className="main users chart-page h-100 ">
        <div className="text-start cursorPointer">
          <a onClick={() => navigate(-1)} className="f-w-700 color-400 ">
            <img src="../images/back.svg" alt="" className="me-3" /> Go back
          </a>
        </div>
        <div className="container-fluid p-lg-4 h-100">
          <div className="currentplan">
            <div className="membership-title pb-5">
              <h3 className="mb-4">Memberships</h3>
              <p>Upgrade to unlock career advancement, growth and rewards </p>
            </div>
            <div className="d-flex align-items-start justify-content-center gap-4">
              <div className="planDiv px-0 w-100">
                <div className="pricing-card p-2 text-center h-100">
                  <div
                    className="basic-cntnt text-center "
                    style={{ borderBottom: '1px dashed #FED7C7' }}
                  >
                    <div className='w-auto mb-5'>
                      <div className='plan-top-first-buttonDiv d-flex align-items-center'>
                        <p className='m-0'>Platinum</p>
                        <p className='m-0'>Business Owner</p>
                      </div>
                    </div>

                    <p className="monthly text-uppercase  mb-4">monthly</p>
                    <p
                      className="priceDiv mb-0"
                      style={{ textDecoration: 'line-through' }}
                    >
                      $55
                    </p>
                    <h2 className='totalPriceDiv'><span className='dollar'>$</span><span className='priceD'>50</span></h2>
                    <p className='excluding-taxes' style={{ fontSize: '10px' }}>** Excluding taxes</p>
                    <p className='billedMonthly'>Billed monthly</p>
                  </div>
                  {/* <div className="d-flex align-items-center flex-column justify-content-start gap-3 ps-4 my-5"> */}
                  <div className="ps-4 py-5 my-5">
                    <div className='w-100 py-5 my-5'>
                      <p className='m-0'>
                        Please <Typography
                          component={Link}
                          className={'border-0'}
                          to='#'
                          color="textSecondary"
                          variant="h6"
                          type='submit'
                          sx={{ textDecoration: 'none' }}
                        >read here</Typography> to get more details about Alliance Membership
                      </p>
                    </div>
                    {/* <div className='d-flex align-items-center justify-content-start gap-2 w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Freight events and couch surfing</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-2 w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Job portal, resume, training, courses</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Rewards and road miles</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Fuel saving</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Trailer pool</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Insurance</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>24/7 road side assistance</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Factoring and quick pay</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Load board</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Tracking and tracing</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Resource sharing</p>
                    </div> */}
                  </div>
                  {/* {buyMembershipButtonClicked ? ( */}
                  <div className='mb-4'>
                    <Typography
                      component={Link}
                      className={'btn ms-2 py-3 submitBtn w-100'}
                      to='/alliance/checkout'
                      color="textSecondary"
                      variant="h6"
                      type='submit'
                      sx={{ textDecoration: 'none' }}
                    >
                      Select plan
                    </Typography>
                    {/* <button className='btn ms-2 py-3 submitBtn w-100'>
                      Select plan
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="planDiv px-0 w-100">
                <div className="pricing-card p-2 text-center h-100">
                  <div
                    className="basic-cntnt text-center "
                    style={{ borderBottom: '1px dashed #FED7C7' }}
                  >
                    <div className='w-auto mb-5'>
                      <div className='plan-top-first-buttonDiv d-flex align-items-center'>
                        <p className='m-0'>Platinum</p>
                        <p className='m-0'>Business Owner</p>
                      </div>
                    </div>

                    <p className="monthly text-uppercase  mb-4">Annually</p>
                    <p
                      className="priceDiv mb-0"
                      style={{ textDecoration: 'line-through' }}
                    >
                      $580
                    </p>
                    <h2 className='totalPriceDiv'><span className='dollar'>$</span><span className='priceD'>550</span></h2>
                    <p className='excluding-taxes' style={{ fontSize: '10px' }}>** Excluding taxes</p>
                    <p className='billedMonthly'>Billed annually</p>
                  </div>
                  {/* <div className="d-flex align-items-center flex-column justify-content-start gap-3 ps-4 my-5"> */}
                  <div className="ps-4 py-5 my-5">
                    <div className='w-100 py-5 my-5'>
                      <p className='m-0'>
                        Please <Typography
                          component={Link}
                          className={'border-0'}
                          to='#'
                          color="textSecondary"
                          variant="h6"
                          type='submit'
                          sx={{ textDecoration: 'none' }}
                        >read here</Typography> to get more details about Alliance Membership
                      </p>
                    </div>
                    {/* <div className='d-flex align-items-center justify-content-start gap-2 w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Freight events and couch surfing</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-2 w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Job portal, resume, training, courses</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Rewards and road miles</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Fuel saving</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Trailer pool</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Insurance</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>24/7 road side assistance</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Factoring and quick pay</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Load board</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Tracking and tracing</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-start gap-3  w-100'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#D1FADF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z" fill="#12B76A" />
                      </svg>
                      <p className='m-0'>Resource sharing</p>
                    </div> */}
                    {/* <div>
                      <button>
                        <img src="images/check-btn.svg" alt="" />
                      </button>
                    </div>
                    <div>
                      <button>
                        <img src="images/check-btn.svg" alt="" />
                      </button>
                    </div>
                    <div>
                      <button>
                        <img src="images/check-btn.svg" alt="" />
                      </button>
                    </div> */}
                  </div>
                  {/* {buyMembershipButtonClicked ? ( */}
                  <div className='mb-4'>
                    <Typography
                      component={Link}
                      className={'btn ms-2 py-3 submitBtn w-100'}
                      to='/alliance/checkout'
                      color="textSecondary"
                      variant="h6"
                      type='submit'
                      sx={{ textDecoration: 'none' }}
                    >
                      Select plan
                    </Typography>
                    {/* <button className='btn ms-2 py-3 submitBtn w-100'>
                      Select plan
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default UpgradeMemberShipPlanScreen
