import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
// import { ProfileApis } from "../../API/Login/ProfileApi";
// import { dispatch } from "../../store/reducers/persist";
// import {
//   setFrProUser,
//   setauthorizationToken,
// } from "../../store/reducers/Users/users";
// import { dispatch } from "../../store/reducers/persist";
// import {
//   setFrProUser,
//   setFrProUserProfile,
// } from "../../store/reducers/Users/users";
import { StropheContext } from '../ChatClient'
import { ProfileApis } from '../../API/Login/ProfileApi'
import { Typography } from '@mui/material'

const SetAccountTypeScreen = (props) => {
  const navigate = useNavigate()
  const [personalDetails, setPersonalDetails] = useState()
  const stropheContext = useContext(StropheContext)
  console.log('dashboard props', props, stropheContext)

  const { frProUser } = useSelector(
    (state) => state.Users
  )


  const userPersonalDetailsByUserId = () => {
    let mobileNumberObj = {
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
      userId: frProUser?.userId,
      page: 0,
      size: 0,
      businessId: null,
      profileId: null,
    }
    ProfileApis.getUserPersonalDetailsByUserId(mobileNumberObj)
      .then((res) => {
        // Correct way to merge the objects
        setPersonalDetails(res?.data)
      })
      .catch((err) => {
        console.debug('Mobile number not Available', err)
      })
  }


  useEffect(() => {
    userPersonalDetailsByUserId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log('-----frProUser---->', frProUser);
  console.log('-----personalDetails---->', personalDetails);
  return (
    <>
      {/* <GetUserProfile /> */}
      <main className="main users chart-page h-100 ">
        <div className="text-start cursorPointer">
          <a onClick={() => navigate(-1)} className="f-w-700 color-400 ">
            <img src="../images/back.svg" alt="" className="me-3" /> Go back
          </a>
        </div>
        <div className="container-fluid p-lg-4 h-100">
          <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
            <div className="row">
              <div className="col-12 col-lg-12 mx-lg-auto">
                <div className="text-center mb-4 title">
                  <h3 className='mb-4'>
                    Set your account type
                  </h3>
                  <p>
                    Please complete the following to get started with us. We’ll take you <br className="d-none d-lg-block" /> through it step by step.</p>
                </div>
                <div className="d-flex gap-4 align-items-center justify-content-center">
                  {/* <!-- Card 1 --> */}

                  {/* <a href="personal-account-type.html" className="">
                            <img src="images/user.svg" style={{ height: 20, width: 20 }} alt=""/>
                            <strong className=" stronglinkcolor font-18 d-block  py-2">Personal account</strong>
                            <p className="m-0">For drivers or owner operators</p>
                        </a> */}

                  {/* <Typography
                  component={Link}
                  className={'card'}
                  to="/personalaccounttype"
                  color="textSecondary"
                  variant="h6"
                  sx={{
                    textDecoration: 'none',
                    fontSize: '1rem',
                    margin: '12px 0',
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M20.5 21V19C20.5 17.9391 20.0786 16.9217 19.3284 16.1716C18.5783 15.4214 17.5609 15 16.5 15H8.5C7.43913 15 6.42172 15.4214 5.67157 16.1716C4.92143 16.9217 4.5 17.9391 4.5 19V21M16.5 7C16.5 9.20914 14.7091 11 12.5 11C10.2909 11 8.5 9.20914 8.5 7C8.5 4.79086 10.2909 3 12.5 3C14.7091 3 16.5 4.79086 16.5 7Z" stroke="#434343" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <strong className=" stronglinkcolor font-18 d-block  py-2">
                    Personal account
                  </strong>
                  <p className="m-0">For drivers or owner operators</p>
                </Typography> */}

                  {/* <!-- Card 2 --> */}
                  <Typography
                    component={Link}
                    className={'card'}
                    to="/alliance/personalaccounttype"
                    color="textSecondary"
                    variant="h6"
                    sx={{ textDecoration: 'none', fontSize: '1rem' }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path d="M20.5 21V19C20.5 17.9391 20.0786 16.9217 19.3284 16.1716C18.5783 15.4214 17.5609 15 16.5 15H8.5C7.43913 15 6.42172 15.4214 5.67157 16.1716C4.92143 16.9217 4.5 17.9391 4.5 19V21M16.5 7C16.5 9.20914 14.7091 11 12.5 11C10.2909 11 8.5 9.20914 8.5 7C8.5 4.79086 10.2909 3 12.5 3C14.7091 3 16.5 4.79086 16.5 7Z" stroke="#434343" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <strong className=" stronglinkcolor font-18 d-block py-2">
                      Personal account
                    </strong>
                    <p className="m-0">For drivers or owner operators</p>
                  </Typography>
                  <Typography
                    component={Link}
                    className={'card'}
                    to="/alliance/businessacoounttype"
                    color="textSecondary"
                    variant="h6"
                    sx={{ textDecoration: 'none', fontSize: '1rem' }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path d="M16.5 21V5C16.5 4.46957 16.2893 3.96086 15.9142 3.58579C15.5391 3.21071 15.0304 3 14.5 3H10.5C9.96957 3 9.46086 3.21071 9.08579 3.58579C8.71071 3.96086 8.5 4.46957 8.5 5V21M4.5 7H20.5C21.6046 7 22.5 7.89543 22.5 9V19C22.5 20.1046 21.6046 21 20.5 21H4.5C3.39543 21 2.5 20.1046 2.5 19V9C2.5 7.89543 3.39543 7 4.5 7Z" stroke="#434343" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <strong className=" stronglinkcolor font-18 d-block py-2">
                      Business account
                    </strong>
                    <p className="m-0">For businesses in freight industry</p>
                  </Typography>

                </div>

              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default SetAccountTypeScreen
