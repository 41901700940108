import React, { lazy } from 'react'
import Loadable from '../Components/Loadable'
import FleetCreateAccount from '../Pages/authentication/FleetCreateAccount'
import FleetCreateAccountMoreInfo from '../Pages/authentication/FleetCreateAccountMoreInfo'
// import CsrLoginScreen from '../Pages/authentication/csr/CsrLogin.js'
// import CreateFre8Account from '../Pages/authentication/Fre8-login-new-pages/CreateAccount.js'
// import Fre8ProLogin from '../Pages/authentication/Fre8Pro-login-new-pages/Login.js'
// import FleetDashboard from "../Pages/authentication/FleetDashboard";

const AuthRegisterWithoutFormik = Loadable(
  lazy(() => import('../Pages/authentication/withoutFormikRegister'))
)

const WithoutFormikLogin = Loadable(
  lazy(() => import('../Pages/authentication/withoutFormikLogin'))
  // lazy(() => import("../Pages/authentication/New/LoginDriver")),
)
const CsrLoginScreen = Loadable(
  lazy(() => import('../Pages/authentication/csr/CsrLogin'))
  // lazy(() => import("../Pages/authentication/New/LoginDriver")),
)
const Fre8ProLogin = Loadable(
  lazy(() => import('../Pages/authentication/Fre8-login-new-pages/Login.js'))
  // lazy(() => import("../Pages/authentication/New/LoginDriver")),
)
const CreateFre8Account = Loadable(
  lazy(() => import('../Pages/authentication/Fre8-login-new-pages/CreateAccount.js'))
  // lazy(() => import("../Pages/authentication/New/LoginDriver")),
)

const WithoutFormikBusinessLogin = Loadable(
  lazy(() => import('../Pages/authentication/WithoutFormikBusinessLogin'))
  // lazy(() => import("../Pages/authentication/New/LoginDriver")),
)

const CreateAccount = Loadable(
  lazy(() => import('../Pages/authentication/CreateAccount'))
  // lazy(() => import("../Pages/authentication/New/LoginDriver")),
)

const PersonalAccountType = Loadable(
  lazy(() => import('../Pages/authentication/PersonalAccountType'))
  // lazy(() => import("../Pages/authentication/New/LoginDriver")),
)
const BusinessAccountType = Loadable(
  lazy(() => import('../Pages/authentication/BusinessAccountType'))
  // lazy(() => import("../Pages/authentication/New/LoginDriver")),
)

const LoginLayout = Loadable(lazy(() => import('../layout/LoginLayout')))

const Business = Loadable(lazy(() => import('../Pages/business')))

const FirstTimeUser = Loadable(lazy(() => import('../Pages/firstTimeUser')))

const DriverCreateAccount = Loadable(
  lazy(() => import('../Pages/authentication/DriverCreateAccount'))
)

const DriverCreateAccountMoreInfo = Loadable(
  lazy(() => import('../Pages/authentication/DriverCreateAccountMoreInfo'))
)

const OwnerOperatorCreateAccount = Loadable(
  lazy(() => import('../Pages/authentication/OwnerOperatorCreateAccount'))
)

const TermsAndConditions = Loadable(
  lazy(() => import('../Pages/TermsConditions/TermsAndConditions.jsx'))
)

const OwnerOperatorCreateAccountMoreInfo = Loadable(
  lazy(() =>
    import('../Pages/authentication/OwnerOperatorCreateAccountMoreInfo')
  )
)

// const VerificationEmail = Loadable(
//   lazy(() => import('../Pages/authentication/VerificationEmail'))
// )
// const VerificationMobile = Loadable(
//   lazy(() => import('../Pages/authentication/VerificationMobile'))
// )

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  // path: "/",
  element: <LoginLayout />,
  children: [
    {
      path: '',
      element: <Fre8ProLogin />,
      // element: <WithoutFormikLogin />,
    },
    {
      path: '/',
      element: <WithoutFormikLogin />,
    },
    {
      path: '/index.html',
      element: <WithoutFormikLogin />,
    },
    {
      path: '/login',
      element: <WithoutFormikLogin />,
    },
    {
      path: '/csr',
      element: <CsrLoginScreen />,
    },
    {
      path: '/businesslogin',
      element: <WithoutFormikBusinessLogin />,
    },
    {
      path: '/createaccount',
      element: <CreateAccount />,
    },
    {
      path: '/createfre8account',
      element: <CreateFre8Account />,
    },
    {
      path: '/personalaccounttype',
      element: <PersonalAccountType />,
    },
    {
      path: '/businessacoounttype',
      element: <BusinessAccountType />,
    },
    {
      path: '/drivercreateaccount',
      element: <DriverCreateAccount />,
    },
    {
      path: '/owneroperatorcreateaccount',
      element: <OwnerOperatorCreateAccount />,
    },
    {
      path: '/drivercreateaccountmoreinfo',
      element: <DriverCreateAccountMoreInfo />,
    },
    {
      path: '/owneroperatorcreateaccountmoreinfo',
      element: <OwnerOperatorCreateAccountMoreInfo />,
    },
    // {
    //   path: '/verificationmobile',
    //   element: <VerificationMobile />,
    // },
    // {
    //   path: '/verificationemail',
    //   element: <VerificationEmail />,
    // },
    // {
    //   path: "/businessacoounttype",
    //   element: <BusinessAccountType />,
    // },
    {
      path: '/register',
      element: <AuthRegisterWithoutFormik />,
    },
    {
      path: '/business',
      element: <Business />,
    },
    {
      path: '/firsttimeuser',
      element: <FirstTimeUser />,
    },
    {
      path: '/FleetCreateAccountMoreInfo',
      element: <FleetCreateAccountMoreInfo />,
    },
    {
      path: '/FleetCreateAccount',
      element: <FleetCreateAccount />,
    },
    {
      path: '/TermsAndConditions',
      element: <TermsAndConditions />,
    },
  ],
}

export default LoginRoutes
