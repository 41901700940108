import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = () => {
    const data = {
        labels: ["Pro verified", "Standard verified"],
        datasets: [
            {
                data: [41, 59], // Data percentages
                backgroundColor: ["#4BE19B", "#E2E2E2"], // Colors for the segments
                borderColor: ["#4BE19B", "#E2E2E2"], // Border colors
                borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: true,
                position: "right",
                labels: {
                    usePointStyle: true, // Use point-style legends
                },
            },
        },
        cutout: "50%", // Creates the hollow center for the Doughnut chart
    };

    return (
        <Doughnut data={data} options={options} />
    );
};

export default DoughnutChart;
