import React, { useState } from 'react'
// import Toasts from '../../Components/Toasts/Toasts'
// import useCountryAndStatesHook from '../../customHooks/useCountryAndStatesHook'
import DashboardCard01 from '../../Components/CSR/DashboardCard01.js'
import BarChart from '../../Components/Charts/BarChart.js'
import { Button, Card, CardContent } from '@mui/material';
import PieChart from '../../Components/Charts/PieChart.js';
import DoughnutChart from '../../Components/Charts/DoughnutChart.js';

const CsrDashboardScreen = () => {
  const [activeBtn, setActiveBtn] = useState('this_week')

  // useCountryAndStatesHook(setToasts)
  // Data for the chart
  const data = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], // X-axis labels
    datasets: [
      {
        label: "Dataset 1",
        data: [65, 59, 80, 81, 56, 20, 30],
        backgroundColor: "rgba(178, 240, 255, 1)",
      },
      {
        label: "Dataset 2",
        data: [28, 48, 40, 19, 86, 50, 100],
        backgroundColor: "rgba(242, 212, 125, 1)",
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
        text: "Users applied for Pro status and membership",
      },
    },
    scales: {
      y: {
        suggestedMin: 50,
        suggestedMax: 100
      }
    }
  };

  return (
    <main className="main users chart-page">
      {/* {toasts ? <Toasts toastsList={toasts} /> : null} */}
      <div className="container-fluid p-lg-4">
        <div className='py-3 d-flex flex-wrap justify-content-between align-items-center'>
          <div className='page-heading'>Dashboard</div>
          <div>
            <Button className={`this-week-btn ${activeBtn === 'this_week' ? 'activeBtn' : ''}`} onClick={() => setActiveBtn('this_week')}>This Week</Button>
            <Button className={`this-week-btn ${activeBtn === 'last_week' ? 'activeBtn' : ''}`} onClick={() => setActiveBtn('last_week')}>Last Week</Button>
          </div>
        </div>
        <div className="row">
          <div className="col-4 col-lg-4 mx-lg-auto">
            <DashboardCard01 />
          </div>
          <div className="col-4 col-lg-4 mx-lg-auto">
            <DashboardCard01 />
          </div>
          <div className="col-4 col-lg-4 mx-lg-auto">
            <DashboardCard01 />
          </div>
        </div>
        <div className='row membership_div'>
          <div className='col-8 col-lg-8'>
            <Card className='my-custom-card mt-5'>
              <CardContent>
                <div className='custom-chart-div'>
                  <h1 className='heading mb-4'>Users applied for Pro status and membership</h1>
                  <BarChart data={data} options={options} />
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="col-4 col-lg-4 mx-lg-auto">
            <Card className='my-custom-card-col-4 mt-5'>
              <CardContent>
                <div className='custom-chart-div'>
                  <h1 className='heading'>Total  Standard users</h1>
                  <PieChart />
                </div>
              </CardContent>
            </Card>
            <Card className='my-custom-card-col-4 mt-5'>
              <CardContent>
                <div className='custom-chart-div'>
                  <h1 className='heading'>Total  Standard users</h1>
                  <DoughnutChart />
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </main>
  )
}

export default CsrDashboardScreen
