import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
// import { ProfileApis } from "../../API/Login/ProfileApi";
import Toasts from '../../Components/Toasts/Toasts'
// import { dispatch } from "../../store/reducers/persist";
// import {
//   setFrProUser,
//   setauthorizationToken,
// } from "../../store/reducers/Users/users";
// import { dispatch } from "../../store/reducers/persist";
// import {
//   setFrProUser,
//   setFrProUserProfile,
// } from "../../store/reducers/Users/users";
import { StropheContext } from '../ChatClient'
import useCountryAndStatesHook from '../../customHooks/useCountryAndStatesHook'
import { ProfileApis } from '../../API/Login/ProfileApi'
import { Typography } from '@mui/material'

const ConnectDashboard = (props) => {
  const [toasts, setToasts] = useState(null)
  const [personalDetails, setPersonalDetails] = useState()
  const stropheContext = useContext(StropheContext)
  console.log('dashboard props', props, stropheContext)

  const { frProUser } = useSelector(
    (state) => state.Users
  )

  useCountryAndStatesHook(setToasts)

  const userPersonalDetailsByUserId = () => {
    let mobileNumberObj = {
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
      userId: frProUser?.userId,
      page: 0,
      size: 0,
      businessId: null,
      profileId: null,
    }
    ProfileApis.getUserPersonalDetailsByUserId(mobileNumberObj)
      .then((res) => {
        // Correct way to merge the objects
        setPersonalDetails(res?.data)
      })
      .catch((err) => {
        console.debug('Mobile number not Available', err)
      })
  }


  useEffect(() => {
    userPersonalDetailsByUserId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log('-----frProUser---->', frProUser);
  console.log('-----personalDetails---->', personalDetails);
  return (
    <>
      {/* <GetUserProfile /> */}
      <main className="main users chart-page h-100 d-flex flex-column align-items-center justify-content-center">
        {toasts ? <Toasts toastsList={toasts} /> : null}
        <div className="container-fluid p-lg-4">
          <div className="row">
            <div className="col-12 col-lg-9 mx-lg-auto">
              <div className="text-center mb-4 title">
                <h2 className='mb-4'>
                  Hi{' '}
                  <span className='text-capitalize'>{personalDetails?.fullNameOfTheUser}</span>
                  , Welcome to Fre8!
                </h2>
                <p>
                  Please Let us know if you want be a part of Connect and continue <br className="d-none d-lg-block" /> with the membership?
                </p>
              </div>
              <div className="d-flex w-50 m-auto d-none">
                <button
                  type="button"
                  className="btn flex-grow-1 me-2 cancelBtn"
                >
                  No
                </button>
                {/* <button
                  type="button"
                  className="btn flex-grow-1 ms-2 submitBtn"
                  onClick={() => {
                    navigator('/setAccountType')
                  }}
                >
                  Yes
                </button> */}
                <Typography
                  component={Link}
                  className={'btn flex-grow-1 ms-2 submitBtn'}
                  to="/alliance/setAccountType"
                  color="textSecondary"
                  variant="h6"
                  sx={{ textDecoration: 'none' }}
                >
                  Yes
                </Typography>
              </div>
            </div>
          </div>
          {/* <Typography
            component={Link}
            className={'btn btn-default'}
            to="/verificationcode"
            color="inherit"
            variant="h6"
            sx={{ textDecoration: 'none' }}
          >
            No
          </Typography>
          <Typography
            component={Link}
            className={'btn btn-default'}
            to="/verificationcode"
            color="textSecondary"
            variant="h6"
            sx={{ textDecoration: 'none' }}
          >
            Yes
          </Typography> */}
        </div>
      </main>
    </>
  )
}

export default ConnectDashboard
