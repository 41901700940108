import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Link
} from 'react-router-dom'

const CheckoutPlanToUpgrade = ({
  setupgradeButtonClicked,
  setbuyMembershipButtonClicked,
  setshowCurrentPlan,
  fleetOwnerPlan,
}) => {
  const { frProUser } = useSelector((state) => state.Users)
  let item = { userCategory: frProUser?.userCategory }
  const [checkBoxCheck, setCheckboxCheck] = useState(false)

  return (
    <div className="mbr-checkout mt-5">
      <div className="membership-title mb-4">
        <h3 className="mb-4">Checkout</h3>
        <p>Upgrade to unlock career advancement, growth and reward </p>
      </div>

      <div className="row justify-content-between">
        <div className="col-md-7">
          <div className="mb-3">
            <div className="Upgrades border-bottom">
              <h4 className="text-black py-2">Saved Credit & Debit cards</h4>
            </div>
            <div className="gap-3 p-2 d-flex justify-content-between">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="cvvPin"
                  id="cvvPin"
                  onClick={(event) => setCheckboxCheck({
                    cvvPin: event?.target?.checked
                  })}
                />
              </div>
              <div className="d-flex align-items-top w-100">
                <div className="flex-shrink-0">
                  <svg
                    width="34"
                    height="24"
                    viewBox="0 0 34 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="33"
                      height="23"
                      rx="3.5"
                      fill="white"
                    />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="33"
                      height="23"
                      rx="3.5"
                      stroke="#F2F4F7"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.179 16.8295C15.9949 17.8275 14.459 18.43 12.7807 18.43C9.03582 18.43 6 15.4303 6 11.73C6 8.02972 9.03582 5.03003 12.7807 5.03003C14.459 5.03003 15.9949 5.63253 17.179 6.63057C18.363 5.63253 19.8989 5.03003 21.5773 5.03003C25.3221 5.03003 28.358 8.02972 28.358 11.73C28.358 15.4303 25.3221 18.43 21.5773 18.43C19.8989 18.43 18.363 17.8275 17.179 16.8295Z"
                      fill="#ED0006"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.1792 16.8295C18.6371 15.6006 19.5616 13.772 19.5616 11.73C19.5616 9.68807 18.6371 7.85947 17.1792 6.63057C18.3632 5.63253 19.8992 5.03003 21.5775 5.03003C25.3224 5.03003 28.3582 8.02972 28.3582 11.73C28.3582 15.4303 25.3224 18.43 21.5775 18.43C19.8992 18.43 18.3632 17.8275 17.1792 16.8295Z"
                      fill="#F9A000"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.1788 16.8295C18.6367 15.6006 19.5611 13.772 19.5611 11.7301C19.5611 9.68811 18.6367 7.85952 17.1788 6.63062C15.7208 7.85952 14.7964 9.68811 14.7964 11.7301C14.7964 13.772 15.7208 15.6006 17.1788 16.8295Z"
                      fill="#FF5E00"
                    />
                  </svg>
                </div>
                <div className="flex-grow-1 ms-3">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6>Card ending 1234 | Expires: 12/26</h6>
                      <p className="m-0">Name: David Rhye</p>
                    </div>
                    <div>
                      <button
                        className="p-0 fw-bold"
                        id="edit"
                        data-bs-toggle="modal"
                        data-bs-target="#membership-edit-credit"
                      >
                        Edit
                      </button>
                      <span>|</span>
                      <button
                        className="p-0 fw-bold"
                        id="delete"
                        data-bs-toggle="modal"
                        data-bs-target="#membership-edit-credit"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  {checkBoxCheck?.cvvPin && <div className='d-flex justify-content-start gap-2 w-auto align-items-center'>
                    <label className="form-label f-w-500 color-700 m-0">
                      Enter CVV
                    </label>
                    <input
                      type="text"
                      className="form-control w-25"
                      placeholder="000"
                    />
                  </div>}
                </div>
              </div>
            </div>
            <div className="gap-3 p-2 d-flex justify-content-between">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="cvvPin"
                  id="cvvPin2"
                  onClick={(event) => setCheckboxCheck({
                    cvvPin2: event?.target?.checked
                  })}
                />
              </div>
              <div className="d-flex align-items-top w-100">
                <div className="flex-shrink-0">
                  <svg
                    width="34"
                    height="24"
                    viewBox="0 0 34 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="33"
                      height="23"
                      rx="3.5"
                      fill="white"
                    />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="33"
                      height="23"
                      rx="3.5"
                      stroke="#F2F4F7"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.7501 15.8583H8.69031L7.14576 9.79247C7.07245 9.51344 6.91679 9.26676 6.68782 9.1505C6.11639 8.85833 5.48672 8.6258 4.7998 8.50853V8.27499H8.11789C8.57583 8.27499 8.91929 8.6258 8.97653 9.03323L9.77793 13.4087L11.8367 8.27499H13.8392L10.7501 15.8583ZM14.984 15.8583H13.0388L14.6406 8.27499H16.5858L14.984 15.8583ZM19.1025 10.3758C19.1597 9.96738 19.5032 9.73384 19.9039 9.73384C20.5336 9.6752 21.2195 9.79248 21.7919 10.0836L22.1354 8.45091C21.5629 8.21737 20.9333 8.1001 20.3619 8.1001C18.4738 8.1001 17.1 9.1505 17.1 10.6083C17.1 11.7174 18.0731 12.2997 18.7601 12.6505C19.5032 13.0003 19.7894 13.2338 19.7322 13.5836C19.7322 14.1083 19.1597 14.3419 18.5883 14.3419C17.9014 14.3419 17.2145 14.167 16.5858 13.8748L16.2424 15.5086C16.9293 15.7997 17.6724 15.917 18.3594 15.917C20.4763 15.9746 21.7919 14.9252 21.7919 13.3501C21.7919 11.3666 19.1025 11.2503 19.1025 10.3758ZM28.5998 15.8583L27.0553 8.27499H25.3962C25.0528 8.27499 24.7093 8.50853 24.5948 8.85833L21.7347 15.8583H23.7372L24.1369 14.7503H26.5973L26.8263 15.8583H28.5998ZM25.6824 10.3172L26.2539 13.1752H24.6521L25.6824 10.3172Z"
                      fill="#172B85"
                    />
                  </svg>
                </div>
                <div className="flex-grow-1 ms-3">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6>Card ending 1234 | Expires: 12/26</h6>
                      <p className="m-0">Name: David Rhye</p>
                    </div>
                    <div>
                      <button
                        className="p-0 fw-bold"
                        id="edit"
                        data-bs-toggle="modal"
                        data-bs-target="#membership-edit-credit"
                      >
                        Edit
                      </button>
                      <span>|</span>
                      <button
                        className="p-0 fw-bold"
                        id="delete"
                        data-bs-toggle="modal"
                        data-bs-target="#membership-edit-credit"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  {checkBoxCheck?.cvvPin2 && <div className='d-flex justify-content-start gap-2 w-auto align-items-center'>
                    <label className="form-label f-w-500 color-700 m-0">
                      Enter CVV
                    </label>
                    <input
                      type="text"
                      className="form-control w-25"
                      placeholder="000"
                    />
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="Upgrades border-bottom">
              <h4 className="text-black py-2">Another payment method</h4>
            </div>
            <div className="py-3">
              <div className="mb-3">
                <div className="row">
                  <div className="col-md-8">
                    <label className="form-label f-w-500 color-700">
                      Name on card
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name on card"
                      id="for_disabled"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label f-w-500 color-700">
                      Expiry
                    </label>
                    <input
                      type="text"
                      className="form-control text-center"
                      placeholder="DD / YY"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="row">
                  <div className="col-md-8">
                    <label className="form-label f-w-500 color-700">
                      Card number
                    </label>
                    <div className="position-relative">
                      <span className="ms-3 position-absolute top-50 translate-middle-y">
                        <svg
                          width="25"
                          height="20"
                          viewBox="0 0 34 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="33"
                            height="23"
                            rx="3.5"
                            fill="white"
                          />
                          <rect
                            x="0.5"
                            y="0.5"
                            width="33"
                            height="23"
                            rx="3.5"
                            stroke="#F2F4F7"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.179 16.8292C15.9949 17.8273 14.459 18.4298 12.7807 18.4298C9.03582 18.4298 6 15.4301 6 11.7298C6 8.02948 9.03582 5.02979 12.7807 5.02979C14.459 5.02979 15.9949 5.63228 17.179 6.63033C18.363 5.63228 19.8989 5.02979 21.5773 5.02979C25.3221 5.02979 28.358 8.02948 28.358 11.7298C28.358 15.4301 25.3221 18.4298 21.5773 18.4298C19.8989 18.4298 18.363 17.8273 17.179 16.8292Z"
                            fill="#ED0006"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.179 16.8292C18.6369 15.6003 19.5614 13.7717 19.5614 11.7298C19.5614 9.68783 18.6369 7.85923 17.179 6.63032C18.363 5.63228 19.8989 5.02979 21.5772 5.02979C25.3221 5.02979 28.3579 8.02948 28.3579 11.7298C28.3579 15.4301 25.3221 18.4298 21.5772 18.4298C19.8989 18.4298 18.363 17.8273 17.179 16.8292Z"
                            fill="#F9A000"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.179 16.8292C18.6369 15.6003 19.5614 13.7717 19.5614 11.7298C19.5614 9.68787 18.6369 7.85927 17.179 6.63037C15.7211 7.85927 14.7966 9.68787 14.7966 11.7298C14.7966 13.7717 15.7211 15.6003 17.179 16.8292Z"
                            fill="#FF5E00"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        className="form-control ps-5"
                        placeholder="1234 1234 1234 1234"
                        id="for_disabled"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label f-w-500 color-700">CVV</label>
                    <input
                      type="text"
                      className="form-control text-center"
                      placeholder="000"
                    />
                  </div>
                </div>
              </div>
              <div className="paymentbtn d-flex gap-4 mt-5">
                <button
                  type="button"
                  className="btn custom-button2 rounded-3 border w-100"
                >
                  Cancel
                </button>
                <Typography
                  component={Link}
                  className={'btn ms-2 submitBtn py-2 w-100'}
                  to='/alliance/membershipDetails'
                  color="textSecondary"
                  variant="h6"
                  type='submit'
                  sx={{ textDecoration: 'none' }}
                >
                  Next
                </Typography>
                {/* <button
                  type="button"
                  id="Submit-order"
                  className="Custom rounded-3 w-100 text-white"
                  data-bs-toggle="modal"
                  data-bs-target="#membershipmodal"
                  onClick={() => {
                    setupgradeButtonClicked(false)
                    setbuyMembershipButtonClicked(true)
                    setshowCurrentPlan(true)
                  }}
                >
                  Buy membership
                </button> */}
              </div>
            </div>
          </div>
          {/* <div className="memebr-payment">
            <div className="credit-card mb-2">
              <button className="p-0">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="4"
                    y="4"
                    width="48"
                    height="48"
                    rx="24"
                    fill="#B3F0FF"
                  />
                  <rect
                    x="4"
                    y="4"
                    width="48"
                    height="48"
                    rx="24"
                    stroke="#E6FAFF"
                    strokeWidth="8"
                  />
                  <path
                    d="M17 26H39M19 20H37C38.1046 20 39 20.8954 39 22V34C39 35.1046 38.1046 36 37 36H19C17.8954 36 17 35.1046 17 34V22C17 20.8954 17.8954 20 19 20Z"
                    stroke="#054757"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="payment-method">
              <h3>Payment method</h3>
              <p>Enter your card details</p>
            </div>
            <div className="mb-3">
              <div className="row">
                <div className="col-md-8">
                  <label className="form-label f-w-500 color-700">
                    Name on card
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name on card"
                    id="for_disabled"
                  />
                </div>
                <div className="col-md-4">
                  <label className="form-label f-w-500 color-700">
                    Expiry
                  </label>
                  <input
                    type="text"
                    className="form-control text-center"
                    placeholder="DD / YY"
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="row">
                <div className="col-md-8">
                  <label className="form-label f-w-500 color-700">
                    Card number
                  </label>
                  <div className="position-relative">
                    <span className="ms-3 position-absolute top-50 translate-middle-y">
                      <svg
                        width="25"
                        height="20"
                        viewBox="0 0 34 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="33"
                          height="23"
                          rx="3.5"
                          fill="white"
                        />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="33"
                          height="23"
                          rx="3.5"
                          stroke="#F2F4F7"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.179 16.8292C15.9949 17.8273 14.459 18.4298 12.7807 18.4298C9.03582 18.4298 6 15.4301 6 11.7298C6 8.02948 9.03582 5.02979 12.7807 5.02979C14.459 5.02979 15.9949 5.63228 17.179 6.63033C18.363 5.63228 19.8989 5.02979 21.5773 5.02979C25.3221 5.02979 28.358 8.02948 28.358 11.7298C28.358 15.4301 25.3221 18.4298 21.5773 18.4298C19.8989 18.4298 18.363 17.8273 17.179 16.8292Z"
                          fill="#ED0006"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.179 16.8292C18.6369 15.6003 19.5614 13.7717 19.5614 11.7298C19.5614 9.68783 18.6369 7.85923 17.179 6.63032C18.363 5.63228 19.8989 5.02979 21.5772 5.02979C25.3221 5.02979 28.3579 8.02948 28.3579 11.7298C28.3579 15.4301 25.3221 18.4298 21.5772 18.4298C19.8989 18.4298 18.363 17.8273 17.179 16.8292Z"
                          fill="#F9A000"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.179 16.8292C18.6369 15.6003 19.5614 13.7717 19.5614 11.7298C19.5614 9.68787 18.6369 7.85927 17.179 6.63037C15.7211 7.85927 14.7966 9.68787 14.7966 11.7298C14.7966 13.7717 15.7211 15.6003 17.179 16.8292Z"
                          fill="#FF5E00"
                        />
                      </svg>
                    </span>
                    <input
                      type="text"
                      className="form-control ps-5"
                      placeholder="1234 1234 1234 1234"
                      id="for_disabled"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <label className="form-label f-w-500 color-700">
                    CVV
                  </label>
                  <input
                    type="text"
                    className="form-control text-center"
                    placeholder="000"
                  />
                </div>
              </div>
            </div>
            <div className="paymentbtn d-flex gap-4 mt-5">
              <button
                type="button"
                className="btn custom-button2 rounded-3 border w-100"
              >
                Cancel
              </button>
              <button
                type="button"
                id="Submit-order"
                className="Custom rounded-3 w-100 text-white"
                data-bs-toggle="modal"
                data-bs-target="#membershipmodal"
                onClick={() => {
                  setviewPlanButtonClicked(false)
                  setupgradeButtonClicked(false)
                  setbuyMembershipButtonClicked(true)
                  setshowCurrentPlan(false)
                }}
              >
                Buy membership
              </button>
            </div>
          </div> */}
        </div>
        <div className="col-md-5">
          <div className="pricing-card">
            <div className="d-flex justify-content-between p-3 border-bottom">
              <h4 className="text-black m-0">Upgrading to</h4>
              {item?.userCategory === 'Driver' && (
                <img src="/Assets/Images/Latest/bronze-icon.svg" alt="" />
              )}
              {item?.userCategory === 'Fleet Owner' && (
                <img src="/Assets/Images/Latest/gold-icon.svg" alt="" />
              )}
              {item?.userCategory === 'Own Operator' && (
                <img src="/Assets/Images/Latest/silver-icon.svg" alt="" />
              )}
            </div>
            <div className='basic-cntnt border-bottom p-3'>
              <div className='d-flex justify-content-between'>
                <h4 className='mb-0'><span className="fw-normal">Monthly</span></h4>
                <h5 className=''>$55.00</h5>
              </div>
              <div className='d-flex justify-content-between'>
                <h4 className='mb-0'><span className="fw-normal">Discount</span></h4>
                <h5 className='text-danger'>-$5.00</h5>
              </div>
            </div>
            <div className='border-bottom p-3'>
              <div className='d-flex justify-content-start gap-2'>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Discount code"
                />
                <button
                  type="button"
                  className="applyBtn rounded-3 text-white"
                >
                  Apply
                </button>
              </div>
            </div>
            <div className='basic-cntnt border-bottom p-3'>
              <div className='d-flex justify-content-between'>
                <h4 className='mb-0'><span className="fw-normal">Tax one</span></h4>
                <h5 className=''>$05.00</h5>
              </div>
              <div className='d-flex justify-content-between'>
                <h4 className='mb-0'><span className="fw-normal">Tax two</span></h4>
                <h5 className='text-danger'>$05.00</h5>
              </div>
            </div>
            <div className='basic-cntnt border-bottom p-3'>
              <div className='d-flex justify-content-between'>
                <h4 className='mb-0 text-black'><span className="fw-bold">Total</span></h4>
                <h5 className='fw-bold text-black'>$5.00</h5>
              </div>
            </div>
          </div>
          <div className='text-center p-3'>
            <p>Billed monthly, until cancelled.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckoutPlanToUpgrade
CheckoutPlanToUpgrade.propTypes = {
  setupgradeButtonClicked: PropTypes.func,
  setbuyMembershipButtonClicked: PropTypes.func,
  setshowCurrentPlan: PropTypes.func,
  fleetOwnerPlan: PropTypes.string,
}
