import { Card, CardContent } from '@mui/material';
import React, { Fragment } from 'react';
// import LineChart from '../Charts/LineChart';
import LineChartWithConfig from '../Charts/LineChart';

const DashboardCard01 = () => {
  // Data for the chart
  // const data = {
  //   labels: ["January", "February", "March", "April", "May"], // X-axis labels
  //   datasets: [
  //     {
  //       label: "Dataset 1",
  //       data: [65, 59, 80, 81, 56],
  //       backgroundColor: "rgba(75, 192, 192, 0.6)",
  //     },
  //     {
  //       label: "Dataset 2",
  //       data: [28, 48, 40, 19, 86],
  //       backgroundColor: "rgba(153, 102, 255, 0.6)",
  //     },
  //   ],
  // };

  // // Configuration options
  // const options = {
  //   responsive: true,
  //   plugins: {
  //     title: {
  //       display: true,
  //       text: "Chart.js Line Chart",
  //     },
  //   },
  //   interaction: {
  //     mode: "index",
  //     intersect: false,
  //   },
  //   scales: {
  //     x: {
  //       display: true,
  //       title: {
  //         display: true,
  //         text: "Month",
  //       },
  //     },
  //     y: {
  //       display: true,
  //       title: {
  //         display: true,
  //         text: "Value",
  //       },
  //     },
  //   },
  // };

  //   const chartData = {
  //     labels: [
  //       '12-01-2022',
  //       '01-01-2023',
  //       '02-01-2023',
  //       '03-01-2023',
  //       '04-01-2023',
  //       '05-01-2023',
  //       '06-01-2023',
  //       '07-01-2023',
  //       '08-01-2023',
  //       '09-01-2023',
  //       '10-01-2023',
  //       '11-01-2023',
  //       '12-01-2023',
  //       '01-01-2024',
  //       '02-01-2024',
  //       '03-01-2024',
  //       '04-01-2024',
  //       '05-01-2024',
  //       '06-01-2024',
  //       '07-01-2024',
  //       '08-01-2024',
  //       '09-01-2024',
  //       '10-01-2024',
  //       '11-01-2024',
  //       '12-01-2024',
  //       '01-01-2025',
  //     ],
  //     datasets: [
  //       // Indigo line
  //       {
  //         data: [732, 610, 610, 504, 504, 504, 349, 349, 504, 342, 504, 610, 391, 192, 154, 273, 191, 191, 126, 263, 349, 252, 423, 622, 470, 532],
  //         fill: true,
  //         backgroundColor: function(context) {
  //           const chart = context.chart;
  //           const {ctx, chartArea} = chart;
  //           return chartAreaGradient(ctx, chartArea, [
  //             { stop: 0, color: `rgba(${hexToRGB(tailwindConfig().theme.colors.violet[500])}, 0)` },
  //             { stop: 1, color: `rgba(${hexToRGB(tailwindConfig().theme.colors.violet[500])}, 0.2)` }
  //           ]);
  //         },            
  //         borderColor: tailwindConfig().theme.colors.violet[500],
  //         borderWidth: 2,
  //         pointRadius: 0,
  //         pointHoverRadius: 3,
  //         pointBackgroundColor: tailwindConfig().theme.colors.violet[500],
  //         pointHoverBackgroundColor: tailwindConfig().theme.colors.violet[500],
  //         pointBorderWidth: 0,
  //         pointHoverBorderWidth: 0,
  //         clip: 20,
  //         tension: 0.2,
  //       },
  //       // Gray line
  //       {
  //         data: [532, 532, 532, 404, 404, 314, 314, 314, 314, 314, 234, 314, 234, 234, 314, 314, 314, 388, 314, 202, 202, 202, 202, 314, 720, 642],
  //         borderColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
  //         borderWidth: 2,
  //         pointRadius: 0,
  //         pointHoverRadius: 3,
  //         pointBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
  //         pointHoverBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
  //         pointBorderWidth: 0,
  //         pointHoverBorderWidth: 0,
  //         clip: 20,
  //         tension: 0.2,
  //       },
  //     ],
  //   };

  return (
    <Fragment>
      <Card className='my-custom-card-active-users'>
        <CardContent>
          <div className='d-flex align-items-start gap-3'>
            <p className='active_user m-0'>
              Active Users
            </p>
            <p className='active_user_count m-0'>
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none">
                <path d="M4 0.599609L0 6.99961H8L4 0.599609Z" fill="#12B76A" />
              </svg> &nbsp;
              36%
            </p>
          </div>
          <h1 className='total_user'>20M</h1>
          <div className='custom-chart-div'>
            <LineChartWithConfig />
            {/* <LineChart data={data} options={options} /> */}
          </div>
        </CardContent>
      </Card>
    </Fragment>
  );
}

export default DashboardCard01;