import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = () => {
    const data = {
        labels: ["Verified", "Unverified"],
        datasets: [
            {
                data: [68, 32],
                backgroundColor: ["#a8e6ff", "#f4d47c"], // Colors for "Verified" and "Unverified"
                borderColor: ["#a8e6ff", "#f4d47c"],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: true,
                position: "right",
                labels: {
                    usePointStyle: true,
                },
            },
        },
    };

    return (
        <Pie data={data} options={options} />
    );
};

export default PieChart;
