import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
// import { ProfileApis } from "../../API/Login/ProfileApi";
import Toasts from '../../Components/Toasts/Toasts'
// import { dispatch } from "../../store/reducers/persist";
// import {
//   setFrProUser,
//   setauthorizationToken,
// } from "../../store/reducers/Users/users";
// import { dispatch } from "../../store/reducers/persist";
// import {
//   setFrProUser,
//   setFrProUserProfile,
// } from "../../store/reducers/Users/users";
import { StropheContext } from '../ChatClient'
import useCountryAndStatesHook from '../../customHooks/useCountryAndStatesHook'
import { ProfileApis } from '../../API/Login/ProfileApi'
import { Typography } from '@mui/material'

const AccountTypeVerifyYourselfScreen = (props) => {
  const navigate = useNavigate()
  const [toasts, setToasts] = useState(null)
  const [personalDetails, setPersonalDetails] = useState()
  const stropheContext = useContext(StropheContext)
  console.log('dashboard props', props, stropheContext)
  const accountType = localStorage.getItem('accountType')
  console.log('-------------accountType00---------', accountType)

  const { frProUser } = useSelector(
    (state) => state.Users
  )

  useCountryAndStatesHook(setToasts)

  const userPersonalDetailsByUserId = () => {
    let mobileNumberObj = {
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
      userId: frProUser?.userId,
      page: 0,
      size: 0,
      businessId: null,
      profileId: null,
    }
    ProfileApis.getUserPersonalDetailsByUserId(mobileNumberObj)
      .then((res) => {
        // Correct way to merge the objects
        setPersonalDetails(res?.data)
      })
      .catch((err) => {
        console.debug('Mobile number not Available', err)
      })
  }


  useEffect(() => {
    userPersonalDetailsByUserId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log('-----frProUser---->', frProUser);
  console.log('-----personalDetails---->', personalDetails);
  return (
    <>
      {/* <GetUserProfile /> */}
      <main className="main users chart-page ">
        {toasts ? <Toasts toastsList={toasts} /> : null}
        {/* <!-- Add more cards as needed --> */}
        <div className="text-start cursorPointer">
          <a onClick={() => navigate(-1)} className="f-w-700 color-400 ">
            <img src="../images/back.svg" alt="" className="me-3" /> Go back
          </a>
        </div>
        <div className="container-fluid p-lg-4">
          <div className="row">
            <div className="col-12 col-lg-9 mx-lg-auto">
              <div className="text-center mb-4 title">
                <h3 className='mb-4'>
                  Hi{' '}
                  <span className='text-capitalize'>{personalDetails?.fullNameOfTheUser}</span>
                  , Your account type has been set to <br className="d-none d-lg-block" /> “{accountType === 'driver' ? 'personal account' : 'Business Owner - Fleet Business'}”
                </h3>
                <p>Let’s continue to verify yourself to be a part of Alliance and continue with the membership</p>
              </div>
              <div className="verifyAccountDiv d-flex align-items-center justify-content-between">
                {/* <!-- Card 2 --> */}
                <Typography
                  component={Link}
                  className={'div'}
                  to="/alliance/personalaccounttype"
                  color="textSecondary"
                  variant="h6"
                  sx={{ textDecoration: 'none', fontSize: '1rem' }}
                >
                  <div className='roundCircleDiv'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 14V12.6667C10.6667 11.9594 10.3857 11.2811 9.88562 10.781C9.38553 10.281 8.70725 10 8 10H3.33334C2.62609 10 1.94782 10.281 1.44772 10.781C0.947623 11.2811 0.666672 11.9594 0.666672 12.6667V14M11.3333 7.33333L12.6667 8.66667L15.3333 6M8.33334 4.66667C8.33334 6.13943 7.13943 7.33333 5.66667 7.33333C4.19391 7.33333 3 6.13943 3 4.66667C3 3.19391 4.19391 2 5.66667 2C7.13943 2 8.33334 3.19391 8.33334 4.66667Z" stroke="#212120" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <strong className=" stronglinkcolor font-18 d-block py-2">
                    How we verify you
                  </strong>
                  <p className="m-0">We use the ID you provide and data from your device</p>
                </Typography>
                <Typography
                  component={Link}
                  className={'div'}
                  to="/alliance/personalaccounttype"
                  color="textSecondary"
                  variant="h6"
                  sx={{ textDecoration: 'none', fontSize: '1rem' }}
                >
                  <div className='roundCircleDiv'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <g clipPath="url(#clip0_10741_4386)">
                        <path d="M4.66667 14.6673H2.66667C2.31305 14.6673 1.97391 14.5268 1.72386 14.2768C1.47381 14.0267 1.33334 13.6876 1.33334 13.334V8.66732C1.33334 8.3137 1.47381 7.97456 1.72386 7.72451C1.97391 7.47446 2.31305 7.33398 2.66667 7.33398H4.66667M9.33334 6.00065V3.33398C9.33334 2.80355 9.12262 2.29484 8.74755 1.91977C8.37248 1.5447 7.86377 1.33398 7.33334 1.33398L4.66667 7.33398V14.6673H12.1867C12.5082 14.671 12.8203 14.5583 13.0653 14.35C13.3103 14.1417 13.4718 13.8519 13.52 13.534L14.44 7.53398C14.469 7.34289 14.4561 7.14777 14.4022 6.96215C14.3483 6.77653 14.2547 6.60485 14.1279 6.45899C14.0011 6.31314 13.8441 6.19661 13.6677 6.11746C13.4914 6.03832 13.2999 5.99846 13.1067 6.00065H9.33334Z" stroke="#212120" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_10741_4386">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <strong className=" stronglinkcolor font-18 d-block py-2">
                    Our experts will check
                  </strong>
                  <p className="m-0">We have a team of experts to complete official document verification</p>
                </Typography>
                <Typography
                  component={Link}
                  className={'div'}
                  to="/alliance/businessacoounttype"
                  color="textSecondary"
                  variant="h6"
                  sx={{ textDecoration: 'none', fontSize: '1rem' }}
                >
                  <div className='roundCircleDiv'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M7.99999 14.6673C7.99999 14.6673 13.3333 12.0007 13.3333 8.00065V3.33398L7.99999 1.33398L2.66666 3.33398V8.00065C2.66666 12.0007 7.99999 14.6673 7.99999 14.6673Z" stroke="#212120" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <strong className=" stronglinkcolor font-18 d-block py-2">
                    Fast and secure
                  </strong>
                  <p className="m-0">By pressing continue, you agree to the privacy policy</p>
                </Typography>
              </div>
              <div className='m-auto my-4 w-100 text-center'>
                <Typography
                  component={Link}
                  className={'btn flex-grow-1 ms-2 mb-4 submitBtn'}
                  to="/alliance/personalInfoUpdate"
                  color="textSecondary"
                  variant="h6"
                  sx={{ textDecoration: 'none' }}
                >
                  Continue
                </Typography>
                <p className='privacyPolicy'>By pressing continue, you agree to the <Typography
                  component={Link}
                  to="#"
                  color="textSecondary"
                  variant="h6"
                  sx={{ textDecoration: "none" }}
                ><b>privacy policy</b></Typography></p>
              </div>

            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default AccountTypeVerifyYourselfScreen
