import React, { useState } from 'react'
import PropTypes from 'prop-types'

const CustomDataTable = ({ columns, dataSource, loading, checkbox }) => {
  const [sortType, setSortType] = useState({
    date: false,
    totalDuration: false,
    status: false,
  })
  return (
    <table className="table table-striped align-middle mb-0">
      <thead style={{ position: 'sticky', top: 0 }} className="thead-light">
        <tr>
          {columns?.map((collData, collDataIdx) => {
            return (
              <th
                key={collDataIdx}
                style={{
                  minWidth: `${collData.width ? `${collData.width}px` : 'auto'}`,
                }}
                // className="col"
                // {...columnData.getHeaderProps()}
                scope="col">
                {collData.title}
                {collData?.ellipsis && (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    style={{
                      transform: `${sortType.date ? 'rotate(180deg)' : ''} `,
                    }}
                    onClick={() => {
                      setSortType({
                        date: false,
                        totalDuration: false,
                        status: true,
                      })
                    }}
                    xmlns="http://www.w3.org/2000/svg">
                    <g id="arrow-down">
                      <path
                        id="Icon"
                        d="M7.9987 3.3335V12.6668M7.9987 12.6668L12.6654 8.00016M7.9987 12.6668L3.33203 8.00016"
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"></path>
                    </g>
                  </svg>
                )}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr className="h-100 minh-41">
            <td colSpan={9} className={'m-auto text-center'}>
              Loading...
              {/* <Spin
                                    className='minh-41 d-flex flex-column justify-content-center align-items-center'
                                    spinning={loading}
                                    delay={0}
                                    tip='Loading...'
                                    size='large'
                                /> */}
            </td>
          </tr>
        ) : dataSource?.length > 0 ? (
          dataSource?.map((element, index) => {
            return (
              <tr key={element?.callCenterAgentUuid}>
                {columns?.map((collData, iindex) => {
                  return (
                    <td key={`${index}_${iindex}`} className={`text-left`}>
                      <div className="d-flex gap-4 justify-content-start ">
                        {checkbox && (
                          <input
                            className="form-check-input align-self-center"
                            type="checkbox"
                            value=""
                            id="Phoenix"
                          />
                        )}
                        {/* {collData?.render(element[collData.dataIndex])} */}
                        {collData?.render(element[collData.dataIndex], element)}
                      </div>
                      {/* {collData?.render(element)} */}
                    </td>
                  )
                })}
              </tr>
            )
          })
        ) : (
          <tr>
            <td colSpan={9} className="text-center">
              <div className="css-dev-only-do-not-override-acm2ia ant-empty ant-empty-normal">
                <div className="ant-empty-image">
                  <svg
                    width="64"
                    height="41"
                    viewBox="0 0 64 41"
                    xmlns="http://www.w3.org/2000/svg">
                    <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                      <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                      <g fillRule="nonzero" stroke="#d9d9d9">
                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                        <path
                          d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                          fill="#fafafa"></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="ant-empty-description">No data</div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
      {/* </Spin> */}
    </table>
  )
}
export default CustomDataTable

CustomDataTable.propTypes = {
  columns: PropTypes.any,
  dataSource: PropTypes.any,
  loading: PropTypes.bool,
  checkbox: PropTypes.bool,
}
