import React from 'react'
import CustomDataTable from './CustomDataTable.jsx'
import { Link } from 'react-router-dom'
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded'
import { Chip } from '@mui/material'
// import PropTypes from 'prop-types'
// import Pagination from '../../CouchServices/Pagination.jsx'

const appliedData = [
  {
    id: 1,
    name: 'Maxine Smith',
    type: 'Fleet owner',
    profileImg: '../images/av-1.png',
    date: '15 May 2024 10:20 AM',
    status: 'Freight Pro Verified',
    uploadedDocuments: 'Documents verified ID verified',
  },
  {
    id: 2,
    name: 'Phoenix Baker',
    type: 'Driver',
    profileImg: '../images/av-2.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Freight Pro Verified',
    uploadedDocuments: 'Documents verified ID verified',
  },
  {
    id: 3,
    name: 'Lana Steiner',
    type: 'Owner operator',
    profileImg: '../images/av-3.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Freight Pro Verified',
    uploadedDocuments: 'Documents verified ID verified',
  },
  {
    id: 4,
    name: 'Demi Wilkinson',
    type: 'Couch Service',
    profileImg: '../images/av-5.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Freight Pro Verified',
    uploadedDocuments: 'Documents verified ID verified',
  },
  {
    id: 5,
    name: 'Candice Wu',
    type: 'Events Creators',
    profileImg: '../images/profile-svg.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Freight Pro Verified',
    uploadedDocuments: 'Documents verified ID verified',
  },
  {
    id: 6,
    name: 'Liron Mass',
    type: 'Gas Station',
    profileImg: '../images/profile-svg.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Freight Pro Verified',
    uploadedDocuments: 'Documents verified ID verified',
  },
  {
    id: 7,
    name: 'Aden Barr',
    type: 'Eatery business',
    profileImg: '../images/av-3.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Freight Pro Verified',
    uploadedDocuments: 'Documents verified ID verified',
  },
  {
    id: 8,
    name: 'Kaelynn Coffey',
    type: 'Learning centre',
    profileImg: '../images/av-1.png',
    date: '15 May 2024 10:20 AM',
    status: 'Freight Pro Verified',
    uploadedDocuments: 'Documents verified ID verified',
  },
  {
    id: 9,
    name: 'Payton Sampson',
    type: 'Fleet Warehouse',
    profileImg: '../images/av-2.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Freight Pro Verified',
    uploadedDocuments: 'Documents verified ID verified',
  },
  {
    id: 10,
    name: 'Casey Roman',
    type: 'Owner operator',
    profileImg: '../images/av-5.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Freight Pro Verified',
    uploadedDocuments: 'Documents verified ID verified',
  },
]

const VerifiedVerificationTab = () => {
  const agentColumns = [
    {
      title: 'Documents',
      dataIndex: 'name',
      width: 100,
      ellipsis: false,
      render: (text, requestObject) => {
        return (
          <label className="form-check-label" htmlFor="Phoenix">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {requestObject?.profileImg && (
                  <img
                    className="img-fluid"
                    src={requestObject?.profileImg}
                    alt="user img"
                    style={{
                      width: '40px',
                      height: '40px',
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                  />
                )}
                {requestObject?.icon && requestObject?.icon}
              </div>
              <div className="flex-grow-1 ms-2">
                <h6 className={`${requestObject?.type ? '' : 'mb-0'}`}>
                  {requestObject?.name}
                </h6>
                {requestObject?.type && (
                  <Chip
                    size="small"
                    icon={
                      [
                        'Fleet owner',
                        'Driver',
                        'Couch Service',
                        'Events Creators',
                      ].includes(requestObject?.type) && (
                        <FiberManualRecordRoundedIcon className="icon" />
                      )
                    }
                    className="custom-chip"
                    label={requestObject?.type}
                    color="success"
                  />
                )}
              </div>
            </div>
          </label>
        )
        // return <span className="text-capitalize">{text}</span>
      },
    },
    {
      title: 'Date received',
      dataIndex: 'date',
      width: 100,
      ellipsis: true,
      render: (text) => {
        return <span className="text-capitalize">{text}</span>
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      ellipsis: true,
      render: (text) => {
        return (
          <Chip
            size="small"
            // icon={
            //   ['Fleet owner', 'Driver', 'Couch Service', 'Events Creators'].includes(
            //     requestObject?.type
            //   ) && <FiberManualRecordRoundedIcon className="icon" />
            // }
            className={
              ['Documents applied', 'Submitted'].includes(text)
                ? `custom-chip`
                : [
                    'Documents Declined',
                    'Video ID Declined',
                    'ID Verification Declined',
                  ].includes(text)
                ? 'custom-chip-error'
                : text === 'Verification In progress'
                ? 'custom-chip-in-progress'
                : text === 'Freight Pro Verified'
                ? 'custom-chip-freight-pro-verified'
                : text === 'ID verification'
                ? 'custom-chip-id-verification'
                : ''
            }
            label={text}
            color="error"
          />
        )
      },
    },
    {
      title: 'Details',
      dataIndex: 'uploadedDocuments',
      width: 100,
      ellipsis: false,
      render: (text) => {
        return (
          <div className="d-flex align-items-center justify-content-between w-100">
            {text}
            {/* view-documents */}
            <Link to={'/csr/documents-verification-list'}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none">
                <g clipPath="url(#clip0_3103_45327)">
                  <path
                    d="M10.4998 6L9.08984 7.41L13.6698 12L9.08984 16.59L10.4998 18L16.4998 12L10.4998 6Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3103_45327">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </Link>
          </div>
        )
      },
    },
  ]
  return (
    <>
      <div id="Couch_requests" style={{ height: '100%' }}>
        <div id="Applications_recived">
          <div className="responsive-tebal border rounded-3 overflow-hidden billing-table">
            <CustomDataTable columns={agentColumns} dataSource={appliedData} />
            {/* <table className="table table-striped align-middle mb-0">
              <thead>
                <tr>
                  <th scope="col">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Actions"
                      />
                      <label className="form-check-label " htmlFor="Actions">
                        User
                      </label>
                    </div>
                  </th>
                  <th scope="col">
                    Date received
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      style={{
                        transform: `${sortType.date ? 'rotate(180deg)' : ''} `,
                      }}
                      onClick={() => {
                        setSortType({
                          date: false,
                          totalDuration: false,
                          status: true,
                        })
                      }}
                      xmlns="http://www.w3.org/2000/svg">
                      <g id="arrow-down">
                        <path
                          id="Icon"
                          d="M7.9987 3.3335V12.6668M7.9987 12.6668L12.6654 8.00016M7.9987 12.6668L3.33203 8.00016"
                          stroke="#667085"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"></path>
                      </g>
                    </svg>
                  </th>
                  <th scope="col">
                    Status
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g id="arrow-down">
                        <path
                          id="Icon"
                          d="M7.9987 3.3335V12.6668M7.9987 12.6668L12.6654 8.00016M7.9987 12.6668L3.33203 8.00016"
                          stroke="#667085"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"></path>
                      </g>
                    </svg>
                  </th>
                  <th scope="col">Details</th>
                </tr>
              </thead>
              <tbody>
                {appliedData.length > 0 ? (
                  appliedData.map((requests) => (
                    <CSRUserRequest
                      checkbox={true}
                      key={requests?.id}
                      arrowLink={'/documents-verification-list'}
                      requestObject={requests}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={9} className="text-center">
                      <div className="css-dev-only-do-not-override-acm2ia ant-empty ant-empty-normal">
                        <div className="ant-empty-image">
                          <svg
                            width="64"
                            height="41"
                            viewBox="0 0 64 41"
                            xmlns="http://www.w3.org/2000/svg">
                            <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                              <ellipse
                                fill="#f5f5f5"
                                cx="32"
                                cy="33"
                                rx="32"
                                ry="7"></ellipse>
                              <g fillRule="nonzero" stroke="#d9d9d9">
                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                <path
                                  d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                  fill="#fafafa"></path>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="ant-empty-description">No data</div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table> */}
          </div>
          <div className="meetup-pagination my-3">
            <ul className="pagination justify-content-center custom-pagnition ">
              <li className="page-item disabled">
                <a className="page-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none">
                    <path
                      d="M15.8332 10.0001H4.1665M4.1665 10.0001L9.99984 15.8334M4.1665 10.0001L9.99984 4.16675"
                      stroke="#667085"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"></path>
                  </svg>
                  Previous
                </a>
              </li>
              <li className="page-item active">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  ...
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  8
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  9
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  10
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  Next
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none">
                    <path
                      d="M4.1665 10.0001H15.8332M15.8332 10.0001L9.99984 4.16675M15.8332 10.0001L9.99984 15.8334"
                      stroke="#667085"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          {/* <Pagination
            page={page}
            setPage={setPage}
            totalPages={Math.ceil(totalPages / size)}
          /> */}
        </div>
      </div>
    </>
  )
}

export default VerifiedVerificationTab

// AllVerificationTab.propTypes = {
//   handleGetIndividualGuestReqObjDetails: PropTypes.func,
//   handleTriggerCouchTab: PropTypes.func,
//   handleGuestRequestList: PropTypes.func,
// }
