import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// eslint-disable-next-line react/prop-types
const BarChart = ({ data, options }) => {
    return (
        <Bar data={data} options={options} />
    );
};

export default BarChart;
