import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Toasts from '../../Components/Toasts/Toasts'
import { StropheContext } from '../ChatClient'
import useCountryAndStatesHook from '../../customHooks/useCountryAndStatesHook'
import { ProfileApis } from '../../API/Login/ProfileApi'
import { Typography } from '@mui/material'

const BusinessAccountTypeScreen = (props) => {
  const navigate = useNavigate()
  const [toasts, setToasts] = useState(null)
  const [personalDetails, setPersonalDetails] = useState()
  const stropheContext = useContext(StropheContext)
  console.log('dashboard props', props, stropheContext)

  const { frProUser } = useSelector(
    (state) => state.Users
  )

  useCountryAndStatesHook(setToasts)

  const userPersonalDetailsByUserId = () => {
    let mobileNumberObj = {
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
      userId: frProUser?.userId,
      page: 0,
      size: 0,
      businessId: null,
      profileId: null,
    }
    ProfileApis.getUserPersonalDetailsByUserId(mobileNumberObj)
      .then((res) => {
        // Correct way to merge the objects
        setPersonalDetails(res?.data)
      })
      .catch((err) => {
        console.debug('Mobile number not Available', err)
      })
  }


  useEffect(() => {
    userPersonalDetailsByUserId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log('-----frProUser---->', frProUser);
  console.log('-----personalDetails---->', personalDetails);
  return (
    <>
      {/* <GetUserProfile /> */}
      <main className="main users chart-page h-100 d-flex flex-column align-items-center justify-content-center">
        {toasts ? <Toasts toastsList={toasts} /> : null}
        <div className="container-fluid p-lg-4">
          <div className="row">
            <div className="col-12 col-lg-9 mx-lg-auto">
              <div className="text-center mb-5 title">
                <h3 className='mb-5'>
                  Select business account type
                </h3>
              </div>
              <div className="col-lg-5 col-md-6 mx-auto">
                {/* <!-- Card 1 --> */}
                <Typography
                  component={Link}
                  className={'card noUnderline'}
                  to={'/alliance/accountTypeVerifyYourself'}
                  onClick={() => localStorage.setItem('accountType', 'fleet')}
                >
                  <strong className=" stronglinkcolor font-18 d-block pb-2">Fleet business</strong>
                  <p className="m-0">Lease vehicles and manage logistics</p>
                </Typography>

                {/* <!-- Add more cards as needed --> */}
                <div className="text-center mt-5 d-flex align-items-center justify-content-center cursorPointer">
                  <a onClick={() => navigate(-1)} className="f-w-700 color-400 ">
                    <img src="images/back.svg" alt="" className="me-3" /> Go back
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </main>
    </>
  )
}

export default BusinessAccountTypeScreen
