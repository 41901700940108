/* eslint-disable react/react-in-jsx-scope */
import axios from 'axios'
import parse from 'html-react-parser'
import moment from 'moment'
import { BuddiesIcon, CouchServicesIcon, EventsIcon, FeedIcon, JobsIcon, LMSIcon, MediaIcon, MyProfileIcon, WelComeIcon } from './SideBarIcons'


export const routesData = [
  {
    id: 1,
    path: '/dashboard',
    element: <WelComeIcon />,
    label: 'Welcome',
    type: 'all'
  },
  {
    id: 2,
    path: '/feed',
    element: <FeedIcon />,
    label: 'Feed',
    type: 'pro'
  },
  {
    id: 3,
    path: '/MyProfile',
    element: <MyProfileIcon />,
    label: 'My profile',
    type: 'all'
  },
  {
    id: 4,
    path: '/media',
    element: <MediaIcon />,
    label: 'Media',
    type: 'pro'
  },
  {
    id: 5,
    path: '/jobs',
    element: <JobsIcon />,
    label: 'Jobs',
    type: 'pro'
  },
  {
    id: 6,
    path: '/events',
    element: <EventsIcon />,
    label: 'Events',
    type: 'pro'
  },
  {
    id: 7,
    path: '/buddies',
    element: <BuddiesIcon />,
    label: 'Buddies',
    type: 'pro'
  },
  {
    id: 8,
    path: '/couchservices',
    element: <CouchServicesIcon />,
    label: 'Couch services',
    type: 'pro'
  },
  {
    id: 9,
    path: '#',
    element: <LMSIcon />,
    label: 'LMS',
    type: 'pro'
  },
]

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

export function formatCardNumber(cardNumberString) {
  // Remove all non-digit characters from the input
  var cleaned = ('' + cardNumberString).replace(/\D/g, '')

  // Check if the cleaned string is a valid sequence of 15 or 16 digits
  if (cleaned.length === 16) {
    // Match the cleaned number into groups: first optional group (1), followed by groups of four digits each
    var match = cleaned.match(/.{1,4}/g)

    if (match) {
      // Join the groups with a space in between to format as "#### #### #### ####"
      return match.join(' ')
    }
  }
  return null // Return null if the input doesn't match the expected format
}

// program to Date Format (dd-mm-yyyy).

export function formatDate(dateVal) {
  const date = new Date(dateVal)
  const yyyy = date.getFullYear()
  let mm = date.getMonth() + 1 // Months start at 0!
  let dd = date.getDate()

  if (dd < 10) dd = '0' + dd
  if (mm < 10) mm = '0' + mm

  const formattedDate = dd + '-' + mm + '-' + yyyy
  return formattedDate
}

// program to Date Format (dd mmm yyyy).

export function newFormatDate(dateVal) {
  const date = new Date(dateVal)
  // Define months array for month names
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  // Extract day, month, and year from the date object
  const day = ('0' + date.getDate()).slice(-2) // Ensure day has at least 2 characters
  const monthIndex = date.getMonth() // Returns 0 for January, 1 for February, etc.
  const year = date.getFullYear()

  // Format the date in the desired format
  const formattedDate = `${day} ${months[monthIndex]} ${year}`

  return formattedDate
}

// program to merge and remove duplicate value from an array

export function getUniqueAfterMerge(arr1, arr2) {
  // merge two arrays
  let arr = [...arr1, ...arr2]
  // removing duplicate
  let uniqueArr = [...new Set(arr)]
  return uniqueArr
}
export const urlToImageFile = async (url, filename) => {
  const response = await axios.get(url, { responseType: 'blob' })
  const blob = response.data
  const file = new File([blob], filename, { type: blob.type })

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve({
        image: reader?.result,
        file: file,
        name: file?.name,
        type: file?.type,
        lastModified: file?.lastModified,
        lastModifiedDate: file?.lastModifiedDate,
      })
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}
export const urlToVideoFile = async (url, filename, thumbnailUrl) => {
  const response = await axios.get(url, { responseType: 'blob' })
  const blob = response.data
  const file = new File([blob], filename, { type: blob.type })

  return {
    video: url,
    thumbnailUrl: thumbnailUrl,
    file: file,
    name: file?.name,
    type: file?.type,
    lastModified: file?.lastModified,
    lastModifiedDate: file?.lastModifiedDate,
  }
}
export const formatFileSize = (sizeInBytes) => {
  if (sizeInBytes < 1024) return `${sizeInBytes} bytes`
  else if (sizeInBytes < 1048576) return `${(sizeInBytes / 1024).toFixed(2)} KB`
  else return `${(sizeInBytes / 1048576).toFixed(2)} MB`
}

// Function to convert URLs in text to anchor tags
export const convertLinksToAnchorTags = (text) => {
  // Regex to identify URLs in the text
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Replace URLs with <a> tags
  const processedText = text.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer" tooltip=${url} style="color:navyblue;font-weight:bold">${url}</a>`;
  });
  processedText.replace('\\n', (url) => {
    return `<br />`;
  });
  // Return the parsed HTML
  return parse(processedText);
};

export const getTimeDifference = (createdAt) => {
  const data = createdAt; // UTC time string
  const utcDate = moment.utc(data, "YYYY-MM-DD HH:mm"); // Parse as UTC
  const localDate = utcDate.local(); // Convert to local time zone
  const now = moment(); // Current time

  // Ensure the createdAt date is valid
  if (!localDate.isValid()) {
    return 'Invalid date';
  }

  const diffMinutes = now.diff(localDate, 'minutes');

  if (diffMinutes < 0) {
    // Handle future dates
    return 'In the future';
  } else if (diffMinutes < 60) {
    return `${diffMinutes} minutes ago`;
  } else if (diffMinutes < 1440) { // 1440 minutes = 24 hours
    const diffHours = Math.floor(diffMinutes / 60);
    return `${diffHours} hours ago`;
  } else {
    // Use moment's 'fromNow()' for more than 24 hours
    return localDate.fromNow();
  }
};