import { persiststore } from '../../store//reducers/persist'
import { api } from '../Configs/AxiosConfigs'
import { defineCancelApiObject } from '../Configs/AxiosUtils'

const state = persiststore.getState()

export const LoginApis = {
  getBearerToken: function () {
    // console.log(":getBearerToken", `Bearer:${localStorage.getItem("Authorization")}`)
    return {
      "Authorization": `Bearer:${localStorage.getItem("Authorization")}`
    }
  },
  getAccessToken: function () {
    // console.log(":getAccessToken", JSON.parse(localStorage.getItem("user")))
    return {
      "user": JSON.parse(localStorage.getItem("user"))
    }
  },
  signIn: async function (payload, cancel = false) {
    // console.debug(`/v1/signin`, api, password, username, operatedByUserId, operatedByModule)
    const response = await api.request({
      url: `/v1/signin`,
      method: 'POST',
      headers: {},
      data: {
        userName: payload?.username, // This is the body part
        password: payload?.password,
        operatedByUserId: payload?.operatedByUserId,
        operatedByModule: payload?.operatedByModule
        // "fre8ProAccessToken": token
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    // console.debug("response", response)
    console.debug(
      'response',
      response,
      document.cookie,
      ':::Fre8Pro-Login:::',
      response?.headers?.get('Fre8Pro-Login')
    )

    return response
  },

  businessSignIn: async function (username, password, cancel = false) {
    console.debug(`/v1/signin/business`, api, password, username)
    const response = await api.request({
      url: `/v1/signin/business`,
      method: 'POST',
      headers: {},
      data: {
        userName: username, // This is the body part
        password: password,
        // "fre8ProAccessToken": token
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    // console.debug("response", response)
    console.debug(
      'response',
      response,
      document.cookie,
      ':::Fre8Pro-Login:::',
      response?.headers?.get('Fre8Pro-Login')
    )

    return response.data
  },
  updateUserEmail: async function (emailObj, cancel = false) {
    console.debug('update useremail', state?.Users)
    const response = await api.request({
      url: `/v1/user/email/update`,
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer:${localStorage.getItem('Authorization')}`
      },
      data: emailObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },
  getPrimaryEmail: async function (mobileObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/email/view/personal/primary`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer:${localStorage.getItem('Authorization')}`
      },
      data: mobileObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },
  getPrimaryMobileNumber: async function (mobileObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/mobilenumber/view/personal/primary`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer:${localStorage.getItem('Authorization')}`
      },
      data: mobileObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },
  getVerificationIdByEmail: async function (verificationIdObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/email/verification/view/personal/primary`,
      method: 'POST',
      headers: {
        Authorization: `Bearer:${localStorage.getItem('Authorization')}`
      },
      data: verificationIdObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  getVerificationIdByMobileNumber: async function (verificationIdObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/mobilenumber/verification/view/personal/primary`,
      method: 'POST',
      headers: {
        Authorization: `Bearer:${localStorage.getItem('Authorization')}`
      },
      data: verificationIdObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  updateUserMobileNumber: async function (numberObj, cancel = false) {
    console.debug('update usermobile', state?.Users, 'Updated objecty   ', {
      ...numberObj,
      fre8ProAccessToken: state?.Users?.authorizationToken,
    })
    const response = await api.request({
      url: `/v1/user/mobile/update`,
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer:${localStorage.getItem('Authorization')}`
      },
      data: numberObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },
  userIsAvailable: async function (username, token, cancel = false) {
    const response = await api.request({
      url: `/v1/availableUser`,
      method: 'POST',
      headers: {},
      data: {
        mobileNumber: username?.mobileNumber,
        email: username?.email,
        userName: username?.userName,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },
  emailIsAvailable: async function (emailObj, cancel = false) {
    const response = await api.request({
      url: `/v1/availableEmail`,
      method: 'POST',
      headers: {},
      data: emailObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },

  mobileNumberIsAvailable: async function (emailObj, cancel = false) {
    const response = await api.request({
      url: `/v1/availableMobile`,
      method: 'POST',
      headers: {},
      data: emailObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },
  createUser: async function (objectUser, token, cancel = false) {
    const response = await api.request({
      url: `/v1/fre8usr`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
      data: objectUser,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    // console.debug("response", response)
    console.debug(
      'createUser',
      response,
      document.cookie,
      '::: Fre8Pro - Login::: ',
      response?.headers?.get('Fre8Pro-Login')
    )
    return response
  },
  createFleetBusinessUser: async function (objectUser, token, cancel = false) {
    const response = await api.request({
      url: `/v1/user/business`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
      data: objectUser,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    // console.debug("response", response)
    console.debug(
      'createUser',
      response,
      document.cookie,
      '::: Fre8Pro - Login::: ',
      response?.headers?.get('Fre8Pro-Login')
    )
    return response.data
  },
  logout: async function (logoutData, cancel = false) {
    // console.log('--------------localStorage--------->', localStorage.getItem('Authorization'))
    const response = await api.request({
      url: `/v1/user/signout`,
      method: 'POST',
      headers: {
        Authorization: `Bearer:${localStorage.getItem('Authorization')}`
      },
      data: {
        fre8ProAccessToken: logoutData?.fre8ProAccessToken,
        operatedByModule: logoutData?.operatedByModule,
        operatedByUserId: logoutData?.operatedByUserId,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)
    return response.data
  },
  getCountries: async function (cancel = false) {
    const response = await api.request({
      url: `/v1/countries`,
      method: 'GET',
      headers: {},
      data: {},
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },
  getStates: async function (countryId, cancel = false) {
    const response = await api.request({
      url: `/v1/statesv2/${countryId}`,
      method: 'GET',
      headers: { Accept: 'application/json, text/plain, */*' },
      data: {
        countryId: countryId,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },
  getCaptcha: async function (token, cancel = false) {
    console.debug('testUser:::Token:: get captch', token)
    const response = await api.request({
      url: `/v1/captcha/image`,
      method: 'POST',
      headers: {},
      // headers: { "Authorization": `JSESSIONID:` + token },
      // headers: { "JSESSIONID": token },
      data: { fre8ProAccessToken: token },
      // data: {},
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response captcha', response, document.cookie, token)

    return response.data
  },
  refreshCaptcha: async function (token, cancel = false) {
    console.debug('testUser:::Token rfefresh captch::', token)
    const response = await api.request({
      url: `/v1/captcha/image/refresh`,
      method: 'POST',
      headers: { Accept: 'image/jpeg, application/json, text/plain, */*' },
      // data: {},
      data: { fre8ProAccessToken: token },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug(
      'refresh captcha',
      response,
      document.cookie,
      ':::Fre8Pro-token:::',
      token
    )
    // for (let header of response.headers) {
    //     console.debug('refresh captcha for loop', header);
    // }
    return response.data
  },
  forgotPassword: async function (passwordObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/forgotpassword`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
      data: passwordObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response captcha', response)

    return response.data
  },
  getToken: async function (cancel = false) {
    const response = await api.request({
      url: `/v1/token`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
      data: {},
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('Get Token', response)

    return response.data
  },
  searchFriend: async function (searchObj, headers, cancel = false) {
    console.debug('::searchFriend:: searchObj ', searchObj, headers)
    const response = await api.request({
      url: `/v1/search/paginated`,
      method: 'POST',
      headers: headers,
      data: searchObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    console.debug(' searchFriend Data', response)
    return response.data
  },
  getImageForOtherUser: async function (userObj, cancel = false) {
    // let accesToken = {
    //   fre8ProAccessToken: userObj?.fre8ProAccessToken,
    // }
    console.log("getImageForOtherUser::::", userObj)
    const response = await api.request({
      url: `/v1/profile/imagev2/other`,
      method: 'POST',
      headers: { "Authorization": `Bearer:${localStorage.getItem("Authorization")}` },
      data: userObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  fetchsearchFriendImage: async function (searchObj, cancel = false) {
    const response = await api.request({
      url: searchObj?.url,
      method: 'POST',
      headers: {},
      data: searchObj?.accessToken,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('fetchsearchFriendImage ', response)

    return response.data
  },
}

// const loadImage = (friend, searchObj) => {
//     return new Promise((resolve, reject) => {

//         let objSearchImg = {
//             url: friend?.userProfileImgUrl?.split('/fre8pro-backend')[1],
//             accessToken: {
//                 "fre8ProAccessToken": searchObj?.fre8ProAccessToken,
//             }
//         }
//         LoginApis.fetchsearchFriendImage(objSearchImg).then((res) => {
//             // console.debug('load image search', res)
//             friend.userProfileImgUrlData = res || ''
//             console.debug('updatedg user data', friend)

//             setTimeout(() => {
//                 resolve(friend?.userProfileImgUrlData)
//             }, 100)
//         }).catch((err) => {
//             resolve(friend?.userProfileImgUrlData)
//         })

//     })
// }

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(LoginApis)
