import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { StropheContext } from '../ChatClient'
import { ProfileApis } from '../../API/Login/ProfileApi'
import CheckoutPlanToUpgrade from '../ProfilePage_new/AccountSettings/Membership/CheckoutPlanToUpgrade'
// import { Typography } from '@mui/material'

const CheckoutPlanScreen = (props) => {
  const navigate = useNavigate()
  const [personalDetails, setPersonalDetails] = useState()

  const stropheContext = useContext(StropheContext)
  console.log('dashboard props', props, stropheContext)

  const { frProUser } = useSelector(
    (state) => state.Users
  )


  const userPersonalDetailsByUserId = () => {
    let mobileNumberObj = {
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
      userId: frProUser?.userId,
      page: 0,
      size: 0,
      businessId: null,
      profileId: null,
    }
    ProfileApis.getUserPersonalDetailsByUserId(mobileNumberObj)
      .then((res) => {
        // Correct way to merge the objects
        setPersonalDetails(res?.data)
      })
      .catch((err) => {
        console.debug('Mobile number not Available', err)
      })
  }


  useEffect(() => {
    userPersonalDetailsByUserId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log('-----frProUser---->', frProUser);
  console.log('-----personalDetails---->', personalDetails);
  return (
    <>
      {/* <GetUserProfile /> */}
      <main className="main users chart-page h-100 ">
        <div className="text-start cursorPointer">
          <a onClick={() => navigate(-1)} className="f-w-700 color-400 ">
            <img src="../images/back.svg" alt="" className="me-3" /> Go back
          </a>
        </div>
        <div className="container-fluid p-lg-4 h-100">
          <CheckoutPlanToUpgrade
          // setupgradeButtonClicked={setupgradeButtonClicked}
          // setbuyMembershipButtonClicked={setbuyMembershipButtonClicked}
          // setshowCurrentPlan={setshowCurrentPlan}
          // fleetOwnerPlan={fleetOwnerPlan}
          />
        </div>
      </main>
    </>
  )
}

export default CheckoutPlanScreen
