/* eslint-disable */
import $ from 'jquery'
import React, { createContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { $pres, Strophe } from 'strophe.js'
import { UsersApis } from '../../API/Login/UsersApi'
import Toasts from '../../Components/Toasts/Toasts'
import FooterLayout from '../../layout/FooterLayout'
import HeaderContent from '../../layout/MainLayout/Header/HeaderContent'

let buddyObj = {
  jid: 'jid',
  ask: '', //subscibe In IQ stanza can be available
  subscription: 'subscription', // None or subscribed unsubscribed - In IQ stanza
  // "type": type || 'subscribe'
}

let count = 1
export const StropheContext = createContext()

const ChatClientMainComponent = () => {
  const { frProUser, searchFriendsResults, otherUserInfo, buddyList } =
    useSelector((state) => state.Users)
  const [buddyChats, setbuddyChats] = useState(new Map())
  const [buddyMessages, setbuddyMessages] = useState([])
  const [connectionObj1, setconnectionObj1] = useState(null)
  const [isOnline, setIsOnline] = useState(false)
  // const [stopReloadTillItsConnecting, setstopReloadTillItsConnecting] = useState(false)
  // const [buddiesList, setbuddiesList] = useState([])
  // const [notificationList, setnotificationList] = useState([]);
  const [showToasts, setshowToasts] = useState(false)
  const [toasts, setToasts] = useState(null)
  const [composing, setcomposing] = useState(false)
  const [chatData, setchatData] = useState({ "users": [], "messages": [] })
  // const stropheHandlers = useRef(null);
  // const onMessageHandler = useRef(null);
  // const onPresenceHandler = useRef(null);
  // const onIqHandler = useRef(null);

  console.log(
    'frProUser:::: UseloadChatClient',
    frProUser,
    searchFriendsResults,
    isOnline,
    otherUserInfo,
    buddyList, setchatData, chatData
  )
  let connection = undefined

  function loadChatclient() {
    if (count > 2 || connectionObj1?.connected === true) {
      return false
    }
    // if (!(connectionObj1 === null || connectionObj1 === undefined)) {
    //     return
    // }
    // // const { frProUser } = useSelector((state) => state.Users);
    // // window.connectionObj = null;
    // if (connection !== undefined) {
    //     return false
    // }

    let userList = JSON.parse(localStorage.getItem('usersList'))
    let loogedInUser = JSON.parse(localStorage.getItem('user'))
    let users = userList.filter((item) => {
      return item?.fre8ProUserId === loogedInUser?.fre8ProUserId
    })
    // setbuddiesList(loogedInUser?.buddyList || [])
    console.log('need to update this user', users[0], loogedInUser)
    let user = frProUser
    if (user?.length <= 0 || user === undefined || user === null) {
      return
    }
    console.log('------->>>>>>>frProUser>>>>>>>>>----->>>>>>>>', frProUser)
    let BOSH_SERVICE = user?.buddyLiveUrl
    // let BOSH_SERVICE = 'https://of.varahisoft.in:7443/http-bind/' ||
    // user?.frProUserProfile?.buddyLiveConfigForm?.jabberServer

    // connection = new Strophe.Connection(BOSH_SERVICE);
    console.log('Setting this connection', Strophe)
    connection = new Strophe.Connection(BOSH_SERVICE)

    let jabberId = user?.jabberId + '@of.varahisoft.in',
      // let jabberId = user?.updateProfileForm?.jabberId ||
      //   user?.updateProfileForm?.buddyLiveConfigForm?.jabberId ||
      //   user?.frProUserProfile?.buddyLiveConfigForm?.jabberId ||
      //   user?.jabberId,
      jabberPwd = user?.jabberPassword,
      // jabberPwd =
      //   user?.updateProfileForm?.jabberPassword ||
      //   user?.updateProfileForm?.buddyLiveConfigForm?.jabberPassword ||
      //   user?.frProUserProfile?.buddyLiveConfigForm?.jabberPassword ||
      //   user?.password,
      onConnect = (status) => {
        console.log('------>>>>>>>>>>>>>>>>>>>>>>>>>----------', jabberId, '---------', jabberPwd)
        console.log('Received response form onConnect', status)
        if (status === Strophe.Status.CONNECTING) {
          // setstopReloadTillItsConnecting(true)
          console.log('Strophe is connecting.')
        } else if (status === Strophe.Status.CONNFAIL) {
          console.log('Strophe failed to connect.')
          // $('#connect').get(0).value = 'connect';
        } else if (status === Strophe.Status.DISCONNECTING) {
          console.log('Strophe is disconnecting.')
        } else if (status === Strophe.Status.DISCONNECTED) {
          console.log('Strophe is disconnected.')
          connection.removeHandlers(
            onMessage,
            null,
            'message',
            null,
            null,
            null
          )
          connection.removeHandlers(
            onSubscriptionRequest,
            null,
            'presence',
            'subscribe'
          )
          connection.removeHandlers(onPresence, null, 'presence')
          setIsOnline(false)
          // $('#connect').get(0).value = 'connect';
        } else if (status === Strophe.Status.CONNECTED) {
          console.log('Strophe is connected.')
          connection.send($pres())
          setIsOnline(true)
          // connection.disconnect();
          // if (loogedInUser?.buddyList === undefined) {
          // }
          //Add handler
          connection.addHandler(onMessage, null, 'message', null, null, null)
          connection.addHandler(
            onSubscriptionRequest,
            null,
            'presence',
            'subscribe'
          )
          connection.addHandler(onPresence, null, 'presence')
          // connectHandlers();
          setconnectionObj1(connection)
          //get roster
          getBuddyListRoster(connection)
          getReceivedRequests()
        }
      }
    // connection.rawInput = rawInput;
    // connection.rawOutput = rawOutput;
    //TODO: Convert into below format
    //TODO:   Null Check
    if (!(jabberId && jabberPwd)) {
      console.error(`Jabber id ${jabberId} and jabberpwd is null`)
      return false
    }
    connection?.connect(jabberId, jabberPwd, onConnect)
    connection.rawInput = rawInput
    connection.rawOutput = rawOutput

    window.connectionObj = connection
  }
  function getReceivedRequests() {
    console.log("getReceivedRequests:::::called", frProUser,)
    //Request received
    let obj = {
      // fre8ProAccessToken: frProUser.fre8ProAccessToken,
      // requestFromUser: fromjid,
      // requestTime: new Date().toISOString(),


      "fre8ProAccessToken": frProUser?.fre8ProAccessToken,
      "page": 1,
      "size": 50,
      "id": "<string>",
      "userId": frProUser?.userId,
      "businessId": "<string>",
      "profileId": frProUser?.profileId

    }

    // UsersApis.userBuddyRequestReceivedBuddyLive(obj)//
    UsersApis.userBuddyRequestReceived(obj)
      .then((res) => {
        console.log(
          'success userBuddyRequestReceived',
          res?.data
        )
        // buddiesList?.push(res?.data)
        // setbuddiesList(buddiesList)
        let item = [
          {
            name: 'Request received from ' + fromjid,
            type: 'Request Received',
          },
        ]
        setToasts(item)
        setshowToasts(true)
        setTimeout(() => {
          // props?.setshowSearchResults(false)
          setshowToasts(false)
        }, 4000)
      })
      .catch((err) => {
        console.log('Err userBuddyRequestReceived ', err)
        // setbuddiesList(buddiesList)
        // setbuddiesList(buddiesList)
        let fromjid = "test"
        let item = [
          {
            name:
              'Request received from ' +
              fromjid +
              'But something went wrong while retrieving data',
            type: 'Request Received Failed',
            color: 'red',
          },
        ]
        setToasts(item)
        setshowToasts(true)
        setTimeout(() => {
          // props?.setshowSearchResults(false)
          setshowToasts(false)
        }, 4000)
      })
  }

  function rawInput(data) {
    console.log('RECV: ' + data)
  }

  function rawOutput(data) {
    console.log('SENT: ' + data)
  }
  function jid_to_id(jid) {
    return Strophe?.getBareJidFromJid(jid).replace('@', '-').replace(/\./g, '-')
  }
  function custom_jid_to_id(jid) {
    return jid?.replace('@', '-').replace(/\./g, '-')
  }
  function onMessage(message) {
    var to = message?.getAttribute('to')
    var from = message?.getAttribute('from')
    var type = message?.getAttribute('type')
    var elems = message?.getElementsByTagName('body')
    let fullMessage = {}
    if (type === 'chat' && elems.length > 0) {
      let body = elems[0]
      fullMessage = {
        to: to,
        from: Strophe.getBareJidFromJid(from),
        type: type,
        msg: Strophe.getText(body),
      }

      console.log('CHAT: I got a message from ' + from + ': ' + fullMessage)
    }
    // we must return true to keep the handler alive.
    // returning false would remove it after it finishes.
    console.log('tp=o', to)

    var full_jid = $(message).attr('from')
    var jid = Strophe.getBareJidFromJid(full_jid)
    //lineitem jid to be matched
    var jid_id = jid_to_id(jid)

    if ($('#chat-' + jid_id).length === 0) {
      // $('#chat-area').tabs('add', '#chat-' + jid_id, jid);
      // $('#chat-' + jid_id).append("<div class='chat-messages'></div><input type='text' class='chat-input'>");
    }

    // $('#chat-' + jid_id).data('jid', full_jid);

    // $('#chat-area').tabs('select', '#chat-' + jid_id);
    // $('#chat-' + jid_id + ' input').focus();

    var composing = $(message).find('composing')

    if (composing?.length > 0) {
      $('#chat-' + jid_id + ' .chat-messages').append(
        "<div class='chat-event'>" +
        Strophe.getNodeFromJid(jid) +
        ' is typing ... </div>'
      )
      fullMessage['composing'] = true
      setcomposing(true)
      // Gab.scroll_chat(jid_id);
    } else {
      setcomposing(false)
    }

    let body = $(message).find('html > body')

    if (body.length === 0) {
      body = $(message).find('body')
      if (body.length > 0) {
        body = body.text()
      } else {
        body = null
      }
    } else {
      body = body.contents()

      var span = $('<span></span>')

      body.each(function () {
        if (document.importNode) {
          $(document.importNode(this, true)).appendTo(span)
        } else {
          // IE workaround
          span.append(this.xml)
        }
      })

      body = span
    }

    if (body) {
      // remove notifications since user is now active
      $('#chat-' + jid_id + ' .chat-event').remove()

      // add the new message
      $('#chat-' + jid_id + ' .chat-messages').append(
        "<div class='chat-message'>" +
        "&lt;<span class='chat-name'>" +
        Strophe.getNodeFromJid(jid) +
        '</span>&gt;' +
        "<span class='chat-text'>" +
        '</span></div>>'
      )

      $('#chat-' + jid_id + ' .chat-message:last .chat-text').append(body)

      let buddyMsg = buddyMessages
      if (buddyMsg && buddyMsg[jid_id] !== undefined) {
        buddyMsg[jid_id].push(body)
      } else {
        if (buddyMsg) {
          buddyMsg[jid_id] = []
          buddyMsg[jid_id].push(...new Set(fullMessage)) //eslint-disable-line
        }
      }

      console.log('Buddychars:::000', buddyChats)
      buddyChats.set(jid_id, fullMessage)
      console.log('Buddychars:::111', buddyChats)
      setbuddyChats(buddyChats)
      console.log('Buddychars:::222', buddyChats)
      // Gab.scroll_chat(jid_id);
      setbuddyMessages(buddyMsg || [])
      console.log(fullMessage)

      // message received
      // const newMessage = {
      //   id: chatData.length + 1,
      //   from: Strophe.getBareJidFromJid(from),
      //   to: message?.getAttribute('to'),
      //   message: body,
      //   type: type,
      // };
      // setchatData([...chatData, newMessage]);

      // let msgData = chatData;
      // let filtermsgData = msgData.users.filter((item) => { return item?.id === custom_jid_to_id(jid) });
      // if (filtermsgData?.length > 0) {
      //   filtermsgData[0]?.messages?.push({
      //     "id": custom_jid_to_id(jid),
      //     "from": Strophe.getBareJidFromJid(from),
      //     "to": to,
      //     "msg": Strophe.getText(body)

      //   })
      //   setchatData(data)
      // }


      // eslint-disable-next-line
      let msgData = chatData
      msgData.users.map((item) => {
        if (item?.id === custom_jid_to_id(jid)) {
          item?.messages?.push({
            "id": custom_jid_to_id(frProUser?.jabberId),
            "from": jid,
            "to": frProUser?.jabberId,
            "msg": body,
            "msgType": "received"
          })
        }
      });
      setchatData(msgData)

    }
    return true
  }
  // // --------------When we receive buddy request
  function onSubscriptionRequest(stanza, connectionObj) {
    console.log('Stanza length', stanza, stanza?.length)
    if (stanza?.length === 0) {
      return
    }
    console.log('connection obj', connectionObj1, connectionObj)
    let loogedInUser = JSON.parse(localStorage.getItem('user'))
    if (loogedInUser?.buddyList === undefined) {
      getRoster(cb)
    } else {
      cb()
    }

    function cb() {
      let loogedInUser = JSON.parse(localStorage.getItem('user'))
      var fromjid = stanza?.getAttribute('from') // user jid who sent request
      // var fromName = $(stanza).attr('name'); // user name who sent request
      var fromType = stanza?.getAttribute('type') // user type who sent request
      // var fromImageUrl = $(stanza).attr('imageUrl'); // user image url who sent request
      // var fromStatus = $(stanza).attr('status'); // user status who sent request

      let buddyobj = {
        jid: fromjid,
        // "name": fromName || "default patil",
        type: fromType,
        // "imageUrl": fromImageUrl || "default_abc.com.image",
        // "status": fromStatus || "deafult_In Dhule Now"
      }

      //checking its available in buddy list
      if (stanza?.getAttribute('type') === 'subscribe') {
        let reqNeedTosent = loogedInUser?.buddyList?.filter((item) => {
          return item?.jid === fromjid
        })
        // if (from JID is available in buddy list)
        if (reqNeedTosent?.length > 0) {
          // // check its type
          if (reqNeedTosent[0]?.subscription === 'subscribed') {
            connectionObj1?.send(
              $pres({
                to: fromjid,
                type: 'subscribed',
              })
            )
            // window?.connectionObj?.send($pres({
            //     to: fromjid,
            //     type: "subscribed"
            // }));
            loogedInUser = loogedInUser?.notificationsList?.map((item) => {
              if (item?.jid === reqNeedTosent[0].jid) {
                //eslint-disable-next-line
                return
              }
              return item
            })
            console.log(
              'Notification for logged in user updated removed unwanted notification',
              loogedInUser
            )
            localStorage.setItem('user', JSON.stringify({ "fre8ProAccessToken": loogedInUser?.fre8ProAccessToken }))
          }
          //

          // if type is subscribed then remove pending request from buddy list
          //todo: Ahitth singh request received notifivation code
          console.log(
            'UPdated notification list after acceptedppo clearing the notification',
            loogedInUser
          )
        } else {
          //Notifications
          let notificationsList = loogedInUser['notificationsList'] || []
          notificationsList = notificationsList?.map((itemData) => {
            if (itemData?.jid !== buddyobj?.jid) {
              notificationsList.push(buddyobj)
              // return itemData
            }
            return itemData
          })
          if (notificationsList?.length === 0) {
            notificationsList.push(buddyobj)
          }

          // item["notificationsList"] = notificationsList;
          loogedInUser['notificationsList'] = notificationsList

          console.log('Notification for logged in user updated', loogedInUser)
          localStorage.setItem('user', JSON.stringify({ "fre8ProAccessToken": loogedInUser?.fre8ProAccessToken }))
          // setnotificationList(notificationsList)
          // dispatch(setNotificationList({ notificationList: notificationsList || [] }))
          //Request received
          let obj = {
            fre8ProAccessToken: frProUser.fre8ProAccessToken,
            requestFromUser: fromjid,
            requestTime: new Date().toISOString(),
          }
          UsersApis.userBuddyRequestReceivedBuddyLive(obj)
            .then((res) => {
              console.log(
                'success userBuddyRequestReceived',
                res?.data
              )
              // buddiesList?.push(res?.data)
              // setbuddiesList(buddiesList)
              let item = [
                {
                  name: 'Request received from ' + fromjid,
                  type: 'Request Received',
                },
              ]
              setToasts(item)
              setshowToasts(true)
              setTimeout(() => {
                // props?.setshowSearchResults(false)
                setshowToasts(false)
              }, 4000)
            })
            .catch((err) => {
              console.log('Err userBuddyRequestReceivedBuddyLive ', err)
              // setbuddiesList(buddiesList)
              // setbuddiesList(buddiesList)
              let item = [
                {
                  name:
                    'Request received from ' +
                    fromjid +
                    'But something went wrong while retrieving data',
                  type: 'Request Received Failed',
                  color: 'red',
                },
              ]
              setToasts(item)
              setshowToasts(true)
              setTimeout(() => {
                // props?.setshowSearchResults(false)
                setshowToasts(false)
              }, 4000)
            })
        }
      } else if (stanza?.getAttribute('type') === 'subscribed') {
        //sender: to whom  request send i.e. from id add this to buddy list
        // Add to buddy list

        let loogedInUser = JSON.parse(localStorage.getItem('user'))
        let logUser = loogedInUser?.buddyList?.map((item) => {
          if (item?.jid === fromjid) {
            item.subscription = 'subscribed'
          }
          return logUser
        })

        localStorage.setItem('user', JSON.stringify({ "fre8ProAccessToken": logUser?.fre8ProAccessToken }))
      }
    }

    return true
  }
  function onPresence(presence, connection) {
    console.log('onPresence:', presence)
    console.log(
      'Onpresesnce executed,  ',
      presence,
      'presence?.type:::: ',
      presence?.type
    )
    if (presence?.type) {
      var presence_type = $(presence).attr('type') // unavailable, subscribed, etc...
      if (presence_type === null || presence_type === undefined) {
        console.log('OnPresesnce presence type is undefined or null')

        // return true
      }
      var from = $(presence).attr('from') // the jabber_id of the contact
      if (!presence_type) presence_type = 'online'
      console.log(' >' + from + ' --> ' + presence_type)
      if (presence_type !== 'error') {
        if (presence_type === 'unavailable') {
          // Mark contact as offline
        } else {
          var show = $(presence).find('show').text() // this is what gives away, dnd, etc.
          if (show === 'chat' || show === '') {
            // Mark contact as online
          } else {
            // etc...
          }
        }
      }
      console.log('On prseence status', presence_type)
      // return true;
    } else {
      console.log('OnPresesnce presence type is undefined or null else part')
    }
    return true
  }
  // // Getting friend list for the logged in user
  function getBuddyListRoster(cb) {
    console.log('getBuddyListRoster')
    var iq = $iq({
      // eslint-disable-line
      type: 'get',
    }).c('query', {
      xmlns: 'jabber:iq:roster',
    })

    connectionObj1?.sendIQ(iq, getBuddyListRosterCallback)
    connectionObj1?.flush()
    // window?.connectionObj?.sendIQ(iq, getBuddyListRosterCallback);
    // window?.connectionObj?.flush();
  }
  function getBuddyListRosterCallback(iq) {
    console.log('getBuddyListRoster:', iq)
    let buddyListObj = []
    //TODO : Update buddy list from here to store to disaptch
    $(iq)
      ?.find('item')
      ?.each(function () {
        var buddyobj = {}
        var jid = $(this).attr('jid') // The jabber_id of your contact
        var ask = $(this).attr('ask')
        var subscription = $(this).attr('subscription')

        buddyobj['jid'] = jid
        // buddyobj.type = type
        buddyobj['ask'] = ask
        buddyobj['subscription'] = subscription

        // You can probably put them in a unordered list and and use their jids as ids.
        console.log(' >' + buddyobj)
        console.log(
          ' pushed this jid to loo=gged in users buddy list >',
          buddyObj
        )
        buddyListObj.push(buddyobj)
        buddyobj = {}

        //check test this scenarionm
        // if (typeof cb === "function") {
        //     cb()
        // }
      })

    // setbuddiesList([])
    // dispatch(setLoggedInUserBuddiesList({ buddiesList: buddyListObj || [] }))
    // let userList = JSON.parse(localStorage.getItem('usersList'));
    let loogedInUser = JSON.parse(localStorage.getItem('user'))
    loogedInUser['buddyList'] = buddyListObj
    localStorage.setItem('user', JSON.stringify({ "fre8ProAccessToken": loogedInUser?.fre8ProAccessToken }))
    // setbuddiesList(buddyListObj)
    // let users = userList.map((item) => {
    //     if (item?.fre8ProUserId === loogedInUser?.fre8ProUserId) {
    //         item["buddyList"] = buddyListObj;
    //     }
    //     return item
    // })
    // console.log('Users udpated with buddy list', users)
    // localStorage.setItem('usersList', JSON.stringify(users));

    // if (typeof cb === "function") {
    //     cb()
    // }
  }
  // // Getting friend list for the logged in user
  function getRoster(cb, connectionObj) {
    console.log('getRoster')
    var iq = $iq({
      // eslint-disable-line
      type: 'get',
    }).c('query', {
      xmlns: 'jabber:iq:roster',
    })
    function rosterCallbackint(iq) {
      rosterCallback(iq)
      cb()
    }
    connection?.sendIQ(iq, rosterCallbackint)
    connection?.flush()

    // window?.connectionObj?.sendIQ(iq, rosterCallbackint);
    // window?.connectionObj?.flush();
  }
  function rosterCallback(iq) {
    console.log('rosterCallback:', iq)
    let buddyListObj = []

    //TODO : Update buddy list from here to store to disaptch
    $(iq)
      ?.find('item')
      ?.each(function () {
        var buddyobj = {}
        var jid = $(this).attr('jid') // The jabber_id of your contact
        var ask = $(this).attr('ask')
        var subscription = $(this).attr('subscription')

        buddyobj['jid'] = jid
        // buddyobj.type = type
        buddyobj['ask'] = ask
        buddyobj['subscription'] = subscription

        // You can probably put them in a unordered list and and use their jids as ids.
        console.log(' >' + buddyobj)
        console.log(
          ' pushed this jid to loo=gged in users buddy list >',
          buddyObj
        )
        buddyListObj.push(buddyobj)
        buddyobj = {}

        //check test this scenarionm
        // if (typeof cb === "function") {
        //     cb()
        // }
      })
    // setbuddiesList(buddyListObj)
    // dispatch(setLoggedInUserBuddiesList({ buddiesList: buddyListObj || [] }))
    // let userList = JSON.parse(localStorage.getItem('usersList'));
    let loogedInUser = JSON.parse(localStorage.getItem('user'))
    loogedInUser['buddyList'] = buddyListObj
    console.log('Buddy list obj  from rosterCallback', buddyListObj)
    // setbuddiesList(buddyListObj)
    // let users = userList.map((item) => {
    //     if (item?.fre8ProUserId === loogedInUser?.fre8ProUserId) {
    //         item["buddyList"] = buddyListObj;
    //     }
    //     return item
    // })
    // console.log('Users udpated with buddy list', users)
    // localStorage.setItem('usersList', JSON.stringify(users));
    localStorage.setItem('user', JSON.stringify({ "fre8ProAccessToken": loogedInUser?.fre8ProAccessToken }))
    // if (typeof cb === "function") {
    //     cb()
    // }
  }
  //Send request
  function subscribePresence(jid, connection) {
    console.log('subscribePresence: ' + jid)
    if (!jid) {
      return
    }

    let buddyObj = {
      jid: jid || 'default@of.varahisoft.in',
      // "name": fromName || "default_ patil",
      type: 'subscribe',
      // "imageUrl": fromImageUrl || "default_abc.com.image",
      // "status": fromStatus || "deafult_In Dhule Now"
    }

    let userList = JSON.parse(localStorage.getItem('usersList'))
    let loogedInUser = JSON.parse(localStorage.getItem('user'))
    if (loogedInUser['buddyList']?.length > 0) {
      loogedInUser['buddyList'] = loogedInUser['buddyList'].push(buddyObj)
    } else {
      loogedInUser['buddyList'] = []
      loogedInUser['buddyList'].push(buddyObj)
    }
    let users = userList.map((item) => {
      if (item?.fre8ProUserId === loogedInUser?.fre8ProUserId) {
        let buddyList = item['buddyList'] || []

        buddyList = buddyList?.map((itemData) => {
          if (itemData?.jid !== buddyObj?.jid) {
            buddyList.push(buddyObj)
          }
          return itemData
        })
        if (buddyList?.length === 0) {
          buddyList.push(buddyObj)
        }

        item['buddyList'] = buddyList
        loogedInUser['buddyList'] = buddyList
      }
      return item
    })
    localStorage.setItem('usersList', JSON.stringify(users))
    localStorage.setItem('user', JSON.stringify({ "fre8ProAccessToken": loogedInUser?.fre8ProAccessToken }))
    console.log(buddyObj)
    // buddiesList?.push(buddyObj)

    // console.log("Buddy list obj  from subscribePresence", buddiesList)
    //  Add callback in callback we have to call /v1/user/buddy/request/send
    // SENT("sent"), RECEIVED("received"), BLOCKED("blocked"),UNBLOCKED("unblocked"), BUDDY("buddy"), UNBUDDY("unbuddy"), DENY("deny");
    // let obj = {
    //     fre8ProAccessToken: frProUser.fre8ProAccessToken,
    //     "requestToUser": jid?.split("@")[0] || this?.jid?.split("@")[0],
    //     // "requestToUser": jid,
    //     "requestTime": new Date().toISOString()
    // }
    // UsersApis.userBuddyRequestSend(obj).then((res) => {
    //     console.log("success userBuddyRequestSend ", res?.data)
    //     // buddiesList?.push(res?.data)
    //     // setbuddiesList(buddiesList)

    //     setbuddiesList((buddiesList) => { buddiesList.push(buddyObj) })
    //     setTimeout(() => {
    //         dispatch(setLoggedInUserBuddiesList({ buddiesList: buddiesList || [] }))
    //     }, 1000)
    // }).catch((err) => {
    //     console.log("Err userBuddyRequestSend ", err)
    //     // setbuddiesList(buddiesList)
    //     // setbuddiesList((buddiesList) => { buddiesList.push(buddyObj) })

    // })

    connectionObj1?.sendPresence(
      $pres({
        to: jid,
        type: 'subscribe',
      }),
      callbacFun1.bind({ jid }),
      errback.bind({ jid })
    )

    // window?.connectionObj?.sendPresence($pres({
    //     to: jid,
    //     type: "subscribe",

    // }), callbacFun1.bind({ jid }), errback.bind({ jid }));
  }
  function errback(params) {
    console.error('params : errback', params, this)
    let user = searchFriendsResults.filter((item) => {
      return item?.jabberId === this?.jid
    })
    let obj = {
      fre8ProAccessToken: frProUser.fre8ProAccessToken,
      requestToUser: user && user[0] && user[0]?.userId,
      requestTime: new Date().toISOString(),
    }
    UsersApis.userBuddyRequestSend(obj)
      .then((res) => {
        console.log('success userBuddyRequestSend ', res?.data)
        // buddiesList?.push(res?.data)
        // setbuddiesList(buddiesList)
      })
      .catch((err) => {
        console.log('Err userBuddyRequestSend ', err)
        // setbuddiesList(buddiesList)
      })
    // alert("error callback")
    return true
  }

  function callbacFun1(params, jid) {
    console.log('params callbacFun1', params, this, jid)
    let obj = {
      fre8ProAccessToken: frProUser.fre8ProAccessToken,
      requestToUser: this?.jid,
      requestTime: new Date().toISOString(),
    }
    UsersApis.userBuddyRequestSend(obj)
      .then((res) => {
        console.log('success userBuddyRequestSend ', res?.data)
        // buddiesList?.push(res?.data)
        // setbuddiesList(buddiesList)
      })
      .catch((err) => {
        console.log('Err userBuddyRequestSend ', err)
        // setbuddiesList(buddiesList)
      })
    // alert("Callback")
    return true
  }
  //Approve button onclick
  const approveBuddyReq = (from, connection) => {
    console.log('request approved')

    connectionObj1?.send(
      $pres({
        to: from,
        type: 'subscribed',
      })
    )

    // window?.connectionObj?.send($pres({
    //     to: from,
    //     type: "subscribed"
    // }));

    console.log('Connected to sender')
    // window?.connectionObj?.flush();
    connectionObj1?.flush()
    console.log('Asking for connection')

    // setTimeout(() => {
    //Send request to himm as he accepted your request
    connectionObj1?.send(
      $pres({
        to: from,
        type: 'subscribe',
      })
    )
    // }, 5000);
    connectionObj1?.flush()

    // window?.connectionObj?.send($pres({
    //     to: from,
    //     type: "subscribe"
    // }));
    // // }, 5000);
    // window?.connectionObj?.flush();

    let buddyobj = {
      jid: from,
      type: 'subscribed',
    }

    let userList = JSON.parse(localStorage.getItem('usersList'))
    let loogedInUser = JSON.parse(localStorage.getItem('user'))

    let users = userList.map((item) => {
      if (item?.fre8ProUserId === loogedInUser?.fre8ProUserId) {
        //buddies
        let buddyList = item['buddyList'] || []
        buddyList.push(buddyobj)
        item['buddyList'] = buddyList
        loogedInUser['buddyList'] = buddyList

        //Notifications
        let notificationsList = item['notificationsList'] || []
        // notificationsList.remove(buddyobj)

        notificationsList = notificationsList?.map((item) => {
          if (item?.jid === from) {
            // eslint-disable-next-line
            return
          }
          return item
        })

        item['notificationsList'] = notificationsList
        loogedInUser['notificationsList'] = notificationsList
        // dispatch(setNotificationList({ notificationList: notificationsList || [] }))
      }
      return item
    })
    console.log('Users udpated with buddy list', users, loogedInUser)
    localStorage.setItem('usersList', JSON.stringify(users))
    localStorage.setItem('user', JSON.stringify({ "fre8ProAccessToken": loogedInUser?.fre8ProAccessToken }))

    // setbuddiesList((buddiesList) => {
    //     let buddyListNew = Object.create(buddiesList)
    //     buddyListNew.push(buddyobj)
    // })
    setTimeout(() => {
      // dispatch(setLoggedInUserBuddiesList({ buddiesList: buddiesList || [] }))
    }, 1000)
    //update UI

    //TODO::: add this from user to buddy list
    //no image can be used
    return true
  }
  function setStatus(s, connection) {
    console.log('setStatus: ' + s)
    var status = $pres().c('show').t(s)
    connectionObj1?.send(status)
    // window?.connectionObj?.send(status);
  }
  // ---------- checking for the presence of the buddies
  const getPresence = (jid, connection) => {
    console.log('getPresence: ' + jid)
    var check = $pres({
      type: 'probe',
      to: jid,
    })
    // window?.connectionObj?.send(check);
    connectionObj1?.send(check)
  }

  useEffect(() => {
    loadChatclient()
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!connectionObj1?.connected) {
      // alert("load again")
      loadChatclient()
    }
  }, [connectionObj1?.connected]) //eslint-disable-line

  console.log('Connection obj11', connectionObj1)
  // console.log(subscribePresence, approveBuddyReq, setStatus, getPresence)
  // const handlers=[];
  // const handlersType = {
  //     handlerFunc: () => { },
  //     matcher: {
  //         namespace,
  //         name: 'message' | 'iq' | 'presence',
  //         type,
  //         id,
  //         from
  //     }
  // };

  // const connectHandlers = () => {
  //     onMessageHandler.current = connection.addHandler(
  //         typeof onMessage === 'function'
  //             ? onMessage
  //             : message => {
  //                 // eslint-disable-next-line
  //                 if (showLogs) console.log('onMessage', message);
  //                 return true;
  //             },
  //         undefined,
  //         'message'
  //     );

  //     onPresenceHandler.current = connection.addHandler(
  //         typeof onPresence === 'function'
  //             ? onPresence
  //             : presence => {
  //                 // eslint-disable-next-line
  //                 if (showLogs) console.log('onPresence', presence);
  //                 return true;
  //             },
  //         undefined,
  //         'presence'
  //     );

  //     onIqHandler.current = connection.addHandler(
  //         typeof onSubscriptionRequest === 'function'
  //             ? onSubscriptionRequest
  //             : iq => {
  //                 // eslint-disable-next-line
  //                 if (showLogs) console.log('onIq', iq);
  //                 return true;
  //             },
  //         undefined,
  //         'iq'
  //     );

  //     stropheHandlers.current = handlers.map(
  //         ( {handlersType?.handlerFunc, handlersType?.matcher}) =>
  //             connection.addHandler(handlerFunc, namespace, name, type, id, from)
  //     );
  // };

  // const disconnectHandlers = () => {
  //     connection.deleteHandler(onMessageHandler);
  //     connection.deleteHandler(onPresenceHandler);
  //     connection.deleteHandler(onIqHandler);
  //     if (stropheHandlers.current && Array.isArray(stropheHandlers.current)) {
  //         stropheHandlers.current.forEach(handler => {
  //             connection.deleteHandler(handler);
  //         });
  //     }
  //     onMessageHandler.current = null;
  //     onPresenceHandler.current = null;
  //     onIqHandler.current = null;
  //     stropheHandlers.current = null;
  // };

  // useEffect(() => {
  //     return disconnectHandlers;
  // }, []);
  // const disconnect = (disconnectingReason) => {
  //     connection.disconnect(disconnectingReason);
  // };
  return (
    <StropheContext.Provider
      value={{
        connectionObj: connectionObj1,
        // buddiesList: buddiesList,
        // notificationList: notificationList,
        buddyChats: buddyChats,
        buddyMessages: buddyMessages,
        composing: composing,
        chatData: chatData,
        setchatData: setchatData,
        setbuddyMessages: setbuddyMessages,
        setconnectionObj: setconnectionObj1,
        subscribePresence: subscribePresence,
        approveBuddyReq: approveBuddyReq,
        getPresence: getPresence,
        setStatus: setStatus,
        loadChatclient: loadChatclient,
        custom_jid_to_id: custom_jid_to_id,
      }}
    >
      <div className="main-wrapper">
        <HeaderContent />
        <Outlet />
        <FooterLayout />
        {showToasts && <Toasts toasts={toasts} />}
      </div>
    </StropheContext.Provider>
  )
}

export default ChatClientMainComponent
