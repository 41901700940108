import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line react/prop-types
const SearchPagination = ({ page, setPage, totalPages, searchRes }) => {
    let searchResponse = searchRes;
    return (
        <div className="meetup-pagination my-3">
            <ul className="pagination justify-content-center custom-pagnition ">
                {searchResponse?.previousAvailable && <button
                    disabled={page <= 1}
                    className={`page-item cursor-pointer `}
                    onClick={() => setPage((prev) => prev - 1)}
                >
                    <a className="page-link">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M15.8332 10.0001H4.1665M4.1665 10.0001L9.99984 15.8334M4.1665 10.0001L9.99984 4.16675"
                                stroke="#667085"
                                strokeWidth="1.67"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                        </svg>
                        Previous
                    </a>
                </button>}
                {Array?.from({ length: totalPages })?.map((_, inx) => (
                    <li
                        key={inx}
                        className={`page-item cursor-pointer ${page === inx + 1 ? 'active' : null
                            }`}
                        onClick={() => setPage(inx + 1)}
                    >
                        <a className="page-link">{inx + 1}</a>
                    </li>
                ))}
                {searchResponse?.nextAvailable && <button
                    disabled={page >= totalPages}
                    className={`page-item cursor-pointer `}
                    onClick={() => setPage((prev) => prev + 1)}
                >
                    <a className="page-link">
                        Next
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M4.1665 10.0001H15.8332M15.8332 10.0001L9.99984 4.16675M15.8332 10.0001L9.99984 15.8334"
                                stroke="#667085"
                                strokeWidth="1.67"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                        </svg>
                    </a>
                </button>}
            </ul>
        </div>
    )
}

SearchPagination.propTypes = {
    previousAvailable: PropTypes.string,
    nextAvailable: PropTypes.string,
}
export default SearchPagination