import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
// import { ProfileApis } from "../../API/Login/ProfileApi";
// import { dispatch } from "../../store/reducers/persist";
// import {
//   setFrProUser,
//   setauthorizationToken,
// } from "../../store/reducers/Users/users";
// import { dispatch } from "../../store/reducers/persist";
// import {
//   setFrProUser,
//   setFrProUserProfile,
// } from "../../store/reducers/Users/users";
import { StropheContext } from '../ChatClient'
import { ProfileApis } from '../../API/Login/ProfileApi'
import { Typography } from '@mui/material'

const SelfDocumentUploadScreen = (props) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [photoBlob, setPhotoBlob] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const navigate = useNavigate()
  const [personalDetails, setPersonalDetails] = useState()
  const stropheContext = useContext(StropheContext)
  console.log('dashboard props', props, stropheContext)

  const { frProUser } = useSelector(
    (state) => state.Users
  )


  const userPersonalDetailsByUserId = () => {
    let mobileNumberObj = {
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
      userId: frProUser?.userId,
      page: 0,
      size: 0,
      businessId: null,
      profileId: null,
    }
    ProfileApis.getUserPersonalDetailsByUserId(mobileNumberObj)
      .then((res) => {
        // Correct way to merge the objects
        setPersonalDetails(res?.data)
      })
      .catch((err) => {
        console.debug('Mobile number not Available', err)
      })
  }

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (err) {
      console.error('Error accessing the camera:', err);
    }
  };

  const capturePhoto = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        setPhotoBlob(blob);
        const imageUrl = URL.createObjectURL(blob);
        setImageSrc(imageUrl);
      }, 'image/jpeg');
    }
  };

  const sendPhotoToApi = async () => {
    if (photoBlob) {
      const formData = new FormData();
      formData.append('profileImage', photoBlob)

      let dataDesc = {
        description: 'file description if available',
        fre8ProAccessToken: "31A8D77B27249C2460492D636BAB78B7",
      }
      formData.append(
        'profileImageForm',
        new Blob([JSON.stringify(dataDesc)], {
          type: 'application/json',
        })
      )

      // formData.append('photo', photoBlob);


      // formData.append('fre8ProAccessToken', "FAC282E1D27D40F2DB7CA5E0DDD2804F");
      // formData.append('fre8ProUserId', "testUser02");
      // formData.append('profileImageId', "testUser02");
      // formData.append('imgOriginalName', "testUser02");
      // formData.append('imgGeneratedName', "testUser02");
      // formData.append('description', "testUser02");
      // formData.append('uploadedAt', "string");
      // formData.append('profileImageUrl', photoBlob);
      // formData.append('profileImageUrls', {
      //   "additionalProp1": "string",
      //   "additionalProp2": "string",
      //   "additionalProp3": "string"
      // });

      try {
        //USe our upload profile or cover photo api to send this photo blob
        const response = await fetch('https://dev-fre8pro-backend.varahisoft.in/fre8pro-backend/v1/profile/uploadImage', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          // Stop the camera after successful submission
          if (videoRef.current && videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject;
            const tracks = stream.getTracks();

            // Stop all tracks to turn off the camera
            tracks.forEach((track) => track.stop());
            videoRef.current.srcObject = null; // Clear the video source
          }
          console.log('Photo uploaded successfully!');
        } else {

          console.error('Error uploading photo:', response.statusText);
        }
      } catch (err) {
        console.error('Error sending photo to API:', err);
      }
    }
  };


  useEffect(() => {
    userPersonalDetailsByUserId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log('-----frProUser---->', frProUser);
  console.log('-----personalDetails---->', personalDetails);
  return (
    <>
      {/* <GetUserProfile /> */}
      <main className="main users chart-page h-100 ">
        <div className="text-start cursorPointer">
          <a onClick={() => navigate(-1)} className="f-w-700 color-400 ">
            <img src="../images/back.svg" alt="" className="me-3" /> Go back
          </a>
        </div>
        <div className="container-fluid p-lg-4 h-100">
          <div className='h-100 '>
            <div className="row">
              <div className="col-12 col-lg-12">
                <div>
                  <div className='mb-3'>
                    <video ref={videoRef} style={{ backgroundColor: '#000', borderRadius: '8px', display: imageSrc ? 'none' : 'block' }} width="320" height="240"></video>
                    <canvas ref={canvasRef} style={{ display: 'none' }} width="320" height="240"></canvas>
                  </div>
                  <div className='d-flex align-items-center justify-content-center gap-3'>
                    <button className='btn cancelBtn py-1' onClick={startCamera}>Open Camera</button>
                    <button className='btn custom-button py-1 ' onClick={capturePhoto}>Capture Photo</button>
                    <button className='btn submitBtn py-1' onClick={sendPhotoToApi}>Submit Photo</button>
                  </div>
                </div>

                {imageSrc && (
                  <div>
                    <h2>Preview</h2>
                    <img src={imageSrc} alt="Captured" width="320" height="240" />
                  </div>
                )}

              </div>
            </div>
            <div className="Current-details mt-4">
              <div className="border-top mb-3 pt-3 d-flex align-item-center justify-content-center gap-3">
                <button
                  type="button"
                  className="btn cancelBtn py-2 px-5"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
                <Typography
                  component={Link}
                  className={'btn ms-2 submitBtn py-2 px-5'}
                  to="/alliance/uploadDocumentList"
                  color="textSecondary"
                  variant="h6"
                  type='submit'
                  sx={{ textDecoration: 'none' }}
                >
                  Next
                </Typography>
                {/* <button
                                        type="submit"
                                        className="btn custom-button py-2 px-5"
                                      >
                                        Next
                                      </button> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default SelfDocumentUploadScreen
