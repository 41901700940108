import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { StropheContext } from '../ChatClient'
import { Typography } from '@mui/material'

const MembershipDetailsScreen = (props) => {
  const navigate = useNavigate()
  const accountType = localStorage.getItem('accountType')
  const [randomNumber, setRandomNumber] = useState(null);
  const stropheContext = useContext(StropheContext)
  console.log('dashboard props', props, stropheContext)



  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(date).toLocaleDateString('en-GB', options);
  };

  const currentDate = new Date();
  const formattedDate = formatDate(currentDate);

  const generateRandomNumber = () => {
    const number = Math.floor(Math.random() * 1000000000); // Generate a number between 0 and 999999999
    setRandomNumber(number);
  };


  useEffect(() => {
    generateRandomNumber()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* <GetUserProfile /> */}
      <main className="main users chart-page h-100 ">
        <div className="text-start cursorPointer">
          <a onClick={() => navigate(-1)} className="f-w-700 color-400 ">
            <img src="../images/back.svg" alt="" className="me-3" /> Go back
          </a>
        </div>
        <div className="container-fluid p-lg-4 h-100">
          <div className="text-start mb-4 title d-flex align-items-center justify-content-between">
            <h3 className='mb-4 memberShipDeTil'>
              Membership details
            </h3>
            <div className='d-flex align-items-center justify-content-end gap-2'>
              <Typography
                component={Link}
                className={'btn ms-2 lightBtn py-1 px-3'}
                to="/alliance/rewards"
                color="textSecondary"
                variant="h6"
                type='submit'
                sx={{ textDecoration: 'none' }}
              >
                Rewards
              </Typography>
              {accountType === 'fleet' && <Typography
                component={Link}
                className={'btn ms-2 lightBtn py-1 px-3'}
                to="/alliance/billing"
                color="textSecondary"
                variant="h6"
                type='submit'
                sx={{ textDecoration: 'none' }}
              >
                Billing
              </Typography>}
            </div>
          </div>
          <div className='memberShip-card position-relative'>
            <p className='member-header'>Platinum monthly plan</p>
            <p className='MembershipID'>Membership ID: FM{randomNumber}</p>
            <p className='Upgraded-member m-0'><b>Upgraded </b> Since {formattedDate}. &nbsp;
              <button
                type="button"
                className="btn cancelBtn py-2"
                onClick={() => navigate(-1)}
              >
                Cancel membership
              </button>
            </p>
            <div className='Reward-points position-absolute'>
              <div className='position-relative'>
                <svg xmlns="http://www.w3.org/2000/svg" width="89" height="96" viewBox="0 0 89 96" fill="none">
                  <path d="M1 94.2143V1H88V94.2143L44.5 63.9196L1 94.2143Z" fill="#FFBE00" stroke="#EDAE10" strokeWidth="1.55357" />
                </svg>
                <div className='Reward-points-div position-absolute'>
                  <div className='text-center'>
                    <p className='reward-title'>Reward points</p>
                    <p className='reward-point'>100</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default MembershipDetailsScreen
