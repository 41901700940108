import React from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler, // Enable area fill
} from "chart.js";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const LineChartWithConfig = () => {
    // Data for the chart
    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'jun', 'ju'], // Replace with your labels
        datasets: [
            {
                label: 'Dataset',
                data: [20, 15, 25, 20, 25, 10, 12, 15, 5], // Replace with your data
                borderColor: '#007bff',
                backgroundColor: 'rgba(0, 123, 255, 0.1)',
                fill: true,
                tension: 0, // Makes the line smooth
                pointBackgroundColor: '#007bff',
                pointBorderColor: '#fff',
                pointBorderWidth: 1,
                pointRadius: 3,
            },
        ],
    };
    // const data = {
    //     labels: ["January"], // X-axis labels
    //     datasets: [
    //         {
    //             label: "My Dataset",
    //             data: [0, 2, 2, 2, 1, 2, 0],
    //             fill: true, // Enable area fill
    //             borderColor: "rgba(0, 123, 255, 1)", // Line color
    //             backgroundColor: "rgba(0, 123, 255, 0.2)", // Area fill color
    //             tension: 0, // Line curve tension
    //             pointBackgroundColor: "rgba(0, 123, 255, 1)", // Point color
    //         },
    //     ],
    // };

    // Chart configuration options
    // const options = {
    //     responsive: true,
    //     plugins: {
    //         Legend: false,
    //         title: {
    //             display: false,
    //             text: "Area Chart Example",
    //         },
    //     },
    //     // interaction: {
    //     //     mode: "index",
    //     //     intersect: false,
    //     // },
    //     // scales: {
    //     //     x: {
    //     //         display: false,
    //     //         title: {
    //     //             display: true,
    //     //             text: "Month",
    //     //         },
    //     //     },
    //     //     y: {
    //     //         display: false,
    //     //         title: {
    //     //             display: true,
    //     //             text: "Value",
    //     //         },
    //     //     },
    //     // },
    // };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false, // Removes the title
            },
        },
        scales: {
            x: {
                display: false, // Hides the x-axis
            },
            y: {
                display: false, // Hides the y-axis
            },
        },
    };

    return (
        <Line data={data} options={options} />
    );
};

export default LineChartWithConfig;
