// material-ui
import * as React from 'react'
// project import
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { $pres } from 'strophe.js'
import { LoginApis } from '../../../../API/Login/LoginApi'
// import { UseloadChatClient } from "../../../../Pages/ChatClient/Chatclient";
import { ProfileApis } from '../../../../API/Login/ProfileApi'
import ErrorHandle from '../../../../Components/ErrorHandle'
import Toasts from '../../../../Components/Toasts/Toasts'
import { StropheContext } from '../../../../Pages/ChatClient'
import NotificationsPopUp from '../../../../Pages/Dashboard/notificationsPopUp/NotificationsPopUp'
import SearchFriendPopUp from '../../../../Pages/SearchFriend/SearchFriendPopUp'
import '../../../../css/New/dashboard.css'
import '../../../../css/New/style.css'
import { setFollowersList } from '../../../../store/reducers/Followers/Followers'
import { setFollowingList } from '../../../../store/reducers/Following/Following'
import { setGlobalSearchSelectValue } from '../../../../store/reducers/GenericData/generalData'
import {
  setEmailVerificationStatus,
  setFrProUser,
  setMobileVerificationStatus,
  setOtherUserInfo,
  setSearchFriends,
} from '../../../../store/reducers/Users/users'
import { dispatch } from '../../../../store/reducers/persist'

// let counter = 0;
// ==============================|| HEADER - CONTENT ||============================== //
const HeaderContent = ({
  showMenu = true,
  showSearch = true,
  showProfile = true,
  loading = true,
  connectionObj,
}) => {
  const inputRef = React.useRef(null)
  const modelRef = React.useRef(null)
  const { frProUser } = useSelector((state) => state.Users)
  // let updatedFrProUserObj = '';
  const { globalSearchSelectValue } = useSelector((state) => state.GeneralData)
  const globalSearchSelectorRef = React.useRef(null)

  const [searchFriendText, setsearchFriendText] = React.useState('')
  const [updatedFrProUserObj, setUpdatedFrProUserObj] = React.useState(frProUser)
  console.log('searchFriendTextsearchFriendText', searchFriendText, inputRef, modelRef)
  const [errorInfo, seterrorInfo] = React.useState(null)
  const [toasts, setToasts] = React.useState(null)

  // const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const { user, frProUser } = useSelector((state) => state.Users);
  // console.log("::: user", user);

  // const { isLoading, serverError, apiData } = useFetch(
  //   "GET",
  //   "https://jsonplaceholder.typicode.com/posts/1",
  //   {}
  // );
  const stropheContext = React.useContext(StropheContext)
  console.log('HeaderCongtent strophe connection context', stropheContext)
  // console.log("connection", connection);
  const logoutClicked = () => {
    let logoutdata = {
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
      operatedByModule: "FRE8PRO",
      operatedByUserId: "d974259c-88bb-49ef-be9e-cd790b913365",
    }
    LoginApis.logout(logoutdata)
      .then((res) => {
        // dispatch(setUser({ user: res }));
        window.location.reload()
        navigate('/')
        // console.log('logged out', res)
        // alert("Logged out")
        // dispatch(setUser({ user: {} }));
        dispatch(setFrProUser({ frProUser: {} }))
        dispatch(setOtherUserInfo({ otherUserInfo: {} }))
        dispatch(setFollowersList({ followersList: [] }))
        dispatch(setFollowingList({ followingList: [] }))
        dispatch(setEmailVerificationStatus(null))
        dispatch(setMobileVerificationStatus(null))
        // window?.connectionObj?.disconnect();
        var check = $pres({
          type: 'unavailable',
        })
        // window?.connectionObj?.send(check);
        // window?.connectionObj?.disconnect();
        // window.connectionObj = null;
        stropheContext?.connectionObj?.send(check)
        stropheContext?.connectionObj?.disconnect()
        stropheContext?.setconnectionObj(null) // TODO: Set conenction obj null
        localStorage.setItem('user', JSON.stringify({}))
        localStorage.removeItem('mainPageUrl')
      })
      .catch((err) => {
        // alert("logout  failed", err)
        // navigate("/error", { err: err })
        var check = $pres({
          type: 'unavailable',
        })
        // window?.connectionObj?.send(check);
        // window?.connectionObj?.disconnect();
        stropheContext?.connectionObj?.send(check)
        stropheContext?.connectionObj?.disconnect()
        stropheContext?.setconnectionObj(null) // TODO: Set conenction obj null
        console.log('logout error', err)
        let customMessage = 'Logout failed'
        let type = 'User Information'
        let color = 'red'
        ErrorHandle(err, setToasts, customMessage, type, color)
      })
      .finally(() => {
        // TODO: remove test code
        navigate('/')
      })
  }
  const [showSearchResults, setshowSearchResults] = React.useState(false)
  const [showNotification, setshowNotification] = React.useState(false)
  const [friendsList, setfriendsList] = React.useState([])
  const navigate = useNavigate()
  // const location = useLocation();
  // const { connectionObj1 } = UseloadChatClient()
  const [searchFriends, setsearchFriends] = React.useState('')
  const [personalDetails, setPersonalDetails] = React.useState('')
  // const { frProUser } = useSelector((state) => state.Users);
  const searchFocused = (e) => {
    // if (e?.currentTarget?.length > 3) {
    setshowSearchResults(true)
    setsearchFriends(true)
    setshowNotification(false)
    // }
  }
  const searchBlured = (e) => {
    setsearchFriendText(e?.target?.value)
    // if (e?.currentTarget?.length > 3) {
    setshowSearchResults(false)
    setsearchFriends(false)
    // navigate("/searchresultspage");
    // }
  }

  React.useEffect(() => {
    // let searchFriendObj = {
    //   fre8ProAccessToken: frProUser?.fre8ProAccessToken,
    //   searchText: searchFriendText,
    //   searchType: 'buddy',
    // }
    let searchFriendObj = {
      "searchText": searchFriendText,
      "searchType": "USER",
      "userId": frProUser?.userId,
      "fre8ProAccessToken": frProUser?.fre8ProAccessToken,
      "page": 1,
      "size": 10,
    }
    let headers = {
      "Authorization": `Bearer:${localStorage.getItem("Authorization")}`
    }
    console.log(searchFriends)
    if (searchFriendText?.length > 2) {
      setshowSearchResults(true)
      setTimeout(() => {
        LoginApis.searchFriend(searchFriendObj, headers)
          .then((res) => {
            console.log('Res seach friend', res)
            // setTimeout(() => {
            setfriendsList(res?.data?.userList)
            dispatch(
              setSearchFriends({
                searchFriendsResults: res?.data?.userList,
              })
            )
            // }, 1000);
          })
          .catch((err) => {
            console.log('Error seach friend', err)
            seterrorInfo(err?.data)
            let customMessage = 'User search friend load failed'
            let type = 'User Information'
            let color = 'red'
            ErrorHandle(err, setToasts, customMessage, type, color)
          })
      }, 300)
    }
  }, [searchFriendText]) //eslint-disable-line react-hooks/exhaustive-deps

  // React.useEffect(() => {
  //   // alert('hi')

  // }, [showNotification]);

  const showNotificationFunc = () => {
    setshowSearchResults(false)
    setshowNotification(!showNotification)
  }

  // React.useEffect(() => {
  //   console.log('----------------Location changed---------------------', connectionObj1);

  // }, [location]);

  React.useEffect(() => {
    if (globalSearchSelectValue === 'all') {
      globalSearchSelectorRef.current.value = 'all'
    }
  }, [globalSearchSelectValue])

  React.useEffect(() => {
    const handleClickOutside = (e) => {
      const isModelOpen = showSearchResults
      const isInputFocused = document.activeElement === inputRef.current
      const isInsideModel = modelRef.current && modelRef.current.contains(e.target)

      if (!isInputFocused && !isModelOpen && !isInsideModel) {
        setsearchFriendText('')
        inputRef.current.value = '' // Clear the input value
        setshowSearchResults(false)
      } else if (
        inputRef.current &&
        !inputRef.current.contains(e.target) &&
        modelRef.current &&
        !modelRef.current.contains(e.target)
      ) {
        setshowSearchResults(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [showSearchResults])

  const userDefaultProfileImages = () => {
    let mobileNumberObj = {
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
      userId: frProUser?.userId,
      businessId: null,
      profileId: null,
    }
    ProfileApis.getUserDefaultProfileImages(mobileNumberObj)
      .then((res) => {
        // Correct way to merge the objects
        return setUpdatedFrProUserObj({ ...frProUser, profileImages: res?.data?.defaultProfileImageList[0] });
        // setMobilePrimaryNumber(res?.data)
      })
      .catch((err) => {
        console.debug('Mobile number not Available', err)
      })
  }
  const getUserPersonalDetailsView = () => {
    let mobileNumberObj = {
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
      viewerUserId: frProUser?.userId,
      targetUserId: frProUser?.userId,
    }
    ProfileApis.getUserPersonalDetails(mobileNumberObj)
      .then((res) => {

        console.log('-----------res personal details 23456------>', res?.data)
        console.log('-----------frProUser 23456------>', frProUser)
        // Dispatch FrProUser Add extra Keys
        dispatch(
          setFrProUser({
            frProUser: {
              ...frProUser,
              emailId: res?.data?.personalInfoForm?.email,
              mobileNumber: res?.data?.personalInfoForm?.mobileNumber,
              locationForm: res?.data?.personalInfoForm?.locationForm,
              fullName: res?.data?.personalInfoForm?.firstName + ' ' + res?.data?.personalInfoForm?.lastName
            },
          })
        )

        // Correct way to merge the objects
        setPersonalDetails(res?.data)
        // return setUpdatedFrProUserObj({
        //   ...frProUser,
        //   profileImages: res?.data?.defaultProfileImageList[0],
        // })
        // setMobilePrimaryNumber(res?.data)
      })
      .catch((err) => {
        console.debug('Mobile number not Available', err)
      })
  }

  React.useEffect(() => {
    userDefaultProfileImages()
    getUserPersonalDetailsView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <nav className="main-nav--bg">
        <div className="container-fluid main-nav">
          <div className="main-nav-start">
            <div className="search-wrapper search">
              <button className="serchbutton">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none">
                  <path
                    d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                    stroke="#000000"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>

              <div className="d-flex">
                <select
                  ref={globalSearchSelectorRef}
                  onChange={(e) => {
                    dispatch(setGlobalSearchSelectValue(''))
                    const value = e.target.value ?? ''
                    if (value === 'Couch' && frProUser?.userCategory === 'Driver') {
                      navigate('/couchServicesHost/couch_Find_form')
                    }
                    if (value === 'Job' && frProUser?.userCategory === 'Driver') {
                      navigate('/jobs/job_Find_form')
                    }
                  }}
                  className="bg-transparent select_drop">
                  <option value="all">All</option>
                  <option value="Buddy">Buddy</option>
                  <option value="Job">Job</option>
                  <option value="Couch">Couch</option>
                  <option value="Meetup">Meetup</option>
                </select>
                <input
                  type="text"
                  ref={inputRef}
                  placeholder="Search Fre8"
                  // required onChange={(e) => {
                  //   // setInterval(() => {
                  //   setsearchFriends(e.target.value);
                  //   // }, 2000);
                  // }}
                  onFocus={searchFocused}
                  onKeyUp={searchBlured}
                />
              </div>

              {showSearchResults && (
                <div ref={modelRef}>
                  <SearchFriendPopUp
                    searchFriendText={searchFriendText}
                    friendsList={friendsList}
                    setshowSearchResults={setshowSearchResults}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="main-nav-end align-items-center">
            <button
              className="sidebar-toggle transparent-btn align-items-center"
              title="Menu"
              type="button">
              <span className="sr-only d-none d-lg-block">
                <img src="images/left-slide-arrow.svg" alt="" />
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="d-block d-lg-none mt-4 bi bi-list"
                width="25"
                height="25"
                fill="currentColor"
                viewBox="0 0 16 16">
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                />
              </svg>
              <span className="icon menu-toggle--gray" aria-hidden="true"></span>
            </button>

            <a href="" className="d-block d-lg-none" title="Home">
              <img src="../images/Fre8Pro_logo.png" alt="" />
            </a>

            <div className="dropdown bg-transparent">
              {/* <button
                className="dropdown-toggle afternone position-relative"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M29.3332 7.99998C29.3332 6.53331 28.1332 5.33331 26.6665 5.33331H5.33317C3.8665 5.33331 2.6665 6.53331 2.6665 7.99998M29.3332 7.99998V24C29.3332 25.4666 28.1332 26.6666 26.6665 26.6666H5.33317C3.8665 26.6666 2.6665 25.4666 2.6665 24V7.99998M29.3332 7.99998L15.9998 17.3333L2.6665 7.99998"
                    stroke="#005A70"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="notify"></span>
              </button> */}
              <ul className="dropdown-menu messagenotifaction">
                <div className="headerMessage chat-list p-3">
                  <div className="d-flex justify-content-between py-3 ">
                    <h3>
                      <strong>Message</strong>
                    </h3>

                    <Typography
                      component={Link}
                      className={'viewall'}
                      to="/messagespage"
                      color="textSecondary"
                      variant="h6"
                      sx={{
                        textDecoration: 'none',
                        fontSize: '1rem',
                        margin: '12px 0',
                      }}>
                      View All
                    </Typography>
                  </div>
                  <a href="#" className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="img-fluid"
                        src="https://mehedihtml.com/chatbox/assets/img/user.png"
                        alt="user img"
                      />
                      <span className="st-statis online"></span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h3>Mehedi Hasan</h3>
                      <p>Hello how are you!</p>
                    </div>
                    <div className="time12">10 mins</div>
                  </a>
                  <a href="#" className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="img-fluid"
                        src="https://mehedihtml.com/chatbox/assets/img/user.png"
                        alt="user img"
                      />
                      <span className="st-statis away"></span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h3>Ryhan</h3>
                      <p>Hello how are you!</p>
                    </div>
                    <div className="time12">10 mins</div>
                  </a>
                  <a href="#" className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="img-fluid"
                        src="https://mehedihtml.com/chatbox/assets/img/user.png"
                        alt="user img"
                      />
                      <span className="st-statis disturb"></span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h3>Malek Hasan</h3>
                      <p>Hello how are you!</p>
                    </div>
                    <div className="time12">10 hr</div>
                  </a>
                  <a href="#" className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="img-fluid"
                        src="https://mehedihtml.com/chatbox/assets/img/user.png"
                        alt="user img"
                      />
                      <span className="st-statis driving"></span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h3>Sadik Hasan</h3>
                      <p>Hello how are you!</p>
                    </div>
                    <div className="time12">10 mins</div>
                  </a>
                  <a href="#" className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="img-fluid"
                        src="https://mehedihtml.com/chatbox/assets/img/user.png"
                        alt="user img"
                      />
                      <span className="st-statis offline"></span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h3>Bulu </h3>
                      <p>Hello how are you!</p>
                    </div>
                    <div className="time12">10 mins</div>
                  </a>
                </div>
              </ul>
            </div>

            <div className="dropdown bg-transparent">
              <div
                className="dropdown-toggle afternone position-relative"
                // type="button"
                style={{ cursor: 'pointer' }}
                // data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={showNotificationFunc}
                tabIndex={0}
                onBlur={() => {
                  setTimeout(() => {
                    setshowNotification(false)
                  }, 1000)
                }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 32 32"
                  fill="none">
                  <path
                    d="M12.4719 28.0001C13.4121 28.8299 14.6471 29.3334 15.9997 29.3334C17.3523 29.3334 18.5873 28.8299 19.5275 28.0001M23.9997 10.6667C23.9997 8.54502 23.1568 6.51018 21.6566 5.00989C20.1563 3.5096 18.1214 2.66675 15.9997 2.66675C13.878 2.66675 11.8431 3.5096 10.3428 5.00989C8.84255 6.51018 7.9997 8.54502 7.9997 10.6667C7.9997 14.787 6.96033 17.608 5.79925 19.474C4.81987 21.0479 4.33018 21.8349 4.34813 22.0544C4.36801 22.2975 4.41951 22.3902 4.6154 22.5355C4.79231 22.6667 5.58982 22.6667 7.18484 22.6667H24.8146C26.4096 22.6667 27.2071 22.6667 27.384 22.5355C27.5799 22.3902 27.6314 22.2975 27.6513 22.0544C27.6692 21.8349 27.1795 21.0479 26.2001 19.474C25.0391 17.608 23.9997 14.787 23.9997 10.6667Z"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="notify"></span>
              </div>
              {showNotification && (
                <NotificationsPopUp
                  setshowNotification={setshowNotification}
                  showNotification={showNotification}
                />
              )}
            </div>

            <div className="dropdown bg-transparent ">
              <div
                className="dropdown-toggle afternone position-relative d-flex align-items-center gap"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <span className="sidebar-user-img position-relative">
                  {!frProUser?.profileImg ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none">
                      <path
                        d="M20.2772 20.75V18.8056C20.2772 17.7742 19.8675 16.785 19.1382 16.0557C18.4089 15.3264 17.4197 14.9167 16.3883 14.9167H8.61057C7.57917 14.9167 6.59002 15.3264 5.86071 16.0557C5.1314 16.785 4.72168 17.7742 4.72168 18.8056V20.75M16.3883 7.13889C16.3883 9.28666 14.6472 11.0278 12.4995 11.0278C10.3517 11.0278 8.61057 9.28666 8.61057 7.13889C8.61057 4.99112 10.3517 3.25 12.4995 3.25C14.6472 3.25 16.3883 4.99112 16.3883 7.13889Z"
                        stroke="#054757"
                        strokeWidth="2.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <img
                      src={updatedFrProUserObj?.profileImages}
                      alt="user profile"
                      height="48"
                      width="48"
                      style={{ borderRadius: '50%' }}
                    />
                  )}
                  <span className="notify user d-block d-lg-none"></span>
                  <button
                    className="driver-btn text-middle withstatus d-none d-lg-block"
                    type="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none">
                      <circle cx="5" cy="5" r="4" fill="#12B76A"></circle>
                    </svg>{' '}
                    {frProUser?.userCategory === 'Driver'
                      ? 'Driver'
                      : frProUser?.userCategory === 'Own Operator'
                        ? 'Owner'
                        : frProUser?.userCategory === 'Fleet Owner'
                          ? 'Fleet Owner'
                          : null}
                  </button>
                </span>
                <div className="d-none d-lg-block">
                  <div className="sidebar-user-info ">
                    <span
                      title={personalDetails?.personalInfoForm?.firstName}
                      className="sidebar-user__title text-capitalize">
                      {personalDetails?.personalInfoForm?.firstName}
                      {'  '}
                      {personalDetails?.personalInfoForm?.lastName}
                    </span>
                    <span
                      title={personalDetails?.personalInfoForm?.email?.email}
                      className="sidebar-user__subtitle">
                      {/* user email */}
                      {personalDetails?.personalInfoForm?.email?.email?.slice(0, 10)}
                    </span>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  className="d-none d-lg-block">
                  <path
                    d="M6.5 9L12.5 15L18.5 9"
                    stroke="#101828"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <ul className="dropdown-menu p-0 adminlogin">
                <li>
                  <Typography
                    component={Link}
                    className="dropdown-item"
                    to="/accountsettings">
                    Account Setting
                  </Typography>
                </li>
                {/* <li>
                  <Typography component={Link} className="dropdown-item" to="/membership">
                    Membership
                  </Typography>
                </li> */}
                <li>
                  <Typography component={Link} className="dropdown-item" to="/membership">
                    Support
                  </Typography>
                </li>

                <li className="border-top"></li>
                <Typography
                  component={Link}
                  className={''}
                  to="/"
                  // color="textSecondary"
                  // variant="h6"
                  onClick={logoutClicked}
                  sx={{
                    textDecoration: 'none',
                    fontSize: '1rem',
                    margin: '12px 0',
                  }}>
                  <a className="dropdown-item">Log Out</a>
                </Typography>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {errorInfo && (
        <div className="errorsInfo">{errorInfo?.errorDetail?.developerMessage}</div>
      )}
      {toasts && <Toasts toastsList={toasts} />}
    </>
  )
}

HeaderContent.propTypes = {
  showMenu: PropTypes.bool,
  showSearch: PropTypes.bool,
  showProfile: PropTypes.bool,
  loading: PropTypes.bool,
  connectionObj: PropTypes.object,
  subscribePresence: PropTypes.func,
}

export default HeaderContent
