import React, { useState } from 'react'
import Toasts from '../../Components/Toasts/Toasts'
import { ProfileApis } from '../../API/Login/ProfileApi'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { formatPhoneNumber } from '../../utils/GeneralUtils'
import { LoginApis } from '../../API/Login/LoginApi'
import ErrorHandle from '../../Components/ErrorHandle'
import { setEmailVerificationStatus, setFrProUser, setMobileVerificationStatus } from '../../store/reducers/Users/users'
import InputField from '../../Components/inputField'
import { Typography } from '@mui/material'
// import UploadImage from '../../Components/Feed/UploadImage'
const allowedTypes = ['image/jpeg', 'image/png']

const UploadDocumentsScreen = (props) => {
  const navigate = useNavigate()
  // const [images, setImages] = useState([])
  const [toasts, setToasts] = useState(null)
  const emailRules = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
  const { frProUser } = useSelector((state) => state.Users);
  const dispatch = useDispatch()
  const [image, setImage] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [errorType, setErrorType] = useState('')
  const [progress, setProgress] = useState(0)

  const handleMobileChecker = async (values) => {
    const obj = {
      mobileNumber:
        values?.mobileNumber?.length === 11 ||
          values?.mobileNumber?.length === 10
          ? formatPhoneNumber(values?.mobileNumber)
          : values?.mobileNumber,
      email: values?.email,
      fre8ProUserId: frProUser?.fre8ProUserId,
    }
    try {
      const res = await LoginApis.mobileNumberIsAvailable(obj)
      if (res?.data?.available) {
        return false
      }
      return true
    } catch (err) {
      console.log(err)
      let customMessage = 'Mobile availble API failed'
      let type = 'User Information'
      let color = 'red'
      ErrorHandle(err, setToasts, customMessage, type, color)
    }
  }

  const handleEmailChecker = async (values) => {
    const obj = {
      mobileNumber: values?.mobileNumber,
      email: values?.email,
      fre8ProUserId: frProUser?.fre8ProUserId,
    }
    // setFieldTouched('email', true, true)
    try {
      const res = await LoginApis.emailIsAvailable(obj)
      if (res?.data?.available) {
        return false
      }

      return true
    } catch (err) {
      console.log(err)
      let customMessage = 'Email availble API failed'
      let type = 'User Information'
      let color = 'red'
      ErrorHandle(err, setToasts, customMessage, type, color)
    }
  }

  const handleUploadImage = (e) => {
    setErrorType('')
    setProgress('')
    setImage(null)
    setImageUrl(null)
    e.preventDefault()
    let file = e?.target?.files[0]
    if (file) {
      setImage(file)
      const fileSize = file?.size / (1024 * 1024)
      if (fileSize > 5) {
        setErrorType('FILE_SIZE')
        return
      } else if (
        file?.type === '' ||
        file?.type === undefined ||
        !allowedTypes.includes(file?.type)
      ) {
        setErrorType('FILE_TYPE')
        return
      }
      const reader = new FileReader()
      try {
        reader.onload = (e) => {
          const interval = setInterval(() => {
            setProgress((prevProgress) => {
              console.log(prevProgress, 'prog_________')
              const newProgress = +prevProgress + 20
              if (prevProgress === 100) {
                clearInterval(interval)

                setImageUrl(e?.target?.result)
              }
              // Divide 100% into 10 parts
              return newProgress > 100 ? 100 : newProgress
            })
          }, 300) //updates every 0.4 sec
        }
        reader.readAsDataURL(file)
      } catch (err) {
        console.debug(err)
      }
    }
  }

  console.log('-------image-------', image)
  console.log('-------imageUrl-------', imageUrl)
  console.log('-------errorType-------', errorType)
  console.log('-------progress-------', progress)

  return (
    <>
      {/* <GetUserProfile /> */}
      <main className="main users chart-page h-100 ">
        <div className="text-start cursorPointer">
          <a onClick={() => navigate(-1)} className="f-w-700 color-400 ">
            <img src="../images/back.svg" alt="" className="me-3" /> Go back
          </a>
        </div>
        <div className="container-fluid p-lg-4">
          <div className="row">
            <div className="col-12 col-lg-9 mx-lg-auto">
              <div className="text-center mb-4 title">
                <h3 className='mb-4'>
                  Please upload you personal ID and business document to verify yourself
                </h3>
              </div>
            </div>
          </div>
          <div className="personal-detais">
            <div className="form mt-4">
              <Formik
                initialValues={{
                  firstName:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.firstName || '',
                  lastName:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.lastName || '',
                  birthDate:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.birthDate || '',
                  birthday:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.birthday || '',
                  birthMonth:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.birthMonth || '',
                  birthYear:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.birthYear || '',
                  gender:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.gender || '',
                  relationshipStatus:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.relationshipStatus
                      ?.relationshipStatus || '',
                  mobileNumber:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.mobileNumber?.mobile || '',
                  secondaryMobileNumber:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.secondaryMobileNumber?.mobile || '',
                  email:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.email?.email || '',
                  secondaryEmail:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.secondaryEmail?.email || '',
                  currentLocationCountry:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.currentPlaceOfLiving?.country
                      ?.countryName || '',
                  currentLocationState:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.currentPlaceOfLiving?.state
                      ?.stateName || '',
                  currentLocationCity:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.currentPlaceOfLiving?.city?.cityName ||
                    '',
                  currentLocationZip:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.currentPlaceOfLiving?.zip || '',
                  nativeLocationCountry:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.nativePlace?.country?.countryName || '',
                  nativeLocationState:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.nativePlace?.state?.stateName || '',
                  nativeLocationCity:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.nativePlace?.city?.cityName || '',
                  nativeLocationZip:
                    frProUser?.updateProfileForm?.personalInfo
                      ?.nativePlace?.zip || '',
                }}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string().required(
                    'Enter your first name'
                  ),
                  lastName: Yup.string().required(
                    'Enter your last name'
                  ),
                  birthday: Yup.string().required('date'),
                  birthMonth: Yup.string().required('month'),
                  birthYear: Yup.string().required(' year'),
                  gender:
                    Yup.string().required('Enter your gender'),
                  relationshipStatus: Yup.string().required(
                    'Enter your relationship status'
                  ),
                  mobileNumber: Yup.string()
                    .required(
                      'Invalid phone number format. Please provide phone number in this format: +1 (555) 000-0000'
                    )
                    .test(
                      'checkPhoneNumber2Exists',
                      'Mobile number already exists in the system.',
                      async function () {
                        let values = this.parent
                        const isAvailable =
                          await handleMobileChecker(values)

                        return isAvailable
                      }
                    ),
                  // secondaryMobileNumber: Yup.string(),
                  email: Yup.string()
                    .matches(
                      emailRules,
                      'Incorrect email format'
                    )
                    .required('Missing Information')
                    .test(
                      'checkEmailExists',
                      'This email already exists in the system.',
                      async function () {
                        let values = this.parent
                        const isAvailable =
                          await handleEmailChecker(values)

                        return isAvailable
                      }
                    ),
                  secondaryEmail: Yup.string(),
                  currentLocationCountry: Yup.string().required(
                    'Enter your current location country'
                  ),
                  currentLocationState: Yup.string().required(
                    'Enter your current location state'
                  ),
                  currentLocationCity: Yup.string().required(
                    'Enter your current location city'
                  ),
                  currentLocationZip: Yup.string().required(
                    'Enter your current location zip'
                  ),
                  nativeLocationCountry: Yup.string().required(
                    'Enter your native location country'
                  ),
                  nativeLocationState: Yup.string().required(
                    'Enter your native location state'
                  ),
                  nativeLocationCity: Yup.string().required(
                    'Enter your native location city'
                  ),
                  nativeLocationZip: Yup.string().required(
                    'Enter your native location zip'
                  ),
                })}
                onSubmit={(values) => {
                  const Obj = {
                    fre8ProAccessToken:
                      frProUser?.fre8ProAccessToken,
                    personalInfoId: frProUser?.fre8ProUserId,
                    profileId: frProUser?.fre8ProUserId,
                    userId: frProUser?.fre8ProUserId,
                    title: 'string',
                    displayName: 'string',
                    petName: 'string',
                    firstName: values?.firstName,
                    lastName: values?.lastName,
                    birthday: values?.birthday,
                    birthMonth: values?.birthMonth,
                    birthYear: values?.birthYear,
                    gender: values?.gender,
                    birthDate: `${values?.birthday}-${values?.birthMonth}-${values?.birthYear}`,
                    email: {
                      emailId: values?.fre8ProUserId,
                      email: values?.email,
                      verificationStatus: 'string',
                    },
                    secondaryEmail: {
                      emailId: values?.fre8ProUserId,
                      email: values?.secondaryEmail,
                      verificationStatus: 'string',
                    },
                    mobileNumber: {
                      mobileId: values?.fre8ProUserId,
                      mobile: values?.mobileNumber,
                      verificationStatus: 'string',
                    },
                    secondaryMobileNumber: {
                      mobileId: values?.fre8ProUserId,
                      mobile: values?.secondaryMobileNumber,
                      verificationStatus: 'string',
                    },
                    relationshipStatus: {
                      relationshipStatusId:
                        values?.fre8ProUserId,
                      relationshipStatus:
                        values?.relationshipStatus,
                    },
                    nativePlace: {
                      placeId: values?.fre8ProUserId,
                      placeDisplayName: 'string',
                      country: {
                        countryId: values?.fre8ProUserId,
                        countryName:
                          values?.nativeLocationCountry,
                      },
                      state: {
                        stateId: values?.fre8ProUserId,
                        stateName: values?.nativeLocationState,
                        countryForm: {
                          countryId: values?.fre8ProUserId,
                          countryName:
                            values?.nativeLocationCountry,
                        },
                      },
                      city: {
                        cityId: values?.fre8ProUserId,
                        cityName: values?.nativeLocationCity,
                      },
                      zip: values?.nativeLocationZip,
                    },
                    currentPlaceOfLiving: {
                      placeId: values?.fre8ProUserId,
                      placeDisplayName: 'string',
                      country: {
                        countryId: values?.fre8ProUserId,
                        countryName:
                          values?.currentLocationCountry,
                      },
                      state: {
                        stateId: values?.fre8ProUserId,
                        stateName: values?.currentLocationState,
                        countryForm: {
                          countryId: values?.fre8ProUserId,
                          countryName:
                            values?.currentLocationCountry,
                        },
                      },
                      city: {
                        cityId: values?.fre8ProUserId,
                        cityName: values?.currentLocationCity,
                      },
                      zip: values?.currentLocationZip,
                    },
                    nativePlaceText: 'string',
                    currentPlaceOfLivingText: 'string',
                    aboutMe: 'string',
                  }
                  ProfileApis.userPersonalInfoEdit(Obj)
                    .then((res) => {
                      dispatch(
                        setFrProUser({
                          frProUser: {
                            ...frProUser,
                            updateProfileForm: {
                              ...frProUser?.updateProfileForm,
                              personalInfo: res.data,
                            },
                          },
                        })
                      )
                      let item = [
                        {
                          name: 'personal Infomation is updated',
                          type: 'Information Updated',
                        },
                      ]
                      setToasts(item)
                      setTimeout(() => {
                        setToasts(null)
                      }, 3000)
                    })
                    .catch((err) => {
                      console.log(err)
                      let customMessage =
                        'User personal info edit failed'
                      let type = 'User Profile'
                      let color = 'red'
                      ErrorHandle(
                        err,
                        setToasts,
                        customMessage,
                        type,
                        color
                      )
                    })
                  const userMobileObj = {
                    fre8ProAccessToken:
                      frProUser?.fre8ProAccessToken,
                    oldMobileNumber: frProUser?.mobile,
                    mobileNumber: values?.mobileNumber,
                    confirmMobileNumber: values?.mobileNumber,
                  }
                  if (
                    userMobileObj?.mobileNumber !==
                    userMobileObj?.oldMobileNumber
                  ) {
                    ProfileApis.userMobileUpdate(userMobileObj)
                      .then((res) => {
                        dispatch(
                          setMobileVerificationStatus(false)
                        )
                      })
                      .catch((err) => {
                        console.log(err)
                        let customMessage =
                          'User mobile update failed'
                        let type = 'User Profile'
                        let color = 'red'
                        ErrorHandle(
                          err,
                          setToasts,
                          customMessage,
                          type,
                          color
                        )
                      })
                  }

                  const userEmailObj = {
                    fre8ProAccessToken:
                      frProUser.fre8ProAccessToken,
                    oldEmail: frProUser?.email,
                    email: values?.email,
                    confirmEmail: values?.email,
                  }
                  if (
                    userEmailObj?.oldEmail !==
                    userEmailObj?.email
                  ) {
                    ProfileApis.userEmailUpdate(userEmailObj)
                      .then((res) => {
                        dispatch(
                          setEmailVerificationStatus(false)
                        )
                      })
                      .catch((err) => {
                        console.log(err)
                        let customMessage =
                          'User email update failed'
                        let type = 'User Profile'
                        let color = 'red'
                        ErrorHandle(
                          err,
                          setToasts,
                          customMessage,
                          type,
                          color
                        )
                      })
                  }
                }}
                validateOnChange={false}
                validateOnBlur={true}
              >
                {({
                  errors,
                  values,
                  touched,
                  handleSubmit,
                  setFieldTouched,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <>
                      <div className="col-lg-12">
                        <div className="personal my-4">
                          <p className='title-upload'>Upload documents*</p>
                          {/* <button
                            onClick={handleSubmit}
                            type="button"
                            className="btn custom-button py-2 "
                          >
                            Save
                          </button> */}
                        </div>
                        {/* <div className="basic border-bottom pb-4">
                          <h5>Basic details</h5>
                          <a href="#" className="nav-link">
                            Update your basic details here.
                          </a>
                        </div> */}
                      </div>
                      <div className="row border-bottom pb-4">
                        <div className="col-lg-6">
                          <div className='mb-3'>
                            <lable className="lable-color">
                              Document name{' '}
                              <span style={{ color: 'red' }}>
                                *
                              </span>
                            </lable>
                            <InputField
                              type="text"
                              placeholder="Document name"
                              values={values}
                              name={'DocumentName'}
                              touched={touched}
                              errors={errors}
                              id="floatingInputGroup1"
                            />
                          </div>
                          <div className='mb-3'>
                            <lable className="lable-color">
                              Document number{' '}
                              <span style={{ color: 'red' }}>
                                *
                              </span>
                            </lable>
                            <InputField
                              type="text"
                              placeholder="Document number"
                              values={values}
                              name={'DocumentNumber'}
                              touched={touched}
                              errors={errors}
                              id="floatingInputGroup1"
                            />
                          </div>
                          <div className='mb-3'>
                            <lable className="lable-color">
                              Document issued by{' '}
                              <span style={{ color: 'red' }}>
                                *
                              </span>
                            </lable>
                            <InputField
                              type="text"
                              placeholder="Document issued by"
                              values={values}
                              name={'DocumentIssuedBy'}
                              touched={touched}
                              errors={errors}
                              id="floatingInputGroup1"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className='mb-3'>
                            <lable className="lable-color">
                              Document type{' '}
                              <span style={{ color: 'red' }}>
                                *
                              </span>
                            </lable>
                            <InputField
                              type="text"
                              placeholder="Document type"
                              values={values}
                              name={'DocumentType'}
                              touched={touched}
                              errors={errors}
                              id="floatingInputGroup1"
                            />
                          </div>
                          <div className='mb-3'>
                            <lable className="lable-color">
                              Document holder name{' '}
                              <span style={{ color: 'red' }}>
                                *
                              </span>
                            </lable>
                            <InputField
                              type="text"
                              placeholder="Document holder name"
                              values={values}
                              name={'DocumentHolderName'}
                              touched={touched}
                              errors={errors}
                              id="floatingInputGroup1"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className='mb-3'>
                            <lable className="lable-color">
                              Issued month{' '}
                              <span style={{ color: 'red' }}>
                                *
                              </span>
                            </lable>
                            <InputField
                              type="text"
                              placeholder="Issued month"
                              values={values}
                              name={'IssuedMonth'}
                              touched={touched}
                              errors={errors}
                              id="floatingInputGroup1"
                            />
                          </div>
                          <div className='mb-3'>
                            <lable className="lable-color">
                              Valid till month{' '}
                              <span style={{ color: 'red' }}>
                                *
                              </span>
                            </lable>
                            <InputField
                              type="text"
                              placeholder="Valid till month"
                              values={values}
                              name={'ValidTillMonth'}
                              touched={touched}
                              errors={errors}
                              id="floatingInputGroup1"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className='mb-3'>
                            <lable className="lable-color">
                              Issued year{' '}
                              <span style={{ color: 'red' }}>
                                *
                              </span>
                            </lable>
                            <InputField
                              type="text"
                              placeholder="Valid till month"
                              values={values}
                              name={'ValidTillMonth'}
                              touched={touched}
                              errors={errors}
                              id="floatingInputGroup1"
                            />
                          </div>
                          <div className='mb-3'>
                            <lable className="lable-color">
                              Valid till year{' '}
                              <span style={{ color: 'red' }}>
                                *
                              </span>
                            </lable>
                            <InputField
                              type="text"
                              placeholder="Valid till year"
                              values={values}
                              name={'ValidTillYear'}
                              touched={touched}
                              errors={errors}
                              id="floatingInputGroup1"
                            />
                          </div>
                        </div>
                        <div className='mb-3'>
                          <label htmlFor="field3" className="form-label">
                            Remarks
                          </label>

                          <InputField
                            tag="textarea"
                            placeholder="Enter your condition"
                            values={values}
                            name={'medicalCondition'}
                            touched={touched}
                            errors={errors}
                            rows="3"
                            maxLength={300}
                          />
                        </div>

                        {/* ----------------------------- */}
                        <div className="border my-3 p-3 rounded-3 text-center">
                          <svg
                            width="46"
                            height="46"
                            viewBox="0 0 46 46"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="3"
                              y="3"
                              width="40"
                              height="40"
                              rx="20"
                              fill="#F2F4F7"
                            ></rect>
                            <rect
                              x="3"
                              y="3"
                              width="40"
                              height="40"
                              rx="20"
                              stroke="#F9FAFB"
                              strokeWidth="6"
                            ></rect>
                            <g clipPath="url(#clip0_2236_1419)">
                              <path
                                d="M26.3335 26.3334L23.0002 23M23.0002 23L19.6669 26.3334M23.0002 23V30.5M29.9919 28.325C30.8047 27.8819 31.4467 27.1808 31.8168 26.3322C32.1868 25.4837 32.2637 24.5361 32.0354 23.6389C31.807 22.7418 31.2865 21.9463 30.5558 21.3779C29.8251 20.8095 28.9259 20.5006 28.0002 20.5H26.9502C26.698 19.5244 26.2278 18.6186 25.5752 17.8509C24.9225 17.0831 24.1042 16.4732 23.182 16.0672C22.2597 15.6612 21.2573 15.4695 20.2503 15.5066C19.2433 15.5437 18.2578 15.8086 17.3679 16.2814C16.4779 16.7542 15.7068 17.4226 15.1124 18.2363C14.518 19.0501 14.1158 19.988 13.936 20.9795C13.7563 21.9711 13.8036 22.9905 14.0746 23.9611C14.3455 24.9317 14.8329 25.8282 15.5002 26.5834"
                                stroke="#475467"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_2236_1419">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(13 13)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>

                          <p className="mb-1">
                            <label htmlFor="customFileInput">
                              <input
                                type="file"
                                id="customFileInput"
                                style={{ display: 'none' }}
                                accept="image/png, image/jpeg"
                                onChange={(e) => handleUploadImage(e)}
                              />{' '}
                              <strong>Click to upload</strong>
                            </label>{' '}
                            or drag and drop
                          </p>
                          <p className="mb-0 small">
                            PNG or JPG (max. 400x400px). Max 5MB file size.
                          </p>
                        </div>
                        {!errorType && image ? (
                          <div className=" border  d-flex my-3 p-3 rounded-3 mb-3">
                            <div className="me-3">
                              <svg
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="2"
                                  y="2"
                                  width="32"
                                  height="32"
                                  rx="16"
                                  fill="#B3F0FF"
                                ></rect>
                                <rect
                                  x="2"
                                  y="2"
                                  width="32"
                                  height="32"
                                  rx="16"
                                  stroke="#E6FAFF"
                                  strokeWidth="4"
                                ></rect>
                                <path
                                  d="M13.3333 24H22.6667C23.403 24 24 23.403 24 22.6667V13.3333C24 12.597 23.403 12 22.6667 12H13.3333C12.597 12 12 12.597 12 13.3333V22.6667C12 23.403 12.597 24 13.3333 24ZM13.3333 24L20.6667 16.6667L24 20M16.6667 15.6667C16.6667 16.219 16.219 16.6667 15.6667 16.6667C15.1144 16.6667 14.6667 16.219 14.6667 15.6667C14.6667 15.1144 15.1144 14.6667 15.6667 14.6667C16.219 14.6667 16.6667 15.1144 16.6667 15.6667Z"
                                  stroke="#054757"
                                  strokeWidth="1.33333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                            <div className="flex-grow-1">
                              <div className="fw-medium mb-1 ">{image?.name}</div>
                              <p className="mb-2 text-muted">
                                {(image?.size / (1024 * 1024)).toFixed(2)} MB
                              </p>
                              <div className="align-items-center d-flex">
                                <div
                                  className="progress w-100"
                                  style={{ height: '8px' }}
                                  role="progressbar"
                                  aria-label="Basic example"
                                  aria-valuenow={progress}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <div
                                    className="progress-bar"
                                    style={{
                                      width: `${progress}%`,
                                      backgroundColor: '#054757',
                                    }}
                                  ></div>
                                </div>
                                <span className="ms-2 small"> {`${progress}%`}</span>
                              </div>
                            </div>

                            <div>
                              <button
                                className="btn p-0"
                                onClick={() => {
                                  setErrorType('')
                                  setImage(null)
                                  setProgress(0)
                                }}
                              >
                                <svg
                                  width="36"
                                  height="36"
                                  viewBox="0 0 36 36"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.5 13H12.1667M12.1667 13H25.5M12.1667 13V24.6666C12.1667 25.1087 12.3423 25.5326 12.6548 25.8451C12.9674 26.1577 13.3913 26.3333 13.8333 26.3333H22.1667C22.6087 26.3333 23.0326 26.1577 23.3452 25.8451C23.6577 25.5326 23.8333 25.1087 23.8333 24.6666V13H12.1667ZM14.6667 13V11.3333C14.6667 10.8913 14.8423 10.4673 15.1548 10.1548C15.4674 9.84222 15.8913 9.66663 16.3333 9.66663H19.6667C20.1087 9.66663 20.5326 9.84222 20.8452 10.1548C21.1577 10.4673 21.3333 10.8913 21.3333 11.3333V13M16.3333 17.1666V22.1666M19.6667 17.1666V22.1666"
                                    stroke="#667085"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        ) : errorType ? (
                          <div
                            className=" border  d-flex my-3 p-3 rounded-3 mb-3 alert alert-danger"
                            role="alert"
                          >
                            <div className="me-3">
                              <svg
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="2"
                                  y="2"
                                  width="32"
                                  height="32"
                                  rx="16"
                                  fill="#B3F0FF"
                                ></rect>
                                <rect
                                  x="2"
                                  y="2"
                                  width="32"
                                  height="32"
                                  rx="16"
                                  stroke="#E6FAFF"
                                  strokeWidth="4"
                                ></rect>
                                <path
                                  d="M13.3333 24H22.6667C23.403 24 24 23.403 24 22.6667V13.3333C24 12.597 23.403 12 22.6667 12H13.3333C12.597 12 12 12.597 12 13.3333V22.6667C12 23.403 12.597 24 13.3333 24ZM13.3333 24L20.6667 16.6667L24 20M16.6667 15.6667C16.6667 16.219 16.219 16.6667 15.6667 16.6667C15.1144 16.6667 14.6667 16.219 14.6667 15.6667C14.6667 15.1144 15.1144 14.6667 15.6667 14.6667C16.219 14.6667 16.6667 15.1144 16.6667 15.6667Z"
                                  stroke="#054757"
                                  strokeWidth="1.33333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                            <div className="flex-grow-1">
                              <div className="fw-medium mb-1 ">
                                Upload failed, please try again.
                              </div>
                              <p className="mb-2 text-muted">{image?.name}</p>
                              <div className="align-items-center d-flex">
                                {errorType === 'FILE_SIZE' &&
                                  'File size is above 5 MB.'}
                                {errorType === 'FILE_TYPE' &&
                                  'Incorrect image format, upload .PNG or .JPEG image.'}
                              </div>
                            </div>
                            <div>
                              <button
                                className="btn p-0"
                                onClick={() => {
                                  setErrorType('')
                                  setImage(null)
                                  setProgress(0)
                                }}
                              >
                                <svg
                                  width="36"
                                  height="36"
                                  viewBox="0 0 36 36"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.5 13H12.1667M12.1667 13H25.5M12.1667 13V24.6666C12.1667 25.1087 12.3423 25.5326 12.6548 25.8451C12.9674 26.1577 13.3913 26.3333 13.8333 26.3333H22.1667C22.6087 26.3333 23.0326 26.1577 23.3452 25.8451C23.6577 25.5326 23.8333 25.1087 23.8333 24.6666V13H12.1667ZM14.6667 13V11.3333C14.6667 10.8913 14.8423 10.4673 15.1548 10.1548C15.4674 9.84222 15.8913 9.66663 16.3333 9.66663H19.6667C20.1087 9.66663 20.5326 9.84222 20.8452 10.1548C21.1577 10.4673 21.3333 10.8913 21.3333 11.3333V13M16.3333 17.1666V22.1666M19.6667 17.1666V22.1666"
                                    stroke="#667085"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* ----------------------------- */}
                        {/* <div className="custom-flex">
                          
                          <div className="border p-2 rounded-3 text-center w-100  ">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 46 46"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <rect x="3" y="3" width="40" height="40" rx="20" fill="#F2F4F7"></rect>
                              <rect
                                x="3"
                                y="3"
                                width="40"
                                height="40"
                                rx="20"
                                stroke="#F9FAFB"
                                strokeWidth="6"></rect>
                              <g clipPath="url(#clip0_2236_1419)">
                                <path
                                  d="M26.3335 26.3334L23.0002 23M23.0002 23L19.6669 26.3334M23.0002 23V30.5M29.9919 28.325C30.8047 27.8819 31.4467 27.1808 31.8168 26.3322C32.1868 25.4837 32.2637 24.5361 32.0354 23.6389C31.807 22.7418 31.2865 21.9463 30.5558 21.3779C29.8251 20.8095 28.9259 20.5006 28.0002 20.5H26.9502C26.698 19.5244 26.2278 18.6186 25.5752 17.8509C24.9225 17.0831 24.1042 16.4732 23.182 16.0672C22.2597 15.6612 21.2573 15.4695 20.2503 15.5066C19.2433 15.5437 18.2578 15.8086 17.3679 16.2814C16.4779 16.7542 15.7068 17.4226 15.1124 18.2363C14.518 19.0501 14.1158 19.988 13.936 20.9795C13.7563 21.9711 13.8036 22.9905 14.0746 23.9611C14.3455 24.9317 14.8329 25.8282 15.5002 26.5834"
                                  stroke="#475467"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_2236_1419">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="white"
                                    transform="translate(13 13)"></rect>
                                </clipPath>
                              </defs>
                            </svg>
                            <p className="mb-1">
                              <label htmlFor="uploadImage">
                                <input
                                  type="file"
                                  id="uploadImage"
                                  style={{ display: 'none' }}
                                  accept="image/png, image/jpeg"
                                  onChange={(e) => handleUploadImage(e)}
                                />{' '}
                                <strong>Click to upload</strong>
                              </label>{' '}
                              or drag and drop
                            </p>
                            <p className="mb-0 small">
                              PNG or JPEG or GIF (max. 2000x2000px). Max 300MB file size.
                            </p>
                          </div>
                        </div> */}
                      </div>

                      <div className="Current-details mt-4">
                        <div className="border-top mb-3 pt-3 d-flex align-item-center justify-content-center gap-3">
                          <button
                            type="button"
                            className="btn cancelBtn py-2 px-5"
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </button>
                          <Typography
                            component={Link}
                            className={'btn ms-2 submitBtn py-2 px-5'}
                            to="/alliance/selfUploadDocument"
                            color="textSecondary"
                            variant="h6"
                            type='submit'
                            sx={{ textDecoration: 'none' }}
                          >
                            Next
                          </Typography>
                          {/* <button
                            type="submit"
                            className="btn custom-button py-2 px-5"
                          >
                            Next
                          </button> */}
                        </div>
                      </div>
                    </>
                  </Form>
                )}
              </Formik>
            </div>
            {toasts && <Toasts toastsList={toasts} />}
          </div>
        </div>
      </main>
    </>
  )
}

export default UploadDocumentsScreen
