import React from 'react'
import CustomDataTable from './CustomDataTable.jsx'
import { Link } from 'react-router-dom'
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded'
import { Chip } from '@mui/material'
// import PropTypes from 'prop-types'
// import Pagination from '../../CouchServices/Pagination.jsx'
const appliedData = [
  {
    id: 1,
    name: 'Maxine Smith',
    type: 'Fleet owner',
    profileImg: '../images/av-1.png',
    date: '15 May 2024 10:20 AM',
    status: 'Verification In progress',
    uploadedDocuments: 'Documents verified Selfie submitted',
  },
  {
    id: 2,
    name: 'Phoenix Baker',
    type: 'Driver',
    profileImg: '../images/av-2.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Verification In progress',
    uploadedDocuments: 'Documents viewed Verification pending',
  },
  {
    id: 3,
    name: 'Lana Steiner',
    type: 'Owner operator',
    profileImg: '../images/av-3.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Verification In progress',
    uploadedDocuments: 'Selfie Viewed Verification pending',
  },
  {
    id: 4,
    name: 'Demi Wilkinson',
    type: 'Couch Service',
    profileImg: '../images/av-5.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Verification In progress',
    uploadedDocuments: 'Documents verified Selfie not Submitted',
  },
  {
    id: 5,
    name: 'Candice Wu',
    type: 'Events Creators',
    profileImg: '../images/profile-svg.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Verification In progress',
    uploadedDocuments: 'Selfie Viewed Verification pending',
  },
  {
    id: 6,
    name: 'Liron Mass',
    type: 'Gas Station',
    profileImg: '../images/profile-svg.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Verification In progress',
    uploadedDocuments: 'Documents verified Selfie viewed',
  },
  {
    id: 7,
    name: 'Aden Barr',
    type: 'Eatery business',
    profileImg: '../images/av-3.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Verification In progress',
    uploadedDocuments: 'Documents verified Selfie not submitted',
  },
  {
    id: 8,
    name: 'Kaelynn Coffey',
    type: 'Learning centre',
    profileImg: '../images/av-1.png',
    date: '15 May 2024 10:20 AM',
    status: 'Verification In progress',
    uploadedDocuments: 'Documents viewed Verification pending',
  },
  {
    id: 9,
    name: 'Payton Sampson',
    type: 'Fleet Warehouse',
    profileImg: '../images/av-2.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Verification In progress',
    uploadedDocuments: 'Documents viewed Verification pending',
  },
  {
    id: 10,
    name: 'Casey Roman',
    type: 'Owner operator',
    profileImg: '../images/av-5.svg',
    date: '15 May 2024 10:20 AM',
    status: 'Verification In progress',
    uploadedDocuments: 'Documents viewed Verification pending',
  },
]

const InProcessVerificationTab = () => {
  const agentColumns = [
    {
      title: 'Documents',
      dataIndex: 'name',
      width: 100,
      ellipsis: false,
      render: (text, requestObject) => {
        return (
          <label className="form-check-label" htmlFor="Phoenix">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {requestObject?.profileImg && (
                  <img
                    className="img-fluid"
                    src={requestObject?.profileImg}
                    alt="user img"
                    style={{
                      width: '40px',
                      height: '40px',
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                  />
                )}
                {requestObject?.icon && requestObject?.icon}
              </div>
              <div className="flex-grow-1 ms-2">
                <h6 className={`${requestObject?.type ? '' : 'mb-0'}`}>
                  {requestObject?.name}
                </h6>
                {requestObject?.type && (
                  <Chip
                    size="small"
                    icon={
                      [
                        'Fleet owner',
                        'Driver',
                        'Couch Service',
                        'Events Creators',
                      ].includes(requestObject?.type) && (
                        <FiberManualRecordRoundedIcon className="icon" />
                      )
                    }
                    className="custom-chip"
                    label={requestObject?.type}
                    color="success"
                  />
                )}
              </div>
            </div>
          </label>
        )
        // return <span className="text-capitalize">{text}</span>
      },
    },
    {
      title: 'Date received',
      dataIndex: 'date',
      width: 100,
      ellipsis: true,
      render: (text) => {
        return <span className="text-capitalize">{text}</span>
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      ellipsis: true,
      render: (text) => {
        return (
          <Chip
            size="small"
            // icon={
            //   ['Fleet owner', 'Driver', 'Couch Service', 'Events Creators'].includes(
            //     requestObject?.type
            //   ) && <FiberManualRecordRoundedIcon className="icon" />
            // }
            className={
              ['Documents applied', 'Submitted'].includes(text)
                ? `custom-chip`
                : [
                    'Documents Declined',
                    'Video ID Declined',
                    'ID Verification Declined',
                  ].includes(text)
                ? 'custom-chip-error'
                : text === 'Verification In progress'
                ? 'custom-chip-in-progress'
                : text === 'Freight Pro Verified'
                ? 'custom-chip-freight-pro-verified'
                : text === 'ID verification'
                ? 'custom-chip-id-verification'
                : ''
            }
            label={text}
            color="error"
          />
        )
      },
    },
    {
      title: 'Details',
      dataIndex: 'uploadedDocuments',
      width: 100,
      ellipsis: false,
      render: (text) => {
        return (
          <div className="d-flex align-items-center justify-content-between w-100">
            {text}
            {/* view-documents */}
            <Link to={'/csr/documents-verification-list'}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none">
                <g clipPath="url(#clip0_3103_45327)">
                  <path
                    d="M10.4998 6L9.08984 7.41L13.6698 12L9.08984 16.59L10.4998 18L16.4998 12L10.4998 6Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3103_45327">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </Link>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <div id="Couch_requests" style={{ height: '100%' }}>
        <div id="Applications_recived">
          <div className="responsive-tebal border rounded-3 overflow-hidden billing-table">
            <CustomDataTable columns={agentColumns} dataSource={appliedData} />
          </div>
          <div className="meetup-pagination my-3">
            <ul className="pagination justify-content-center custom-pagnition ">
              <li className="page-item disabled">
                <a className="page-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none">
                    <path
                      d="M15.8332 10.0001H4.1665M4.1665 10.0001L9.99984 15.8334M4.1665 10.0001L9.99984 4.16675"
                      stroke="#667085"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"></path>
                  </svg>
                  Previous
                </a>
              </li>
              <li className="page-item active">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  ...
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  8
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  9
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  10
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  Next
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none">
                    <path
                      d="M4.1665 10.0001H15.8332M15.8332 10.0001L9.99984 4.16675M15.8332 10.0001L9.99984 15.8334"
                      stroke="#667085"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          {/* <Pagination
            page={page}
            setPage={setPage}
            totalPages={Math.ceil(totalPages / size)}
          /> */}
        </div>
      </div>
    </>
  )
}

export default InProcessVerificationTab

// AllVerificationTab.propTypes = {
//   handleGetIndividualGuestReqObjDetails: PropTypes.func,
//   handleTriggerCouchTab: PropTypes.func,
//   handleGuestRequestList: PropTypes.func,
// }
