import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

const UploadDocumentListScreen = (props) => {
  const navigate = useNavigate()

  return (
    <>
      {/* <GetUserProfile /> */}
      <main className="main users chart-page h-100 ">
        <div className="text-start cursorPointer">
          <a onClick={() => navigate(-1)} className="f-w-700 color-400 ">
            <img src="../images/back.svg" alt="" className="me-3" /> Go back
          </a>
        </div>
        <div className="container-fluid p-lg-4">
          <div className="row">
            <div className="col-12 col-lg-9 mx-lg-auto">
              <div className="text-center mb-4 title">
                <h3 className='mb-4'>
                  Please upload you personal ID and business document to verify yourself
                </h3>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="personal d-flex align-item-center justify-content-between">
                <p className='title-upload m-0 d-flex align-item-center'>Upload documents*</p>
                <Typography
                  component={Link}
                  className={'btn ms-2 lightBtn py-1 px-3'}
                  to="/alliance/uploadDocumentList"
                  color="textSecondary"
                  variant="h6"
                  type='submit'
                  sx={{ textDecoration: 'none' }}
                >
                  + Add
                </Typography>
              </div>
            </div>
          </div>
          <div className="personal-detais">
            <div className='document-list-card d-flex align-items-center gap-2 flex-wrap'>
              <div className='list-card'>
                <div className='list-card-header d-flex align-items-center justify-content-between gap-2'>
                  <p className='list-title d-flex align-items-center gap-2'>
                    <div className='roundCircleDiv'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M8.6665 1.33398H3.99984C3.64622 1.33398 3.30708 1.47446 3.05703 1.72451C2.80698 1.97456 2.6665 2.3137 2.6665 2.66732V13.334C2.6665 13.6876 2.80698 14.0267 3.05703 14.2768C3.30708 14.5268 3.64622 14.6673 3.99984 14.6673H11.9998C12.3535 14.6673 12.6926 14.5268 12.9426 14.2768C13.1927 14.0267 13.3332 13.6876 13.3332 13.334V6.00065M8.6665 1.33398L13.3332 6.00065M8.6665 1.33398V6.00065H13.3332" stroke="#EDAE10" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    Article of business
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className='list-body'>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Type</p>
                    <p>Business document</p>
                  </div>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Number</p>
                    <p>Doc562899</p>
                  </div>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Holder Name</p>
                    <p>New your business</p>
                  </div>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Issued by</p>
                    <p>Thomas Sola</p>
                  </div>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Issued on</p>
                    <p>09/2020</p>
                  </div>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Valid till</p>
                    <p>01/2028</p>
                  </div>
                </div>
              </div>
              <div className='list-card'>
                <div className='list-card-header d-flex align-items-center justify-content-between gap-2'>
                  <p className='list-title d-flex align-items-center gap-2'>
                    <div className='roundCircleDiv'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M8.6665 1.33398H3.99984C3.64622 1.33398 3.30708 1.47446 3.05703 1.72451C2.80698 1.97456 2.6665 2.3137 2.6665 2.66732V13.334C2.6665 13.6876 2.80698 14.0267 3.05703 14.2768C3.30708 14.5268 3.64622 14.6673 3.99984 14.6673H11.9998C12.3535 14.6673 12.6926 14.5268 12.9426 14.2768C13.1927 14.0267 13.3332 13.6876 13.3332 13.334V6.00065M8.6665 1.33398L13.3332 6.00065M8.6665 1.33398V6.00065H13.3332" stroke="#EDAE10" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    Article of business
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className='list-body'>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Type</p>
                    <p>Business document</p>
                  </div>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Number</p>
                    <p>Doc562899</p>
                  </div>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Holder Name</p>
                    <p>New your business</p>
                  </div>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Issued by</p>
                    <p>Thomas Sola</p>
                  </div>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Issued on</p>
                    <p>09/2020</p>
                  </div>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Valid till</p>
                    <p>01/2028</p>
                  </div>
                </div>
              </div>
              <div className='list-card'>
                <div className='list-card-header d-flex align-items-center justify-content-between gap-2'>
                  <p className='list-title d-flex align-items-center gap-2'>
                    <div className='roundCircleDiv'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M8.6665 1.33398H3.99984C3.64622 1.33398 3.30708 1.47446 3.05703 1.72451C2.80698 1.97456 2.6665 2.3137 2.6665 2.66732V13.334C2.6665 13.6876 2.80698 14.0267 3.05703 14.2768C3.30708 14.5268 3.64622 14.6673 3.99984 14.6673H11.9998C12.3535 14.6673 12.6926 14.5268 12.9426 14.2768C13.1927 14.0267 13.3332 13.6876 13.3332 13.334V6.00065M8.6665 1.33398L13.3332 6.00065M8.6665 1.33398V6.00065H13.3332" stroke="#EDAE10" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    Article of business
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className='list-body'>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Type</p>
                    <p>Business document</p>
                  </div>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Number</p>
                    <p>Doc562899</p>
                  </div>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Holder Name</p>
                    <p>New your business</p>
                  </div>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Issued by</p>
                    <p>Thomas Sola</p>
                  </div>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Issued on</p>
                    <p>09/2020</p>
                  </div>
                  <div className='d-flex gap-3'>
                    <p className='w-25'>Valid till</p>
                    <p>01/2028</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="Current-details mt-4">
              <div className="mb-3 pt-3 d-flex align-item-center justify-content-center gap-3">
                <button
                  type="button"
                  className="btn w-25 cancelBtn py-2 px-5"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
                <Typography
                  component={Link}
                  className={'btn ms-2 submitBtn py-2 px-5'}
                  to="/alliance/agreementView"
                  color="textSecondary"
                  variant="h6"
                  type='submit'
                  sx={{ textDecoration: 'none' }}
                >
                  Submit for verification
                </Typography>
                {/* <button
                                        type="submit"
                                        className="btn custom-button py-2 px-5"
                                      >
                                        Next
                                      </button> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default UploadDocumentListScreen
